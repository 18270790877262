import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimationsModule } from '~/shared/modules/animations/animations.module';
import { FirebaseModule } from '../firebase/firebase.module';
import { PushPromptComponent } from './push-prompt/push-prompt.component';

@NgModule({
  imports: [CommonModule, FirebaseModule, AnimationsModule],
  declarations: [PushPromptComponent],
  exports: [PushPromptComponent],
})
export class PushModule {}
