import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { DestroyWatcher } from '~/shared/helpers';
import { CartItemHelper, ICartItem, UserEventCountType } from '~_shared/models';
import { isEqualJSON, isEqualObj } from '~_shared/utils/misc.utils';
import { NGXS } from '../ngxs';
import { MyUserService } from './my-user.service';

@Injectable()
export class CartService extends DestroyWatcher {
  constructor(private ngxs: Store, private fb: FormBuilder, private myUser: MyUserService) {
    super();
  }

  readonly isLoading$ = this.ngxs.select(NGXS.CartItem.select.hasLoaded).pipe(map((x) => !x));

  readonly dibsables$ = this.ngxs.select(NGXS.CartItem.select.dibsables);
  readonly cartItems$ = this.ngxs.select(NGXS.CartItem.select.all);
  readonly cartItemsByItemIds$ = this.ngxs.select(NGXS.CartItem.select.entitiesByItemIds);

  init() {
    // clean-up requested but not found itemIds from cart
    combineLatest([
      this.cartItems$,
      this.ngxs.select(NGXS.Item.select.state),
      this.ngxs.select(NGXS.Store.select.state),
    ])
      .pipe(
        takeUntil(this.ngDestroyed$),
        debounceTime(10),
        map(([cartItems, itemState, storeState]) => {
          return cartItems.filter((cit) => {
            const itemExists = itemState.entities[cit.itemId];
            const itemHasTriedLoaded = itemState.status?.loaded?.ids?.includes(cit.itemId);
            const store = storeState.entities[cit.storeId];
            const storeDibsActive = store?.dibs?.active;
            const itemNotExists = !itemExists && itemHasTriedLoaded;
            const storeNotSupported = itemHasTriedLoaded && store && !storeDibsActive;
            return itemNotExists || storeNotSupported;
          });
        }),
        filter((invalidCartItems) => !!invalidCartItems?.length),
        distinctUntilChanged(isEqualJSON)
      )
      .subscribe((invalidCartItems) => {
        this.ngxs.dispatch(new NGXS.CartItem.actions.Remove(invalidCartItems));
      });
  }

  loadCart$() {
    return this.ngxs.dispatch(new NGXS.CartItem.actions.GetMine());
  }

  getByItemId$(itemId: string, requireValid?: boolean): Observable<ICartItem | undefined> {
    return this.ngxs
      .select(NGXS.CartItem.select.entityByItemIdFn(itemId))
      .pipe(filter((ci) => !!ci || !requireValid));
  }

  isStoreDibsActive$(storeId: string) {
    return this.ngxs
      .select(NGXS.Store.select.entityByIdFn(storeId))
      .pipe(map((s) => !!s?.dibs?.active));
  }

  cartItemForm$(itemId: string) {
    const item$ = this.ngxs.select(NGXS.Item.select.entityByIdFn).pipe(map((fn) => fn(itemId)));
    return combineLatest([this.getByItemId$(itemId), item$, this.myUser.userId$]).pipe(
      debounceTime(10),
      distinctUntilChanged(isEqualObj),
      map(([ci, it, userId]) => {
        const form = this.fb.group({
          id: [ci?.id ?? CartItemHelper.id(userId, itemId), Validators.required],
          storeId: [ci?.storeId || it.storeId, Validators.required],
          itemId: [itemId, Validators.required],
          userId: [userId, Validators.required],
          quantity: [ci?.quantity || 1, Validators.required],
          message: [ci?.message],
        }) as FormGroupTyped<ICartItem>;
        return form;
      })
    );
  }

  save$(cartItemOrItems: ICartItem | ICartItem[]) {
    const cartItems = Array.isArray(cartItemOrItems) ? cartItemOrItems : [cartItemOrItems];
    this.trackEvent(cartItems);
    return this.ngxs.dispatch(new NGXS.CartItem.actions.Save(cartItems));
  }

  remove$(cartItemOrItems: ICartItem | ICartItem[]) {
    const cartItems = Array.isArray(cartItemOrItems) ? cartItemOrItems : [cartItemOrItems];
    this.trackEvent(cartItems, false);
    return this.ngxs.dispatch(new NGXS.CartItem.actions.Remove(cartItems));
  }

  private trackEvent(cartItems: ICartItem[], adding = true) {
    const itemEventType = adding
      ? UserEventCountType.OrderCartAddItem
      : UserEventCountType.OrderCartRemoveItem;
    const storeEventType = adding
      ? UserEventCountType.OrderCartAddStore
      : UserEventCountType.OrderCartRemoveStore;
    cartItems.forEach((ci) => {
      this.myUser.event.track(itemEventType, ci.itemId);
      this.myUser.event.track(storeEventType, ci.storeId);
    });
  }
}
