import { IPlace } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[Place] UpsertMany';
  constructor(public places: IPlace[], public loadRelated?: boolean) {}
}

export class GetMine {
  static readonly type = '[Place] GetMine';
  constructor() {}
}

export class Upsert {
  static readonly type = '[Place] Upsert';
  constructor(public place: IPlace) {}
}

export class Delete {
  static readonly type = '[Place] Delete';
  constructor(public locationId: string) {}
}

export class LoadRelated {
  static readonly type = '[Place] LoadRelated';
  constructor(public places: IPlace[]) {}
}
