export enum DynamicListItemType {
  StoreRow = 'storerow',
  Poll = 'poll',
}

export interface IDynamicListItem<T = unknown> {
  type: DynamicListItemType;
  refId: string;
  data: T;
}
