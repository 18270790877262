import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IItemBody } from '~_shared/models';

@Injectable()
export class ItemBodyApiService {
  constructor(private backend: BackendService) {}

  getByIds$(ids: string[]) {
    if (ids.length < 10) {
      return this.backend.get<IItemBody[]>(`item-bodies/ids`, { params: { ids: ids.join(',') } });
    } else {
      return this.backend.post<IItemBody[]>(`item-bodies/ids`, ids);
    }
  }
}
