import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '~/../environments/environment';

@Injectable()
export class BackendService {
  private _baseUrl: string;

  get baseUrl() {
    return (this._baseUrl + '').replace(/\/$/g, '');
  }

  private _authBearer: string;
  public get authBearer() {
    return this._authBearer;
  }

  constructor(private http: HttpClient) {
    this._baseUrl = environment.apiBaseUrl;
  }

  url(url: string): string {
    url = url || '';
    url = url.replace(/\\/g, '/');
    url = url.replace(/^\//g, '');

    return this.baseUrl + '/' + url;
  }

  defaultOptions(options?: object) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.authBearer || '',
    });
    return { headers, ...options };
  }

  get<T>(url: string, options?: any) {
    return this.http.get<T>(this.url(url), this.defaultOptions(options));
  }
  post<T>(url: string, body: any, options?: any) {
    return this.http.post<T>(this.url(url), body, this.defaultOptions(options));
  }
  put<T>(url: string, body: any, options?: any) {
    return this.http.put<T>(this.url(url), body, this.defaultOptions(options));
  }
  patch<T>(url: string, body: any, options?: any) {
    return this.http.patch<T>(this.url(url), body, this.defaultOptions(options));
  }
  delete<T>(url: string, options?: any) {
    return this.http.delete<T>(this.url(url), this.defaultOptions(options));
  }

  setAuthBearer(authBearer: string) {
    this._authBearer = 'Bearer ' + authBearer;
  }
}
