import { createSelector, Selector } from '@ngxs/store';
import { ILocation, IPlace } from '~_shared/models';
import { LocationSelectors } from '../location/location.selectors';
import { MeSelectors } from '../me/me.selectors';
import { IPlaceState, PlaceState } from './place.state';

export class PlaceSelectors {
  @Selector([PlaceState])
  static state(s: IPlaceState) {
    return s;
  }

  @Selector([PlaceState])
  static status(s: IPlaceState) {
    return s.status;
  }

  @Selector([PlaceState])
  static entities(s: IPlaceState) {
    return s.entities;
  }

  @Selector([PlaceState])
  static ids(s: IPlaceState) {
    return s.ids;
  }

  @Selector([PlaceState])
  static all(s: IPlaceState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([PlaceState, MeSelectors.userId])
  static allMine(s: IPlaceState, userId: string) {
    return s.ids.map((id) => s.entities[id]).filter((p) => p.userId === userId);
  }

  @Selector([PlaceSelectors.all, LocationSelectors.selectedId, MeSelectors.userId])
  static selected(places: IPlace[], locationId: string, userId: string) {
    return places.find((p) => p.locationId === locationId && p.userId === userId);
  }

  @Selector([PlaceSelectors.selected, LocationSelectors.selectedId, MeSelectors.userId])
  static selectedOrDefault(place: IPlace, locationId: string, userId: string) {
    return (
      place ||
      ({
        locationId,
        userId,
        radiusKm: undefined,
      } as IPlace)
    );
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IPlaceState) => {
      return s.entities[id];
    });
  }

  static entityByLocationFn(locationId: string, userId?: string) {
    return createSelector([this.all], (all: IPlace[]) => {
      return all.find(
        (it) => it.locationId === locationId && ((userId && userId === it.userId) || !userId)
      );
    });
  }

  static locationsByIdsFn(placeIds: string[]) {
    return createSelector([this.entities, LocationSelectors.entities], (pes, les) => {
      const locations = placeIds.map((pid) => les[(pes[pid] || {}).locationId] as ILocation);
      return locations.filter((p) => !!p);
    });
  }
}
