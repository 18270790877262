import * as CategoryActions from './category.actions';
import { CategorySelectors } from './category.selectors';
import { CategoryApiService } from './category.service';
import { CategoryState } from './category.state';

export const Category = {
  state: CategoryState,
  actions: CategoryActions,
  select: CategorySelectors,
  api: CategoryApiService,
};
