import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import cloneDeep from 'lodash/cloneDeep';
import { IItem, ItemHelper } from '~_shared/models';
import * as BulkActions from '../bulk/bulk.actions';
import * as ItemBodyActions from '../item-body/item-body.actions';
import * as StoreActions from '../store/store.actions';
import { EntityUtils, IDictionary, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as ItemActions from './item.actions';
import { ItemApiService } from './item.service';

export interface IItemState extends IEntityState<IItem>, IStatusState {
  /**
   * idsByStores grouped by any identifier, e.g
   * - [locationId] = { storeId: item[] }
   * - [locationId_userFilterId] = { storeId: item[] }
   * - [locationId_categoryId] = { storeId: item[] }
   * - ['all'] = { storeId: item[] }
   */
  idsByStoresHash: IDictionary<IDictionary<string[]>>;
}

export const hashIdAll = 'all';

@State<IItemState>({
  name: 'item',
  defaults: {
    idsByStoresHash: {},
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class ItemState {
  constructor(private api: ItemApiService) {}

  @Action(ItemActions.UpsertMany)
  async upsertMany(ctx: StateContext<IItemState>, { items, loadRelated }: ItemActions.UpsertMany) {
    const status = new StatusUtils(ctx, ItemActions.UpsertMany);
    const decompressedItems = items.map((it) => ItemHelper.decompress(it));

    // always add all items' storeId to idsByStoresHash['all'][storeId]
    const idsByStoresHash = { ...ctx.getState().idsByStoresHash };
    const idsByStoresAll = { ...(idsByStoresHash[hashIdAll] || {}) };
    for (const it of decompressedItems) {
      idsByStoresAll[it.storeId] = [...(idsByStoresAll[it.storeId] || [])];
      if (!idsByStoresAll[it.storeId].includes(it.id)) {
        idsByStoresAll[it.storeId].push(it.id);
      }
    }
    idsByStoresHash[hashIdAll] = idsByStoresAll;

    ctx.patchState({
      ...EntityUtils.upsertMany(ctx.getState(), decompressedItems),
      idsByStoresHash,
    });
    if (loadRelated) {
      await ctx.dispatch(new ItemActions.LoadRelated(decompressedItems)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(ItemActions.SetIdsByStoresHash)
  async setIdsByStoresHash(
    ctx: StateContext<IItemState>,
    { idsByStoresHash, clear }: ItemActions.SetIdsByStoresHash
  ) {
    const s = ctx.getState();
    ctx.patchState({
      idsByStoresHash: clear
        ? idsByStoresHash
        : Object.assign({}, s.idsByStoresHash, idsByStoresHash),
    });
  }

  @Action(ItemActions.GetById)
  async getById(ctx: StateContext<IItemState>, { id }: ItemActions.GetById) {
    const status = new StatusUtils(ctx, ItemActions.GetById);
    const s = ctx.getState();
    const item = s.entities[id] || (await this.api.getById$(id).toPromise());
    await ctx.dispatch(new ItemActions.UpsertMany([item])).toPromise();
    status.setLoadedIds([id]).setLoading(false);
  }

  @Action(ItemActions.GetByIds)
  async getByIds(ctx: StateContext<IItemState>, { ids }: ItemActions.GetByIds) {
    const s = ctx.getState();
    const nonExistingIds = ids.filter(
      (id) => !s.entities[id] && !s.status?.loaded?.ids?.includes(id)
    );
    if (nonExistingIds.length === 0) {
      return;
    }
    const status = new StatusUtils(ctx, ItemActions.GetByIds);
    const items = await this.api.getByIds$(nonExistingIds).toPromise();
    await ctx.dispatch(new ItemActions.UpsertMany(items)).toPromise();
    status.setLoadedIds(nonExistingIds).setLoading(false);
  }

  @Action(ItemActions.GetPublicsByStores)
  async getPublicsByStores(
    ctx: StateContext<IItemState>,
    { storeIds }: ItemActions.GetPublicsByStores
  ) {
    const status = new StatusUtils(ctx, ItemActions.GetPublicsByStores);
    if (storeIds?.length) {
      const items = (await this.api.getByStores$(storeIds).toPromise()) || [];
      await ctx.dispatch(new ItemActions.UpsertMany(items)).toPromise();
    }
    status.setLoadedIds(storeIds).setLoading(false);
  }

  @Action(ItemActions.GetByStoreAdmin)
  async getByStoreAdmin(ctx: StateContext<IItemState>, { storeId }: ItemActions.GetByStoreAdmin) {
    const status = new StatusUtils(ctx, ItemActions.GetByStoreAdmin);
    const items = (await this.api.getByStoreAdmin$(storeId).toPromise()) || [];
    await ctx.dispatch(new ItemActions.UpsertMany(items)).toPromise();
    status.setLoading(false);
  }

  @Action(ItemActions.Save)
  async save(ctx: StateContext<IItemState>, { item }: ItemActions.Save) {
    const status = new StatusUtils(ctx, ItemActions.Save);
    const s = () => ctx.getState();
    const itemData = cloneDeep(item);
    const storeId = itemData.storeId;
    const hashId = `storeadmin_${storeId}`;
    const getIdsByStoresHashFn = (itemId?: string, append = true) => {
      const idsByStoresHash = { ...(s().idsByStoresHash || {}) };
      idsByStoresHash[hashId] = { ...(idsByStoresHash[hashId] || {}) };
      idsByStoresHash[hashId][storeId] = (idsByStoresHash[hashId][storeId] || []).filter(
        (id) => id !== itemId
      );
      if (append) {
        idsByStoresHash[hashId][storeId].push(itemId);
      }
      return idsByStoresHash;
    };
    const upsertItemFn = (theItem: IItem) => {
      ctx.patchState({
        ...EntityUtils.upsertOne(s(), theItem),
        idsByStoresHash: getIdsByStoresHashFn(theItem.id, true),
      });
      ctx.dispatch(
        new BulkActions.ClearStatus([
          BulkActions.GetItemsByQuery.type,
          BulkActions.GetItemsByIds.type,
        ])
      );
    };
    const removeItemFn = (theId: string) => {
      ctx.patchState({
        ...EntityUtils.removeOne(s(), { id: theId }),
        idsByStoresHash: getIdsByStoresHashFn(theId, false),
      });
    };

    // store to state instantly (optimistic) for better UX
    const quickUpdateItem: IItem = { ...(s().entities[itemData.id] || {}), ...itemData };
    quickUpdateItem.id = quickUpdateItem.id || `temp-${new Date().getTime()}`;
    upsertItemFn(quickUpdateItem);

    // save by API and update state
    this.api
      .save$(itemData)
      .toPromise()
      .then((savedItem) => {
        removeItemFn(quickUpdateItem.id);
        const mergedItem = { ...itemData, ...savedItem };
        upsertItemFn(mergedItem);
      });

    status.setLoading(false);
  }

  @Action(ItemActions.LoadRelated)
  async loadRelated(ctx: StateContext<IItemState>, { items }: ItemActions.LoadRelated) {
    const hashByStoreIds: IDictionary<boolean> = {};
    const hashByItemBodyIds: IDictionary<boolean> = {};
    for (let i = 0; i < items.length; i++) {
      const it = items[i];
      if (!it) continue;
      hashByStoreIds[it.storeId] = true;
      hashByItemBodyIds[it.itemBodyId] = true;
    }
    const storeIds = Object.keys(hashByStoreIds);
    const itemBodyIds = Object.keys(hashByItemBodyIds);
    const jobs: Array<Promise<any>> = [];
    if (storeIds.length) {
      jobs.push(ctx.dispatch(new StoreActions.GetByIds(storeIds)).toPromise());
    }
    if (itemBodyIds.length) {
      jobs.push(ctx.dispatch(new ItemBodyActions.GetByIds(itemBodyIds)).toPromise());
    }
    await Promise.all(jobs);
  }
}
