<mat-list class="menu-list menu-list p-0">
  <mat-list-item
    matRipple
    class="link"
    *ngFor="let item of items"
    (click)="itemClick.emit(item)"
    [class.isLoading]="item.isLoading"
    [ngClass]="[item.cssClass || '']"
    [routerLinkActive]="item.routerLink ? 'active' : ''"
    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
    [routerLink]="item.routerLink"
    [queryParams]="item.queryParams"
    [fragment]="item.fragment"
  >
    <div matLine>
      <span class="head-label lh-100">
        {{ item.label }}
        <i *ngIf="item.showActionDot" class="action-dot ml-025"></i>
      </span>
      <span class="sub-label d-block ts-60 opacity-50 lh-100" *ngIf="item.subLabel">
        {{ item.subLabel }}
      </span>
    </div>
    <div class="order-last d-flex">
      <i
        matListIcon
        class="icon"
        [ngClass]="item.icon != null ? item.icon : 'fal fa-chevron-right'"
      ></i>
      <i matListIcon class="spinner mr-025">
        <mat-spinner diameter="24" class="blackish"></mat-spinner>
      </i>
    </div>
  </mat-list-item>
</mat-list>
