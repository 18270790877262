import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import flatten from 'lodash/flatten';
import { IShoppingListUser, ShoppingListHelper } from '~_shared/models';
import * as UserActions from '../user/user.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as ShoppingListUserActions from './shopping-list-user.actions';
import { ShoppingListUserApiService } from './shopping-list-user.service';

export interface IShoppingListUserState extends IEntityState<IShoppingListUser>, IStatusState {}

@State<IShoppingListUserState>({
  name: 'shoppingListUser',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class ShoppingListUserState {
  constructor(private api: ShoppingListUserApiService) {}

  @Action(ShoppingListUserActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IShoppingListUserState>,
    { shoppingListUsers, loadRelated }: ShoppingListUserActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, ShoppingListUserActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), shoppingListUsers));
    if (loadRelated) {
      await ctx.dispatch(new ShoppingListUserActions.LoadRelated(shoppingListUsers)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(ShoppingListUserActions.GetByListIds)
  async getByListIds(
    ctx: StateContext<IShoppingListUserState>,
    { shoppingListIds }: ShoppingListUserActions.GetByListIds
  ) {
    const status = new StatusUtils(ctx, ShoppingListUserActions.GetByListIds);
    let error: Error;
    try {
      const listUsersArray = await Promise.all(
        shoppingListIds.map((listId) => this.api.getByListId$(listId).toPromise())
      );
      const listUsers = flatten(listUsersArray);
      await ctx.dispatch(new ShoppingListUserActions.UpsertMany(listUsers)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListUserActions.Save)
  async save(
    ctx: StateContext<IShoppingListUserState>,
    { shoppingListUsers }: ShoppingListUserActions.Save
  ) {
    const status = new StatusUtils(ctx, ShoppingListUserActions.Save);
    let error: Error;
    try {
      // add optimistic
      shoppingListUsers.forEach((lit) => (lit.id = ShoppingListHelper.listUserId(lit)));
      ctx.dispatch(new ShoppingListUserActions.UpsertMany(shoppingListUsers)).toPromise();

      // update actually saved
      const users = await Promise.all(shoppingListUsers.map((u) => this.api.save$(u).toPromise()));
      await ctx.dispatch(new ShoppingListUserActions.UpsertMany(users)).toPromise();
      // TODO: handle errors?
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListUserActions.SaveArchived)
  async saveArchived(
    ctx: StateContext<IShoppingListUserState>,
    { shoppingListUsers }: ShoppingListUserActions.SaveArchived
  ) {
    const status = new StatusUtils(ctx, ShoppingListUserActions.SaveArchived);
    let error: Error;
    try {
      const users = await Promise.all(
        shoppingListUsers.map((u) => this.api.saveArchived$(u).toPromise())
      );
      await ctx.dispatch(new ShoppingListUserActions.UpsertMany(users)).toPromise();
      // TODO: handle errors?
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListUserActions.LoadRelated)
  async loadRelated(
    ctx: StateContext<IShoppingListUserState>,
    { shoppingListUsers }: ShoppingListUserActions.LoadRelated
  ) {
    const userIds: string[] = [];
    for (const lu of shoppingListUsers) {
      if (lu.userId) {
        userIds.push(lu.userId);
      }
    }
    if (userIds.length) {
      ctx.dispatch(new UserActions.GetByIds(userIds));
    }
  }
}
