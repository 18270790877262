import * as ShoppingListUserActions from './shopping-list-user.actions';
import { ShoppingListUserSelectors } from './shopping-list-user.selectors';
import { ShoppingListUserApiService } from './shopping-list-user.service';
import { ShoppingListUserState } from './shopping-list-user.state';

export const ShoppingListUser = {
  state: ShoppingListUserState,
  actions: ShoppingListUserActions,
  select: ShoppingListUserSelectors,
  api: ShoppingListUserApiService,
};
