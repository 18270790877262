import { Component, Inject } from '@angular/core';
import { WINDOW } from '~/core/services';

@Component({
  selector: 'kd-loading-failure',
  template: `
    <kd-modal>
      <div class="modal-inner">
        <div class="p-1 text-center">
          <h2>Oj, något gick fel</h2>
          <hr />
          <div>
            Appen kunde inte ladda som den skulle. Prova ladda om appen eller kontakta oss på
            <a href="mailto:hej@fiffit.com" target="_blank">hej@fiffit.com</a> om felet kvarstår.
          </div>
          <hr />
          <div>
            <button class="btn btn-blackish btn-full" (click)="refresh()">Ladda om appen</button>
          </div>
        </div>
      </div>
    </kd-modal>
  `,
  styles: [
    `
      .modal-inner {
        min-width: 20em;
      }
    `,
  ],
})
export class LoadingFalilureComponent {
  constructor(@Inject(WINDOW) private window: Window) {}

  refresh() {
    this.window.location.reload();
  }
}
