<!-- <kd-loading-page></kd-loading-page> -->
<kd-app-base>
  <kd-drawer-wrapper>
    <router-outlet></router-outlet>

    <dynamic-router-outlet
      *ngFor="let r of dynamicWindows.outletRoutes$ | async"
      [name]="r.outlet"
    ></dynamic-router-outlet>
  </kd-drawer-wrapper>

  <kd-loading-failure *ngIf="loadFailure$ | async"></kd-loading-failure>
</kd-app-base>
