import { Firebase } from './firebase.model';

export enum WebToNativeEvent {
  WEB_HELLO = 'WEB_HELLO',
  REQUIRE_HELLO = 'REQUIRE_HELLO',
  PUSH_ACTIVATE = 'PUSH_ACTIVATE',
  PUSH_STATUS = 'PUSH_STATUS',
  NAVIGATE_BACK_RESULT = 'NAVIGATE_BACK_RESULT',
  LOGIN_PASSWORD = 'LOGIN_PASSWORD',
  LOGIN_FACEBOOK = 'LOGIN_FACEBOOK',
  LOGIN_GOOGLE = 'LOGIN_GOOGLE',
  LOGIN_APPLE = 'LOGIN_APPLE',
  LOGIN_ANONYMOUS = 'LOGIN_ANONYMOUS',
  GET_INITIAL_USER = 'GET_INITIAL_USER',
  LOGOUT = 'LOGOUT',
}

export enum NativeToWebEvent {
  NATIVE_HELLO = 'NATIVE_HELLO',
  PUSH_ACTIVATED = 'PUSH_ACTIVATED',
  PUSH_RECEIVED = 'PUSH_RECEIVED',
  PUSH_STATUS_RESULT = 'PUSH_STATUS_RESULT',
  NAVIGATE_BACK = 'NAVIGATE_BACK',
  SEND_DEEPLINK = 'SEND_DEEPLINK',
  LOGIN_PASSWORD_RESULT = 'LOGIN_PASSWORD_RESULT',
  LOGIN_FACEBOOK_RESULT = 'LOGIN_FACEBOOK_RESULT',
  LOGIN_GOOGLE_RESULT = 'LOGIN_GOOGLE_RESULT',
  LOGIN_APPLE_RESULT = 'LOGIN_APPLE_RESULT',
  LOGIN_ANONYMOUS_RESULT = 'LOGIN_ANONYMOUS_RESULT',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export interface ITalkEventNativeHello {
  deviceType: string;
  language: string;
  manufacturer: string;
  model: string;
  os: string;
  osVersion: string;
  region: string;
  sdkVersion: string;
  uuid: string;
  screen: {
    heightDIPs: number;
    heightPixels: number;
    scale: number;
    widthDIPs: number;
    widthPixels: number;
  };
}

export interface ITalkEventPushActivated {
  token?: string;
  error?: Error;
  success?: boolean;
}
export interface ITalkEventPushStatusResult {
  enabled: boolean;
}
export interface ITalkEventNavigateBackResult {
  navigated: boolean;
}

export interface ITalkEventLoginResult {
  // extends IUserLoginResult really, but cant import it due to TS version mix-up
  user?: Firebase.User;
  token?: string;
  error?: Error;
}

export interface ITalkEventLoginPassword extends Firebase.PasswordLoginOptions {}
