import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import sortBy from 'lodash/sortBy';
import { combineLatest, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { DestroyWatcher } from '~/shared/helpers';
import { IBulkItemsQuery, IPlaceAndLocation, LocationHelper } from '~_shared/models';
import { NGXS } from '../ngxs';

@Injectable()
export class PlaceService extends DestroyWatcher {
  constructor(private ngxs: Store) {
    super();
  }

  readonly isLoadingPlace$ = this.ngxs.select(NGXS.Place.select.status).pipe(map((s) => s.loading));
  readonly isLoadingLocation$ = this.ngxs
    .select(NGXS.Location.select.status)
    .pipe(map((s) => s.loading || !s.lastLoaded));
  readonly isLoading$ = combineLatest([this.isLoadingPlace$, this.isLoadingLocation$]).pipe(
    map(([x1, x2]) => x1 || x2)
  );

  readonly selectedOrDefaultPlace$ = this.ngxs.select(NGXS.Place.select.selectedOrDefault);

  readonly selectedLocationId$ = this.ngxs.select(NGXS.Location.select.selectedId);

  readonly selectedLocation$ = this.ngxs.select(NGXS.Location.select.selected);

  readonly myPlaces$ = this.ngxs.select(NGXS.Place.select.allMine);

  readonly myPlaceAndLocations$ = combineLatest([
    this.myPlaces$,
    this.ngxs.select(NGXS.Location.select.entities),
  ]).pipe(
    map(([places, locationsByIds]) =>
      places.map((place) => {
        return {
          place,
          location: locationsByIds[place.locationId],
        } as IPlaceAndLocation;
      })
    ),
    map((placesAndLocations) =>
      sortBy(placesAndLocations, (pl) => LocationHelper.title(pl.location))
    )
  );

  readonly myLocations$ = this.myPlaces$.pipe(
    map((places) => places.map((p) => p.id)),
    switchMap((placeIds) => this.ngxs.select(NGXS.Place.select.locationsByIdsFn(placeIds))),
    map((locations) => sortBy(locations, (l) => LocationHelper.title(l)))
  );

  getOrLoadLocation$(id: string) {
    // FYI: GetById action only load if not already exists, so ok to run every time
    return this.ngxs
      .dispatch(new NGXS.Location.actions.GetById(id))
      .pipe(switchMap(() => this.ngxs.select(NGXS.Location.select.entityByIdFn(id))));
  }

  getOrLoadBulkLocation$(id: string, forceLoad?: boolean) {
    const shouldLoadFn$ = this.ngxs.select(NGXS.Bulk.select.shouldLocationLoadFn);
    const location$ = this.ngxs.select(NGXS.Location.select.entityByIdFn(id));
    return shouldLoadFn$.pipe(
      first(),
      switchMap((shouldLoadFn) => {
        if (shouldLoadFn(id) || forceLoad) {
          return this.ngxs
            .dispatch(new NGXS.Bulk.actions.GetLocation(id, forceLoad))
            .pipe(switchMap(() => location$));
        }
        return location$;
      })
    );
  }

  getOrLoadBulkItemByQuery$(query: IBulkItemsQuery, forceLoad?: boolean) {
    const shouldLoadFn$ = this.ngxs.select(NGXS.Bulk.select.shouldItemListLoadFn);
    const itemList$ = of(null); // TODO: select list?
    return shouldLoadFn$.pipe(
      first(),
      switchMap((shouldLoadFn) => {
        if (shouldLoadFn(query) || forceLoad) {
          return this.ngxs
            .dispatch(new NGXS.Bulk.actions.GetItemsByQuery(query, forceLoad))
            .pipe(switchMap(() => itemList$));
        }
        return itemList$;
      })
    );
  }

  setSelectedLocation$(locationId: string) {
    return this.ngxs
      .dispatch(new NGXS.Me.actions.SetSelected({ locationId }))
      .pipe(switchMap(() => this.ngxs.select(NGXS.Location.select.selected)));
  }
}
