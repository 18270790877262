import { Bulk } from './bulk';
import { CartItem } from './cart-item';
import { Category } from './category';
import { Filter } from './filter';
import { Item } from './item';
import { ItemBody } from './item-body';
import { Location } from './location';
import { Me } from './me';
import { Order } from './order';
import { Place } from './place';
import { Poll } from './poll';
import { PushMessage } from './push-message';
import { ShoppingList } from './shopping-list';
import { ShoppingListItem } from './shopping-list-item';
import { ShoppingListUser } from './shopping-list-user';
import { Store } from './store';
import { StoreUser } from './store-user';
import { User } from './user';
import { UserEvent } from './user-event';
import { UserFilter } from './user-filter';

export const NGXS = {
  Bulk,
  CartItem,
  Category,
  Filter,
  Item,
  ItemBody,
  Location,
  Me,
  Order,
  Place,
  Poll,
  PushMessage,
  ShoppingList,
  ShoppingListItem,
  ShoppingListUser,
  Store,
  StoreUser,
  User,
  UserEvent,
  UserFilter,
};

export const ngxsServices = Object.keys(NGXS)
  .map((x) => NGXS[x].api)
  .filter((x) => !!x);

export const ngxsStates = Object.keys(NGXS)
  .sort()
  .reverse()
  .map((x) => NGXS[x].state)
  .filter((x) => !!x);
