<div class="push-prompt-wrapper text-center p-1" *ngIf="!activated && initialTokenChecked">
  <div class="push-prompt-text">
    <div class="text-labeled">
      Aktivera notiser för att inte missa uppdateringar kring din paxning.
    </div>
  </div>
  <div class="push-prompt-button py-05">
    <button type="button" class="btn btn-blackish" (click)="onActivate()">
      <span *ngIf="showLoading; else buttonTextDefault" class="d-flex">
        <span>Aktiverar...</span>
        <span class="ts-20 pl-3" *ngIf="showSuccess">
          <kd-animation-success class="ts-70"></kd-animation-success>
        </span>
      </span>
      <ng-template #buttonTextDefault>
        Aktivera
      </ng-template>
    </button>
  </div>
  <div class="push-prompt-error bg-danger text-white p-05" *ngIf="failed">
    <div class="text-labeled ts-70">
      Det gick inte att aktivera notiser, var god kontrollera dina inställningar och försök igen.
    </div>
  </div>
</div>
