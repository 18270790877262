import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BackendService } from '~/core/services/backend.service';
import { IPlace } from '~_shared/models';

@Injectable()
export class PlaceApiService {
  constructor(private backend: BackendService, private fireAuth: AngularFireAuth) {}

  async getMine() {
    return this.backend.get<IPlace[]>('places/by/mine').toPromise();
  }

  async upsert(place: IPlace) {
    if (!place.userId) {
      place.userId = (await this.fireAuth.currentUser).uid;
    }
    return this.backend.patch<IPlace>('places', place).toPromise();
  }

  async delete(placeId: string) {
    return this.backend.delete<boolean>(`places/${placeId}`).toPromise();
  }
}
