import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimationDeclineComponent } from './_components/animation-decline/animation-decline.component';
import { AnimationEmojiSupriseComponent } from './_components/animation-emoji-suprise/animation-emoji-suprise.component';
import { AnimationSuccessComponent } from './_components/animation-success/animation-success.component';

const components = [
  AnimationEmojiSupriseComponent,
  AnimationSuccessComponent,
  AnimationDeclineComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule],
})
export class AnimationsModule {}
