import * as UserEventActions from './user-event.actions';
import { UserEventSelectors } from './user-event.selectors';
import { UserEventApiService } from './user-event.service';
import { UserEventState } from './user-event.state';

export const UserEvent = {
  state: UserEventState,
  actions: UserEventActions,
  select: UserEventSelectors,
  api: UserEventApiService,
};
