import * as CartItemActions from './cart-item.actions';
import { CartItemSelectors } from './cart-item.selectors';
import { CartItemApiService } from './cart-item.service';
import { CartItemState } from './cart-item.state';

export const CartItem = {
  state: CartItemState,
  actions: CartItemActions,
  select: CartItemSelectors,
  api: CartItemApiService,
};
