import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ITalkEventNavigateBackResult, NativeToWebEvent, WebToNativeEvent } from '~_shared/models';
import { DynamicWindowsService } from '../modules/dynamic-windows';
import { NGXS } from '../ngxs';
import { MyUserService } from './my-user.service';
import { NativeTalkerService } from './native-talker.service';
import { WINDOW } from './window.service';

@Injectable()
export class NavigatorService {
  private navigatedStartingPointUrl: string;

  constructor(
    public router: Router,
    private myUser: MyUserService,
    private ngxs: Store,
    private location: Location,
    private dynamicWindow: DynamicWindowsService,
    @Inject(WINDOW) private window: Window,
    private nativeTalker: NativeTalkerService
  ) {
    this.trackNativeBack();
    this.trackNativeDeepLink();
  }

  async getStartingPointUrl() {
    return combineLatest([
      this.ngxs.select(NGXS.Me.select.auth),
      this.ngxs.select(NGXS.Location.select.selectedId),
      this.ngxs.select(NGXS.Place.select.allMine),
      this.myUser.stores$,
    ])
      .pipe(
        first(),
        map(([auth, selectedLocationId, places, stores]) => {
          if (stores && stores.length) {
            return this.router.createUrlTree(['/admin/store', stores[0].id]).toString();
          }
          const useSelected = places.some((p) => p.locationId === selectedLocationId);
          const locationId = useSelected ? selectedLocationId : places?.[0]?.locationId;
          if (locationId && auth) {
            return this.router.createUrlTree([locationId]).toString();
          }
          return this.router.createUrlTree(['/setup']).toString();
        })
      )
      .toPromise();
  }

  async gotoStartingPoint(replaceUrl?: boolean) {
    const startUrl = await this.getStartingPointUrl();
    await this.router.navigateByUrl(startUrl, { replaceUrl });
    await this.dynamicWindow.closeAll();
    this.setStartingPoint();
  }

  /**
   * Store startingpoint url with delay to resolve auto-redirects
   */
  async setStartingPoint(url?: string, waitMs = 500): Promise<string> {
    return new Promise((resolve) =>
      setTimeout(() => {
        this.navigatedStartingPointUrl = url ?? this.router.url;
        resolve(this.navigatedStartingPointUrl);
      }, waitMs)
    );
  }

  async isStartingPoint(url = this.router.url) {
    return url && url === this.navigatedStartingPointUrl;
  }

  async goBack(): Promise<boolean> {
    if (!this.navigatedStartingPointUrl) {
      this.gotoStartingPoint(true);
      return true;
    }
    if (await this.isStartingPoint()) {
      return false;
    }
    if (this.window.history?.length) {
      this.location.back();
      return true;
    }
    this.gotoStartingPoint(true);
    return true;
  }

  private trackNativeBack() {
    this.nativeTalker.listenTo$(NativeToWebEvent.NAVIGATE_BACK).subscribe(async () => {
      const navigated = await this.goBack();
      this.nativeTalker.talk<ITalkEventNavigateBackResult>(WebToNativeEvent.NAVIGATE_BACK_RESULT, {
        navigated,
      });
    });
  }

  private trackNativeDeepLink() {
    this.nativeTalker.listenTo$<string>(NativeToWebEvent.SEND_DEEPLINK).subscribe((url) => {
      const u = new URL(url);
      const path = u.pathname + u.search;
      this.router.navigateByUrl(path);
    });
  }
}
