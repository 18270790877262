import { IShoppingListUser } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[ShoppingListUser] UpsertMany';
  constructor(public shoppingListUsers: IShoppingListUser[], public loadRelated?: boolean) {}
}

export class GetByListIds {
  static readonly type = '[ShoppingListUser] GetByListIds';
  constructor(public shoppingListIds: string[]) {}
}

export class Save {
  static readonly type = '[ShoppingListUser] Save';
  constructor(public shoppingListUsers: IShoppingListUser[]) {}
}
export class SaveArchived {
  static readonly type = '[ShoppingListUser] SaveArchived';
  constructor(public shoppingListUsers: IShoppingListUser[]) {}
}

export class LoadRelated {
  static readonly type = '[ShoppingListUser] LoadRelated';
  constructor(public shoppingListUsers: IShoppingListUser[]) {}
}
