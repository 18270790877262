import * as UserActions from './user.actions';
import { UserSelectors } from './user.selectors';
import { UserApiService } from './user.service';
import { UserState } from './user.state';

export const User = {
  state: UserState,
  actions: UserActions,
  select: UserSelectors,
  api: UserApiService,
};
