import { Component, HostBinding, Input, OnInit } from '@angular/core';

export enum ActionBarType {
  None = 'none',
  Swirl = 'swirl',
  Flat = 'flat',
}
export type ActionBarTypes = 'swirl' | 'flat' | 'none';

@Component({
  selector: 'kd-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit {
  @Input() type: ActionBarTypes = ActionBarType.None;
  @Input() color: string;
  @Input() innerCssClass: string = 'p-025 inverted';

  @HostBinding('attr.class') get cssClass() {
    return this.type;
  }

  constructor() {}

  ngOnInit() {}
}
