import * as StoreUserActions from './store-user.actions';
import { StoreUserSelectors } from './store-user.selectors';
import { StoreUserApiService } from './store-user.service';
import { StoreUserState } from './store-user.state';

export const StoreUser = {
  state: StoreUserState,
  actions: StoreUserActions,
  select: StoreUserSelectors,
  api: StoreUserApiService,
};
