import { ILatLng, ILocation } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[Location] UpsertMany';
  constructor(public locations: ILocation[]) {}
}

export class GetById {
  static readonly type = '[Location] GetById';
  constructor(public id: string) {}
}

export class GetByIds {
  static readonly type = '[Location] GetByIds';
  constructor(public ids: string[]) {}
}

export class GetBySearchPos {
  static readonly type = '[Location] GetBySearchPos';
  constructor(public pos: ILatLng) {}
}

export class GetBySearchZip {
  static readonly type = '[Location] GetBySearchZip';
  constructor(public searchText: string) {}
}

export class GetBySearchCity {
  static readonly type = '[Location] GetBySearchCity';
  constructor(public searchText: string) {}
}
