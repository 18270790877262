import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IFilter } from '~_shared/models';

@Injectable()
export class FilterApiService {
  constructor(private backend: BackendService) {}

  getMine$() {
    return this.backend.get<IFilter[]>(`filters`);
  }

  getByIds$(ids: string[]) {
    if (ids.length < 10) {
      return this.backend.get<IFilter[]>(`filters/ids`, { params: { ids: ids.join(',') } });
    } else {
      return this.backend.post<IFilter[]>(`filters/ids`, ids);
    }
  }

  save$(filters: IFilter[]) {
    return this.backend.patch<IFilter[]>(`filters`, filters);
  }

  removeById$(filterId: string) {
    return this.backend.delete<IFilter[]>(`filters/${filterId}`);
  }
  removeByIds$(filterIds: string[]) {
    return this.backend.post<IFilter[]>(`filters/remove`, filterIds);
  }
}
