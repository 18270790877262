import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IShoppingListUser } from '~_shared/models';

@Injectable()
export class ShoppingListUserApiService {
  constructor(private backend: BackendService) {}

  getByListId$(listId: string) {
    return this.backend.get<IShoppingListUser[]>(`shopping-list/${listId}/users`);
  }

  save$(shoppingListUser: IShoppingListUser) {
    const listId = shoppingListUser.shoppingListId;
    return this.backend.patch<IShoppingListUser>(`shopping-list/${listId}/users`, shoppingListUser);
  }

  saveArchived$(shoppingListUser: IShoppingListUser) {
    const { shoppingListId, archivedAt } = shoppingListUser;
    return this.backend.put<IShoppingListUser>(`shopping-list/${shoppingListId}/users/archived`, {
      archivedAt,
    });
  }
}
