import { Injectable } from '@angular/core';
import { Action, Actions, ofActionDispatched, State, StateContext } from '@ngxs/store';
import flatten from 'lodash/flatten';
import { merge, timer } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { IShoppingListItem, ShoppingListHelper } from '~_shared/models';
import * as ItemActions from '../item/item.actions';
import * as StoreActions from '../store/store.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as ShoppingListItemActions from './shopping-list-item.actions';
import { ShoppingListItemApiService } from './shopping-list-item.service';

export interface IShoppingListItemState extends IEntityState<IShoppingListItem>, IStatusState {}

@State<IShoppingListItemState>({
  name: 'shoppingListItem',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class ShoppingListItemState {
  constructor(private api: ShoppingListItemApiService, private actions$: Actions) {}

  @Action(ShoppingListItemActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IShoppingListItemState>,
    { shoppingListItems, loadRelated }: ShoppingListItemActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, ShoppingListItemActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), shoppingListItems));
    if (loadRelated) {
      await ctx.dispatch(new ShoppingListItemActions.LoadRelated(shoppingListItems)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(ShoppingListItemActions.GetByListIds)
  async getByListIds(
    ctx: StateContext<IShoppingListItemState>,
    { shoppingListIds }: ShoppingListItemActions.GetByListIds
  ) {
    const status = new StatusUtils(ctx, ShoppingListItemActions.GetByListIds);
    let error: Error;
    try {
      const listItemsArray = await Promise.all(
        shoppingListIds.map((listId) => this.api.getByListId$(listId).toPromise())
      );
      const listItems = flatten(listItemsArray);
      await ctx.dispatch(new ShoppingListItemActions.UpsertMany(listItems)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListItemActions.Save)
  async save(
    ctx: StateContext<IShoppingListItemState>,
    { shoppingListItems }: ShoppingListItemActions.Save
  ) {
    const status = new StatusUtils(ctx, ShoppingListItemActions.Save);
    let error: Error;
    try {
      const currEntities = ctx.getState().entities;

      // add optimistic
      const optimisticItems = shoppingListItems.map((lit) => {
        lit.id = ShoppingListHelper.listItemId(lit); // add for saving data as well
        const optItem: IShoppingListItem = {
          ...(currEntities[lit.id] || {}), // merge existing props
          ...lit,
        };
        return optItem;
      });
      ctx.dispatch(new ShoppingListItemActions.UpsertMany(optimisticItems)).toPromise();

      // update actually saved (cancel if new action with same listItem id occurs)
      const items = await Promise.all(
        shoppingListItems.map((slit) =>
          timer(500) // cancellable timer as debouncer
            .pipe(
              switchMap(() => this.api.save$(slit)),
              takeUntil(this._cancelled$(slit.id))
            )
            .toPromise()
        )
      );
      await ctx.dispatch(new ShoppingListItemActions.UpsertMany(items)).toPromise();
      // TODO: handle errors?
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListItemActions.Remove)
  async remove(
    ctx: StateContext<IShoppingListItemState>,
    { shoppingListItems }: ShoppingListItemActions.Remove
  ) {
    const status = new StatusUtils(ctx, ShoppingListItemActions.Remove);
    let error: Error;
    try {
      // remove optimistic
      shoppingListItems.forEach((lit) => (lit.id = ShoppingListHelper.listItemId(lit)));
      ctx.patchState(EntityUtils.removeMany(ctx.getState(), shoppingListItems));

      // update actually removed
      const items = await Promise.all(
        shoppingListItems.map((slit) =>
          timer(500) // cancellable timer as debouncer
            .pipe(
              switchMap(() => this.api.remove$(slit)),
              takeUntil(this._cancelled$(slit.id))
            )
            .toPromise()
        )
      );
      ctx.patchState(EntityUtils.removeMany(ctx.getState(), items));
      // TODO: handle errors?
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListItemActions.LoadRelated)
  async loadRelated(
    ctx: StateContext<IShoppingListItemState>,
    { shoppingListItems }: ShoppingListItemActions.LoadRelated
  ) {
    const itemIds: string[] = [];
    const storeIds: string[] = [];
    for (const lit of shoppingListItems) {
      if (lit.itemId) {
        itemIds.push(lit.itemId);
      }
      if (lit.groupStoreId) {
        storeIds.push(lit.groupStoreId);
      }
    }
    if (itemIds.length) {
      ctx.dispatch(new ItemActions.GetByIds(itemIds));
    }
    if (storeIds.length) {
      ctx.dispatch(new StoreActions.GetByIds(storeIds));
    }
  }

  /** Helper: detect if any new actions dispatches with same id */
  private _cancelled$(slitId: string) {
    return merge(
      this.actions$.pipe(
        ofActionDispatched(ShoppingListItemActions.Save),
        filter((a: ShoppingListItemActions.Save) =>
          a.shoppingListItems.some((slit) => slit.id === slitId)
        )
      ),
      this.actions$.pipe(
        ofActionDispatched(ShoppingListItemActions.Remove),
        filter((a: ShoppingListItemActions.Remove) =>
          a.shoppingListItems.some((slit) => slit.id === slitId)
        )
      )
    ).pipe(first());
  }
}
