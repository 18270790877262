import { Inject, Injectable } from '@angular/core';
import { LatLng } from '~_shared/models';
import { PlatformService } from './platform.service';
import { WINDOW } from './window.service';

@Injectable()
export class GeolocationService {
  constructor(private platform: PlatformService, @Inject(WINDOW) private window: Window) {}

  async getPosition() {
    return this.platform.isWeb() ? this.getPositionByBrowser() : this.getPositionByNative();
  }

  async getPositionByBrowser() {
    return new Promise<LatLng>((resolve, reject) => {
      this.window.navigator.geolocation.getCurrentPosition(
        (pos) => resolve(LatLng.fromObject(pos.coords)),
        (err) => reject(err)
      );
    });
  }

  async getPositionByNative() {
    return Promise.resolve<LatLng>(null);
  }
}
