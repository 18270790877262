import { createSelector, Selector } from '@ngxs/store';
import pick from 'lodash/pick';
import { ICartItem, IItem, IOrder, IOrderItem, IStore, OrderStatus } from '~_shared/models';
import { ItemSelectors } from '../item/item.selectors';
import { MeSelectors } from '../me/me.selectors';
import { StoreSelectors } from '../store/store.selectors';
import { IDictionary } from '../_utils';
import { CartItemState, ICartItemState } from './cart-item.state';

export class CartItemSelectors {
  @Selector([CartItemState])
  static state(s: ICartItemState) {
    return s;
  }

  @Selector([CartItemState])
  static status(s: ICartItemState) {
    return s.status;
  }
  @Selector([CartItemState])
  static hasLoaded(s: ICartItemState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([CartItemState])
  static entities(s: ICartItemState) {
    return s.entities;
  }

  @Selector([CartItemState])
  static ids(s: ICartItemState) {
    return s.ids;
  }

  @Selector([CartItemState])
  static all(s: ICartItemState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([CartItemSelectors.all])
  static entitiesByItemIds(all: ICartItem[]) {
    return all.reduce((byItemIds, ci) => {
      byItemIds[ci.itemId] = ci;
      return byItemIds;
    }, {} as IDictionary<ICartItem>);
  }

  @Selector([CartItemSelectors.all, ItemSelectors.entityByIdFn, StoreSelectors.entities])
  static dibsables(
    cartItems: ICartItem[],
    entityByIdFn: (id: string) => IItem,
    storesByIds: IDictionary<IStore>
  ) {
    return cartItems
      .reduce((allDibsables, ci) => {
        const item = entityByIdFn(ci.itemId);
        const store = storesByIds[item?.storeId];
        if (item && store) {
          const findStore = () => allDibsables.find((d) => d.store.id === store.id);
          const dibsable = findStore() || {
            store,
            cartItems: [],
          };
          dibsable.cartItems.push({ ...ci, item });
          if (!findStore()) {
            allDibsables.push(dibsable);
          }
        }
        return allDibsables;
      }, [] as IDibsable[])
      .sort((a, b) => {
        if (a.store.title < b.store.title) {
          return 1;
        }
        if (a.store.title > b.store.title) {
          return -1;
        }
      });
  }

  @Selector([CartItemSelectors.dibsables, MeSelectors.userId])
  static orderables(dibsables: IDibsable[], userId: string): IOrder[] {
    return dibsables.map((d) => {
      const order: IOrder = {
        userId,
        storeId: d.store.id,
        orderItems: d.cartItems.map((cit) => {
          return {
            itemId: cit.itemId,
            ...pick(cit.item, ['title', 'shortInfo', 'priceFull', 'priceDiscounted', 'extId']),
            quantity: {
              ordered: cit.quantity,
            },
            message: cit.message,
            image: cit.item.images?.[0],
          } as IOrderItem;
        }),
        status: OrderStatus.NotSent,
        statusChanges: [],
      };
      return order;
    });
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: ICartItemState) => {
      return s.entities[id];
    });
  }

  static entityByItemIdFn(itemId: string) {
    return createSelector([this.all], (all: ICartItem[]) => {
      return all.find((x) => x.itemId === itemId) as ICartItem | undefined;
    });
  }
}

export interface IDibsable {
  store: IStore;
  cartItems: Array<ICartItem & { item: IItem }>;
}
