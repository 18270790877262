import { ILatLngShort } from './lat-lng.model';

export interface IAddress {
  streetLine1?: string;
  streetLine2?: string;
  postalCode?: string;
  postalCity?: string;
  country?: string;
}

export interface IAddressGeopos {
  address: IAddress;
  geopos: ILatLngShort;
}

export class AddressHelper {
  static parseSimple(simpleAddress: string): IAddress {
    simpleAddress = (simpleAddress || '').toString().trim();
    const address: IAddress = {};
    const addrLines = simpleAddress.split(',');
    const postalIndex = addrLines.findIndex((ap) => this.isPostalLine(ap));
    if (postalIndex >= 1) {
      address.streetLine1 = addrLines[0]?.trim();
      if (postalIndex >= 2) {
        address.streetLine2 = addrLines[1]?.trim();
      }
    }
    // add postalCode + postalCity
    Object.assign(address, this.parsePostalLine(addrLines[postalIndex]));

    if (addrLines.length === postalIndex + 2) {
      address.country = addrLines[postalIndex + 1]?.trim();
    }
    return address;
  }

  static toSimple(
    address: IAddress,
    include: { country?: boolean; postalCode?: boolean } = { country: false, postalCode: false }
  ) {
    let simpleAddress = '';
    const append = (line?: string) => {
      simpleAddress += line ? ((simpleAddress ? ', ' : '') + line).trim() : '';
    };
    append(address?.streetLine1);
    append(address?.streetLine2);
    const postals = [address?.postalCity];
    if (include.postalCode && address?.postalCode) {
      postals.splice(0, 0, address?.postalCode);
    }
    append(postals.filter((x) => !!x).join(' '));
    if (include.country) {
      append(address?.country);
    }
    return simpleAddress;
  }

  static isPostalLine(line: string) {
    return /^[\d\s]*\s.*$/.test((line || '').trim());
  }
  static parsePostalLine(line: string): { postalCode?: string; postalCity?: string } {
    let postalCode;
    let postalCity;
    if (this.isPostalLine(line)) {
      const matches = /^(?<code>[\d\s]*)\s*(?<city>.*)$/.exec(line);
      if (matches?.groups) {
        postalCode = (matches?.groups?.code || '').trim();
        postalCity = (matches?.groups?.city || '').trim();
      }
    }
    return { postalCode, postalCity };
  }
}
