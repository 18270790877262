import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { NativeTalkerService } from '~/core/services/native-talker.service';
import { DestroyWatcher } from '~/shared/helpers';
import { PUSH_TNS_SERVICE_TOKEN } from './push-tns.service';
import { PUSH_WEB_SERVICE_TOKEN } from './push-web.service';
import { IPushService } from './push.models';

/**
 * The main push service, which should be the only one in use.
 * This detects which sub-service to use based on client's environment,
 * and proxy props/methods.
 */
@Injectable({ providedIn: 'root' })
export class PushService extends DestroyWatcher implements IPushService {
  constructor(private injector: Injector, private nativeTalker: NativeTalkerService) {
    super();

    // track which push service to use
    this.nativeTalker.hasTalker$
      .pipe(distinctUntilChanged(), takeUntil(this.ngDestroyed$))
      .subscribe((isNativeWrapped) => {
        if (isNativeWrapped) {
          this._push$.next(this.injector.get(PUSH_TNS_SERVICE_TOKEN));
        } else {
          this._push$.next(this.injector.get(PUSH_WEB_SERVICE_TOKEN));
        }
      });
  }

  // holds which push service in use
  private _push$ = new BehaviorSubject<IPushService>(null);

  readonly pushRaw$ = this._push$.asObservable();
  readonly push$ = this.pushRaw$.pipe(filter((x) => !!x));

  get push() {
    return this._push$.value;
  }

  readonly isSupported$ = this.push$.pipe(switchMap((p) => p.isSupported$));
  readonly isPermissionGranted$ = this.push$.pipe(switchMap((p) => p.isPermissionGranted$));
  readonly isPermissionDenied$ = this.push$.pipe(switchMap((p) => p.isPermissionDenied$));
  readonly messages$ = this.push$.pipe(switchMap((p) => p.messages$));

  getToken$(requestPermissionIfNotGranted?: boolean): Observable<string> {
    return this.push$.pipe(switchMap((p) => p.getToken$(requestPermissionIfNotGranted)));
  }
}
