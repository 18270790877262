import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppInitComponent } from './app-init.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppBaseModule } from './shared/components/app-base/app-base.module';
import { LoadingPageModule } from './shared/components/loading-page/loading-page.module';

registerLocaleData(localeSv);

@NgModule({
  declarations: [AppComponent, AppInitComponent],
  imports: [
    LoadingPageModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    AppBaseModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'sv-SE' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
