export interface IVariant {
  [variantType: number]: string | number;
  quantity?: number;
  q?: number; // used when compressed
}

export enum VariantType {
  Unknown = 0,
  Size = 10,
  ShoeSizeEU = 18,
  ShoeSizeUS = 19,
  Color = 20,
  // ...more
}

export class VariantHelper {
  static setShoeSize(
    variant: IVariant,
    size?: string | number
  ): 'EU' | 'US' | 'general' | undefined {
    if (!size) return;
    const sizeNum = +(size + '').replace(/[^0-9\.]+/g, '');
    if (sizeNum > 0 && sizeNum <= 15) {
      variant[VariantType.ShoeSizeUS] = sizeNum;
      return 'US';
    }
    if (sizeNum > 20 && sizeNum <= 50) {
      variant[VariantType.ShoeSizeEU] = sizeNum;
      return 'EU';
    }
    // general size
    variant[VariantType.Size] = size;
    return 'general';
  }
}
