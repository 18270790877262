import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResizeObserverEntry } from 'ngx-resize-observer';
import { DynamicWindowsService } from '~/core/modules/dynamic-windows';

@Component({
  selector: 'kd-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() dynamicWindowsCloser: DynamicWindowsService;
  @Input() showCloseIcon: boolean;
  @Input() backdropClose: boolean;
  @Input() size: 'sm' | 'md' | 'lg';
  @Output() closeIconClicked = new EventEmitter<void>();
  @Output() backdropClicked = new EventEmitter<void>();

  closeIconLeft = 0;
  closeIconTop = 0;

  get isCloseIconVisible() {
    return (this.dynamicWindowsCloser && this.showCloseIcon !== false) || this.showCloseIcon;
  }

  onBoxResize(e: ResizeObserverEntry) {
    this.closeIconLeft = e.contentRect.width / 2;
    this.closeIconTop = -(e.contentRect.height / 2);
  }

  onCloseIconClicked() {
    this.closeIconClicked.emit();
    this.closeDynamicWindow();
  }

  onBackdropClicked() {
    this.backdropClicked.emit();
    if (this.backdropClose) {
      this.closeDynamicWindow();
    }
  }

  private closeDynamicWindow() {
    if (this.dynamicWindowsCloser) {
      this.dynamicWindowsCloser.close();
    }
  }
}
