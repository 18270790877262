import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IShoppingList } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as ShoppingListItemActions from './../shopping-list-item/shopping-list-item.actions';
import * as ShoppingListUserActions from './../shopping-list-user/shopping-list-user.actions';
import * as ShoppingListActions from './shopping-list.actions';
import { ShoppingListApiService } from './shopping-list.service';

export interface IShoppingListState extends IEntityState<IShoppingList>, IStatusState {}

@State<IShoppingListState>({
  name: 'shoppingList',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class ShoppingListState {
  constructor(private api: ShoppingListApiService) {}

  @Action(ShoppingListActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IShoppingListState>,
    { shoppingLists, loadRelated }: ShoppingListActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, ShoppingListActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), shoppingLists));
    if (loadRelated) {
      await ctx.dispatch(new ShoppingListActions.LoadRelated(shoppingLists)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(ShoppingListActions.GetMine)
  async getMine(ctx: StateContext<IShoppingListState>, {}: ShoppingListActions.GetMine) {
    const status = new StatusUtils(ctx, ShoppingListActions.GetMine);
    let error: Error;
    try {
      const shoppingLists = await this.api.getMine$().toPromise();
      await ctx.dispatch(new ShoppingListActions.UpsertMany(shoppingLists, true)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListActions.Save)
  async save(ctx: StateContext<IShoppingListState>, { shoppingLists }: ShoppingListActions.Save) {
    const status = new StatusUtils(ctx, ShoppingListActions.Save);
    let error: Error;
    try {
      const lists = await Promise.all(shoppingLists.map((sl) => this.api.save$(sl).toPromise()));
      await ctx.dispatch(new ShoppingListActions.UpsertMany(lists)).toPromise();
      // TODO: handle errors?
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(ShoppingListActions.LoadRelated)
  async loadRelated(
    ctx: StateContext<IShoppingListState>,
    { shoppingLists }: ShoppingListActions.LoadRelated
  ) {
    const listIds = shoppingLists.map((sl) => sl.id);
    if (listIds.length) {
      // TODO: load list items + users
      await Promise.all([
        ctx.dispatch(new ShoppingListItemActions.GetByListIds(listIds)).toPromise(),
        ctx.dispatch(new ShoppingListUserActions.GetByListIds(listIds)).toPromise(),
      ]);
    }
  }
}
