import { createSelector, Selector } from '@ngxs/store';
import { IOrder } from '~_shared/models';
import { IOrderState, OrderState } from './order.state';

export class OrderSelectors {
  @Selector([OrderState])
  static state(s: IOrderState) {
    return s;
  }

  @Selector([OrderState])
  static status(s: IOrderState) {
    return s.status;
  }
  @Selector([OrderState])
  static hasLoaded(s: IOrderState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([OrderState])
  static entities(s: IOrderState) {
    return s.entities;
  }

  @Selector([OrderState])
  static ids(s: IOrderState) {
    return s.ids;
  }

  @Selector([OrderState])
  static all(s: IOrderState) {
    return s.ids.map((id) => s.entities[id]);
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IOrderState) => {
      return s.entities[id];
    });
  }

  static allByUserFn(userId: string) {
    return createSelector([this.all], (all: IOrder[]) => {
      return all.filter((o) => o.userId === userId);
    });
  }

  static allByStoreFn(storeId: string) {
    return createSelector([this.all], (all: IOrder[]) => {
      return all.filter((o) => o.storeId === storeId);
    });
  }
}
