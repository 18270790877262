import * as PushMessageActions from './push-message.actions';
import { PushMessageSelectors } from './push-message.selectors';
import { PushMessageApiService } from './push-message.service';
import { PushMessageState } from './push-message.state';

export const PushMessage = {
  state: PushMessageState,
  actions: PushMessageActions,
  select: PushMessageSelectors,
  api: PushMessageApiService,
};
