import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// Disable IOS webview (which adds 'initial-scale=1' as default value to all webviews)
var viewPortValue = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';
function ensureViewport() {
  document.querySelectorAll('meta').forEach((m) => {
    if (m.name === 'viewport' && m.content !== viewPortValue) {
      m.content = viewPortValue;
    }
  });
}
window.addEventListener('load', ensureViewport);
