import dayjs from '../../../_shared/libs/dayjs';
import { IDictionary } from '../interfaces';
import { IItem } from './item.model';

export interface IItemPushByUser {
  /** userId */
  id?: string;
  /** number = epoch */
  places?: { [placeId: string]: number };
  /** number = epoch */
  stores?: { [storeId: string]: number };
  /** number = epoch */
  items?: { [itemId: string]: number };
}

export class ItemPushByUser {
  constructor(data?: object) {
    Object.assign(this, data);
  }

  static clearProp(
    it: IItemPushByUser,
    propName: 'items' | 'stores' | 'places',
    olderThanDays = 14,
    onlyIfMoreThanNr?: number
  ) {
    it = it || { stores: {}, items: {}, places: {} };
    it[propName] = it[propName] || {};
    const prop = it[propName]!;

    if (onlyIfMoreThanNr && Object.keys(prop).length <= onlyIfMoreThanNr) {
      return it;
    }

    for (const itemId of Object.keys(prop)) {
      const sentDate = prop[itemId];
      const diffDays = sentDate
        ? Math.abs(dayjs(sentDate).diff(new Date(), 'day'))
        : olderThanDays + 1;
      if (diffDays > olderThanDays) {
        delete prop[itemId];
      }
    }
    return it;
  }

  static clearItems(it: IItemPushByUser, olderThanDays?: number) {
    return this.clearProp(it, 'items', olderThanDays, 100);
  }

  static clearStores(it: IItemPushByUser, olderThanDays?: number) {
    return this.clearProp(it, 'stores', olderThanDays, 50);
  }

  static clearPlaces(it: IItemPushByUser, olderThanDays?: number) {
    return this.clearProp(it, 'places', olderThanDays, 20);
  }

  static clear(it: IItemPushByUser, olderThanDays?: number) {
    const it2 = this.clearStores(it, olderThanDays);
    const it3 = this.clearItems(it2, olderThanDays);
    const it4 = this.clearPlaces(it3, olderThanDays);
    return it4;
  }

  static isPushableItem(
    itemPushByUser: IItemPushByUser,
    item: IItem,
    placeId: string,
    itemsAndStoreMaxAgeInHours = 8,
    placeMaxAgeInHours = 4
  ) {
    const items = itemPushByUser.items || {};
    const stores = itemPushByUser.stores || {};
    const places = itemPushByUser.stores || {};
    const itemId = item.id!;
    const storeId = item.storeId!;

    const hoursSinceItemPushed = items[itemId]
      ? Math.abs(dayjs(items[itemId]).diff(new Date(), 'hour'))
      : itemsAndStoreMaxAgeInHours + 1;
    const hoursSinceStorePushed = stores[storeId]
      ? Math.abs(dayjs(stores[storeId]).diff(new Date(), 'hour'))
      : itemsAndStoreMaxAgeInHours + 1;
    const hoursSincePlacePushed = places[placeId]
      ? Math.abs(dayjs(places[placeId]).diff(new Date(), 'hour'))
      : placeMaxAgeInHours + 1;

    const itemIsOk = hoursSinceItemPushed > itemsAndStoreMaxAgeInHours;
    const storeIsOk = hoursSinceStorePushed > itemsAndStoreMaxAgeInHours;
    const placeIsOk = hoursSincePlacePushed > placeMaxAgeInHours;

    return itemIsOk && storeIsOk && placeIsOk;
  }

  static mergeInfo(
    userId: string,
    placeIds: string[],
    storeIds: string[],
    itemIds: string[],
    itemPushByUsers?: IDictionary<IItemPushByUser>
  ) {
    const itemPushByUser = this.clear(itemPushByUsers?.[userId] || {});
    itemPushByUser.id = userId;
    (placeIds || []).forEach((id) => (itemPushByUser.places![id] = Date.now()));
    (storeIds || []).forEach((id) => (itemPushByUser.stores![id] = Date.now()));
    (itemIds || []).forEach((id) => (itemPushByUser.items![id] = Date.now()));
    return itemPushByUser;
  }
}
