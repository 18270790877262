
export enum GooglePlaceType {
  Unknown,
  Store
}

export interface IGooglePlace {
  id?: string;
  type?: GooglePlaceType;
  data?: object;
}
