import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IShoppingListItem } from '~_shared/models';

@Injectable()
export class ShoppingListItemApiService {
  constructor(private backend: BackendService) {}

  getByListId$(listId: string) {
    return this.backend.get<IShoppingListItem[]>(`shopping-list/${listId}/items`);
  }

  save$(shoppingListItem: IShoppingListItem) {
    const listId = shoppingListItem.shoppingListId;
    return this.backend.patch<IShoppingListItem>(`shopping-list/${listId}/items`, shoppingListItem);
  }

  remove$(shoppingListItem: IShoppingListItem) {
    const listId = shoppingListItem.shoppingListId;
    const listItemId = shoppingListItem.id;
    return this.backend.delete<IShoppingListItem>(`shopping-list/${listId}/items/${listItemId}`);
  }
}
