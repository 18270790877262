import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IUserFilter } from '~_shared/models';

@Injectable()
export class UserFilterApiService {
  constructor(private backend: BackendService) {}

  getMine$() {
    return this.backend.get<IUserFilter[]>(`user-filters`);
  }

  save$(userFilter: IUserFilter) {
    return this.backend.patch<IUserFilter[]>(`user-filters`, userFilter);
  }

  removeByIds$(userFilterIds: string[]) {
    return this.backend.post<IUserFilter[]>(`user-filters/remove`, userFilterIds);
  }
}
