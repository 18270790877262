import { Pipe, PipeTransform } from '@angular/core';
import { cleanTelephone } from '~_shared/utils/misc.utils';

/**
 * Format telephone number
 * E.g: 0700-11 22 33 => +46700112233
 */
@Pipe({
  name: 'tel',
})
export class TelPipe implements PipeTransform {
  transform(v: string, options?: { countryCode: string }) {
    return cleanTelephone(v, options);
  }
}
