import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StSPipe } from './st-s.pipe';
import { TelPipe } from './tel.pipe';

const pipes = [StSPipe, TelPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class StringTweaksModule {}
