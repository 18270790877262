import { IConnectStore, IStore } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[Store] UpsertMany';
  constructor(public stores: IStore[], public locationId?: string) {}
}

export class GetAll {
  static readonly type = '[Store] GetAll';
  constructor() {}
}

export class GetById {
  static readonly type = '[Store] GetById';
  constructor(public id: string) {}
}

export class GetByIds {
  static readonly type = '[Store] GetByIds';
  constructor(public ids: string[]) {}
}

export class GetByLocation {
  static readonly type = '[Store] GetByLocation';
  constructor(public locationId: string) {}
}

export class Update {
  static readonly type = '[Store] Update';
  constructor(public store: Partial<IStore>, public stateOnly?: boolean) {}
}

export class Connect {
  static readonly type = '[Store] Connect';
  constructor(public connectStoreId: number, public connectStore: IConnectStore) {}
}
