import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IUser } from '~_shared/models';

@Injectable()
export class UserApiService {
  constructor(private backend: BackendService) {}

  getById$(id: string) {
    return this.backend.get<IUser>(`users/${id}`);
  }

  getByIds$(ids: string[]) {
    if (ids.length < 10) {
      return this.backend.get<IUser[]>(`users/ids`, { params: { ids: ids.join(',') } });
    } else {
      return this.backend.post<IUser[]>(`users/ids`, ids);
    }
  }

  update$(user: IUser) {
    return this.backend.put<IUser>(`users/${user.id}`, user);
  }
}
