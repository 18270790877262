import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationExtras,
  Route,
  Router,
  UrlSegmentGroup,
} from '@angular/router';
import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, first, map, takeUntil } from 'rxjs/operators';
import { DestroyWatcher } from '~/shared/helpers';
import { DynamicWindowsInitService } from './dynamic-windows-init.service';
import { DynamicWindowsHelper } from './dynamic-windows.helper';

export type DynamicWindowOnCloseFn = <T>(value?: T | PromiseLike<T>) => void;

export interface IDynamicWindow {
  id?: string;
  path?: string;
  onCloseFn?: DynamicWindowOnCloseFn;
  index?: number;
}

const defaultNumOfOutlets = 10;

@Injectable()
export class DynamicWindowsService extends DestroyWatcher {
  private _windowsCounter = 0;
  private _windows$ = new BehaviorSubject<IDynamicWindow[]>([]);

  // number of outlets that will be generated and available
  public numOfOutlets$ = new BehaviorSubject(defaultNumOfOutlets);

  public outletRoutes$ = this.numOfOutlets$.pipe(
    map((length) =>
      Array.from({ length }).map<Route>((_, i) => ({
        outlet: 'w' + i,
        path: 'w',
        loadChildren: () =>
          import('./dynamic-windows-routing.module').then((m) => m.DynamicWindowsRoutingModule),
      }))
    )
  );

  public set windows(value: IDynamicWindow[]) {
    this._windows$.next(uniqBy(value, (w) => w.id));
  }
  public get windows() {
    return this._windows$.value;
  }
  public get windows$() {
    return this._windows$.asObservable();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dwInit: DynamicWindowsInitService
  ) {
    super();
    this.initRoutes();
    this.initListenForChanges();
  }

  public async open<T>(path: any | string, extras?: NavigationExtras) {
    return new Promise<T>(async <T>(onCloseFn) => {
      path = Array.isArray(path) ? path.join('/') : path;
      const newWindow = await this.addNew(path, onCloseFn);
      this.navigate(newWindow, extras);
    });
  }

  public add(w: IDynamicWindow) {
    this.windows = [...this.windows, w];
  }

  public async close<T>(route?: ActivatedRoute | ActivatedRouteSnapshot, outputData?: T) {
    const w = this.getWindowByRoute(route);
    const goto = [{ outlets: { [w?.id]: null } }];
    this.router.navigate(goto, { replaceUrl: true, queryParamsHandling: 'preserve' });
    if (w?.onCloseFn) {
      w.onCloseFn(outputData);
    }
    // give it time to navigate properly
    await new Promise((resolve) => setTimeout(resolve, 50));
    return;
  }

  public async closeAll(matchUrlPattern?: RegExp | string) {
    const dwRoutes = this.getDynamicRoutes();
    for (const dwRoute of dwRoutes) {
      if (matchUrlPattern) {
        const fullUrl = this.getFullUrlPathByRoute(dwRoute);
        const isMatched = new RegExp(matchUrlPattern).test(fullUrl);
        if (!isMatched) {
          continue; // dw route url doesnt match - skip close
        }
      }
      await this.close(dwRoute);
    }
  }

  public isDynamicRoute(route: ActivatedRoute | ActivatedRouteSnapshot) {
    let isDR = false;
    let r = route;
    while (r) {
      isDR = DynamicWindowsHelper.isDynamicOutlet(r.outlet);
      if (isDR) {
        break;
      }
      r = r.parent;
    }
    return isDR;
  }

  public getOutletRoute(route: ActivatedRoute | ActivatedRouteSnapshot = this.route) {
    // current route?
    if (DynamicWindowsHelper.isDynamicOutlet(route?.outlet)) {
      return route;
    }
    // parent route?
    let parentRoute = route?.parent;
    while (parentRoute) {
      if (DynamicWindowsHelper.isDynamicOutlet(parentRoute?.outlet)) {
        return parentRoute;
      }
      parentRoute = parentRoute.parent;
    }
    // find from root with highest index = last opened
    const lastWindow = maxBy(this.windows, 'index');
    return this.route.root.children.find((r) => r.outlet === lastWindow?.id);
  }

  public getWindowByRoute(route?: ActivatedRoute | ActivatedRouteSnapshot) {
    const outletRoute = this.getOutletRoute(route);
    const window = this.windows.find((w) => w.id === outletRoute?.outlet);
    return window;
  }

  public async getAvailableOutlet(): Promise<Route> {
    const outletRoutes = await this.outletRoutes$.pipe(first()).toPromise();
    const available = outletRoutes.find((or) => !this.windows.some((w) => w.id === or.outlet));

    if (!available) {
      // out of available outlets = create more outlets
      const numOfMoreOutlets = this.numOfOutlets$.value + defaultNumOfOutlets;
      this.numOfOutlets$.next(numOfMoreOutlets);
      await new Promise((r) => setTimeout(r, 10)); // let new outlets be generated
      return this.getAvailableOutlet();
    }

    return available;
  }

  /**
   * Generate dynamic windows routes to router config
   */
  private initRoutes() {
    this.outletRoutes$.pipe(takeUntil(this.ngDestroyed$)).subscribe((outletRoutes) => {
      // get config without previous Dynamic Windows routes
      const routerConfig = this.router.config.filter(
        (r) => !DynamicWindowsHelper.isDynamicOutlet(r.outlet)
      );
      // add new DW routes
      outletRoutes.forEach((r) => routerConfig.push(r));
      this.router.resetConfig(routerConfig);
    });
  }

  private initListenForChanges() {
    this.router.events
      .pipe(
        takeUntil(this.ngDestroyed$),
        filter((e) => e instanceof ActivationEnd),
        debounceTime(10)
      )
      .subscribe(() => {
        this.cleanup();
        this.ensure();
        this.executeInitialRoutes();
      });
  }

  /**
   * (Re-)Add window if navigating to route with missing outlet
   */
  private ensure() {
    const routesWithMissingWindow = this.getDynamicRoutes().filter(
      (r) => !this.windows.find((w) => w.id === r.outlet)
    );
    if (!routesWithMissingWindow.length) {
      return;
    }
    const newWindows = routesWithMissingWindow.map((r) => {
      // FYI: routes keep path values in private _urlSegment prop:
      const urlSegmentGroup: UrlSegmentGroup = (r as any)._urlSegment;
      if (!urlSegmentGroup && !urlSegmentGroup.segments) {
        return;
      }
      const paths = urlSegmentGroup.segments.map((s) => s.path);
      if (!paths.length) {
        return;
      }
      // first path ought to be "w" - remove that
      paths.shift();

      const newWindow: IDynamicWindow = {
        index: this._windowsCounter++,
        id: r.outlet,
        path: paths.join('/'),
      };
      return newWindow;
    });
    this.windows = [...this.windows, ...newWindows];
  }

  /**
   * Remove unused outlets
   */
  private cleanup() {
    const currOutlets = this.getDynamicRoutes().map((r) => r.outlet);
    const currWindows = this.windows.filter((w) => currOutlets.find((o) => o === w.id));
    this.windows = currWindows;
  }

  private async addNew(path?: string, onCloseFn?: DynamicWindowOnCloseFn) {
    const r = await this.getAvailableOutlet();
    const newWindow: IDynamicWindow = {
      id: r.outlet,
      path,
      onCloseFn,
      index: this._windowsCounter++,
    };
    this.windows = [...this.windows, newWindow];
    return newWindow;
  }

  private navigate(w: IDynamicWindow, extras?: NavigationExtras) {
    const goto = [
      {
        outlets: {
          [w.id]: ['w', ...w.path.split('/')],
        },
      },
    ];
    this.router.navigate(goto, { queryParamsHandling: 'preserve', ...(extras || {}) });
  }

  private getDynamicRoutes() {
    return this.route.root.snapshot.children.filter((r) =>
      DynamicWindowsHelper.isDynamicOutlet(r.outlet)
    );
  }

  private getFullUrlPathByRoute(route: ActivatedRouteSnapshot) {
    return ((route as any)._urlSegment?.segments || []).map((x) => x.path).join('/');
  }

  /**
   * Open inital dynamic window routes
   * collected by DynamicWindowInitService
   */
  public executeInitialRoutes() {
    const initialRoutes = this.dwInit.initialRoutes;

    const openNext = () => {
      if ((initialRoutes || []).length) {
        this.open(initialRoutes.shift(), { replaceUrl: true });
        setTimeout(openNext, 500);
      }
    };

    openNext();
  }
}
