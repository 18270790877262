import { IShoppingListItem } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[ShoppingListItem] UpsertMany';
  constructor(public shoppingListItems: IShoppingListItem[], public loadRelated?: boolean) {}
}

export class GetByListIds {
  static readonly type = '[ShoppingListItem] GetByListIds';
  constructor(public shoppingListIds: string[]) {}
}

export class Save {
  static readonly type = '[ShoppingListItem] Save';
  constructor(public shoppingListItems: IShoppingListItem[]) {}
}

export class Remove {
  static readonly type = '[ShoppingListItem] Remove';
  constructor(public shoppingListItems: IShoppingListItem[]) {}
}

export class LoadRelated {
  static readonly type = '[ShoppingListItem] LoadRelated';
  constructor(public shoppingListItems: IShoppingListItem[]) {}
}
