import { createSelector, Selector } from '@ngxs/store';
import { IStore, IStoreUser, IUser } from '~_shared/models';
import { isUserAnonymous } from '~_shared/utils/misc.utils';
import { StoreUserSelectors } from '../store-user/store-user.selectors';
import { StoreSelectors } from '../store/store.selectors';
import { UserSelectors } from '../user/user.selectors';
import { IDictionary } from '../_utils';
import { IMeSelected } from './me.actions';
import { IMeState, MeState } from './me.state';

export class MeSelectors {
  @Selector([MeState])
  static state(s: IMeState) {
    return s;
  }

  @Selector([MeState])
  static status(s: IMeState) {
    return s.status;
  }

  @Selector([MeState])
  static token(s: IMeState) {
    return s.token;
  }

  @Selector([MeState])
  static auth(s: IMeState) {
    return s.auth;
  }

  @Selector([MeSelectors.auth])
  static userId(auth: firebase.User) {
    return auth.uid;
  }

  @Selector([MeState])
  static pushToken(s: IMeState) {
    return s.pushToken;
  }

  @Selector([MeState])
  static selected(s: IMeState) {
    return s.selected;
  }
  @Selector([MeSelectors.selected])
  static selectedLocationId(selected: IMeSelected) {
    return selected?.locationId;
  }
  @Selector([MeSelectors.selected])
  static selectedUserFilterId(selected: IMeSelected) {
    return selected?.userFilterId;
  }
  @Selector([MeSelectors.selected])
  static selectedCategoryId(selected: IMeSelected) {
    return selected?.categoryId;
  }

  @Selector([MeState])
  static isKnown(s: IMeState) {
    return !!(s?.auth?.uid && !isUserAnonymous(s?.auth));
  }

  @Selector([MeState])
  static updates(s: IMeState) {
    return s.updates;
  }

  @Selector([MeSelectors.userId, UserSelectors.entities])
  static user(userId: string, userEntities: IDictionary<IUser>) {
    return userEntities[userId];
  }

  @Selector([MeSelectors.userId, StoreUserSelectors.all])
  static storeIds(userId: string, storeUsers: IStoreUser[]) {
    return storeUsers.filter((su) => su.userId === userId && su.storeId).map((su) => su.storeId);
  }

  @Selector([MeSelectors.storeIds, StoreSelectors.entities])
  static stores(storeIds: string[], storeEntities: IDictionary<IStore>) {
    return storeIds.map((storeId) => storeEntities[storeId]).filter((s) => !!s);
  }

  static errorByIdFn(id: string) {
    return createSelector([this.state], (s: IMeState) => {
      return s.status.errors[id];
    });
  }
}
