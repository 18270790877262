import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngxs/store';
import flatten from 'lodash/flatten';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { DestroyWatcher } from '~/shared/helpers';
import {
  ICartItem,
  IOrder,
  OrderHelper,
  OrderStatus,
  UserEventHelper,
  UserEventTimeType,
} from '~_shared/models';
import { sortOrders } from '~_shared/utils/sort.utils';
import { NGXS } from '../ngxs';
import { CartService } from './cart.service';
import { ItemService } from './item.service';
import { MyUserService } from './my-user.service';
import { StoreService } from './store.service';

@Injectable()
export class DibsService extends DestroyWatcher {
  constructor(
    public ngxs: Store,
    public cartSvc: CartService,
    public storeSvc: StoreService,
    public itemSvc: ItemService,
    public myUser: MyUserService
  ) {
    super();
  }

  readonly OrderStatus = OrderStatus;

  readonly userCartAsOrders$ = this.ngxs.select(NGXS.CartItem.select.orderables);

  readonly userOrders$ = this.myUser.userId$.pipe(
    switchMap((userId) => this.ngxs.select(NGXS.Order.select.allByUserFn(userId)))
  );

  readonly isLoading$ = combineLatest([
    this.ngxs.select(NGXS.CartItem.select.hasLoaded),
    this.ngxs.select(NGXS.Order.select.hasLoaded),
    this.ngxs.select(NGXS.Store.select.hasLoaded),
    this.ngxs.select(NGXS.Item.select.hasLoaded),
    this.ngxs.select(NGXS.User.select.hasLoaded),
    this.ngxs.select(NGXS.CartItem.select.all),
    this.ngxs.select(NGXS.Order.select.all),
  ]).pipe(
    map(
      ([cartLoaded, ordersLoaded, storesLoaded, itemsLoaded, usersLoaded, cartItems, orders]: [
        boolean,
        boolean,
        boolean,
        boolean,
        boolean,
        ICartItem[],
        IOrder[]
      ]) => {
        if (!cartItems.length && cartLoaded) {
          return false;
        }
        if (!orders.length && ordersLoaded) {
          return false;
        }
        return !(cartLoaded && ordersLoaded && storesLoaded && itemsLoaded && usersLoaded);
      }
    ),
    distinctUntilChanged()
  );

  getStatusCount(statusCounts: IStatusCounts, statuses: OrderStatus[]) {
    return (statuses || []).reduce(
      (r, s) => {
        r.totalCount += statusCounts?.[s]?.totalCount || 0;
        r.newCount += statusCounts?.[s]?.newCount || 0;
        return r;
      },
      {
        totalCount: 0,
        newCount: 0,
      } as IStatusCount
    );
  }

  /**
   * Look for param in route and its parents
   */
  getRouteParam$(route: ActivatedRoute, paramName: string): Observable<string | undefined> {
    const allParams$: Array<Observable<Params>> = [route.params];
    let r = route;
    while (r.parent) {
      r = r.parent;
      allParams$.push(r.params);
    }
    return combineLatest(allParams$).pipe(
      map((allParams) => allParams.find((p) => !!p[paramName])?.[paramName])
    );
  }

  /**
   * Detect if current view is store's admin or user's
   * @param route
   */
  getModeByRoute$(route: ActivatedRoute): Observable<IOrderMode> {
    return combineLatest([
      this.getRouteParam$(route, 'storeId'),
      this.getRouteParam$(route, 'mode'),
    ]).pipe(
      map(([storeId, modeName]) => {
        const isStore = !!storeId || modeName === 'store';
        return {
          isUser: !isStore,
          isStore,
          storeId,
        };
      })
    );
  }

  getOrders$(mode$: Observable<IOrderMode>) {
    return mode$.pipe(
      switchMap((mode) => {
        if (mode.storeId) {
          // store orders
          return this.getStoreOrders$(mode.storeId);
        }
        // user orders
        return combineLatest([this.userCartAsOrders$, this.userOrders$]).pipe(map(flatten));
      }),
      map(sortOrders)
    );
  }

  getStoreOrders$(storeId: string) {
    return this.ngxs.select(NGXS.Order.select.allByStoreFn(storeId));
  }

  getOrderInfo$(orderId$: Observable<string>, route: ActivatedRoute, required = true) {
    const order$ = orderId$.pipe(
      switchMap((orderId) => this.ngxs.select(NGXS.Order.select.entityByIdFn(orderId))),
      filter((x) => !!x || !required)
    );
    const store$ = order$.pipe(
      switchMap((order) => this.ngxs.select(NGXS.Store.select.entityByIdFn(order?.storeId)))
    );
    const user$ = order$.pipe(
      switchMap((order) => this.ngxs.select(NGXS.User.select.entityByIdFn(order?.userId)))
    );
    const mode$ = combineLatest([this.getModeByRoute$(route), order$]).pipe(
      map(([mode, order]) => {
        if (mode.isStore && !mode.storeId) {
          mode.storeId = order.storeId;
        }
        return mode;
      })
    );
    return combineLatest([order$, store$, user$, mode$]).pipe(
      filter((xx) => !xx.find((x) => !x)),
      map(([order, store, user, mode]) => ({ order, store, user, mode }))
    );
  }

  getStatusCounts$(
    orders$: Observable<IOrder[]>,
    mode$: Observable<IOrderMode>
  ): Observable<IStatusCounts> {
    // UserEventType to check if read or not
    const eventType$ = mode$.pipe(
      map((mode) =>
        mode.isStore ? UserEventTimeType.ShowStoreOrderStatus : UserEventTimeType.ShowOrderStatus
      )
    );
    // UserEvents to track for updates (not using all becase we dont want to trigger re-calc on every user event)
    const userEvents$ = this.ngxs.select(
      NGXS.UserEvent.select.filterAll({
        types: [UserEventTimeType.ShowStoreOrderStatus, UserEventTimeType.ShowOrderStatus],
      })
    );

    return combineLatest([orders$, userEvents$, eventType$]).pipe(
      map(([orders, userEvents, eventType]) => {
        const r: IStatusCounts = {};
        for (const statusKey in OrderStatus) {
          const status = OrderStatus[statusKey];
          const totals = orders.filter((o) => o.status === status);
          const news = totals.filter((o) => {
            if (!o.createdAt) {
              return true; // not sent orders = always new
            }
            // check if order status has been read (by checking UserEvents)
            const status = OrderHelper.lastStatus(o);
            const isRead =
              UserEventHelper.filter(userEvents, {
                types: [eventType],
                entityIds: [o.id],
                timestampAfter: status?.changedAt,
              }).length > 0;
            return !isRead; // not read = new
          });
          r[status] = {
            totalCount: totals.length,
            newCount: news.length,
          };
        }
        return r;
      })
    );
  }

  getNewCount$(statusCounts$: Observable<IStatusCounts>) {
    return statusCounts$.pipe(
      map((statusCounts) =>
        Object.keys(statusCounts).reduce((newCounts, s) => {
          newCounts += statusCounts[s].newCount || 0;
          return newCounts;
        }, 0)
      )
    );
  }
}

export interface IOrderMode {
  isUser: boolean;
  isStore: boolean;
  storeId?: string;
}

interface IStatusCount {
  totalCount: number;
  newCount: number;
}

interface IStatusCounts {
  [orderStatus: string]: IStatusCount;
}
