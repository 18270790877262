import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { first, withLatestFrom } from 'rxjs/operators';
import { SubmitState } from '~_shared/models';
import { PushService } from '../push.service';

@Component({
  selector: 'kd-push-prompt',
  templateUrl: './push-prompt.component.html',
  styleUrls: ['./push-prompt.component.scss'],
})
export class PushPromptComponent {
  constructor(private push: PushService, private ngxs: Store) {
    this.push
      .getToken$()
      .pipe(withLatestFrom(this.push.isPermissionGranted$), first())
      .subscribe(([token, granted]) => {
        this.token = granted ? token : undefined;
        this.initialTokenChecked = true;
        if (this.token) {
          this.state = 'submitted'; // makes `activated` prop = true
        }
      });
  }

  token: string;
  initialTokenChecked = false;
  state: SubmitState = 'idle';

  get failed() {
    return this.state === 'submitted' && !this.token;
  }

  get activated() {
    return this.state === 'submitted' && !!this.token;
  }

  get showLoading() {
    return this.state === 'submitting';
  }
  get showSuccess() {
    return !!this.token;
  }

  async onActivate() {
    if (this.state === 'submitting') {
      return;
    }
    this.state = 'submitting';
    this.token = await this.getActivatedToken();
    const delay = this.token ? 3000 : 0; // success animation
    setTimeout(() => (this.state = 'submitted'), delay);
  }

  /**
   * Get token when push is enabled/granted.
   * Try check <maxRetries> times every second.
   */
  private async getActivatedToken(maxRetries = 5, retryCount = 0): Promise<string | undefined> {
    const token = await this.push.getToken$(true).pipe(first()).toPromise();
    const granted = await this.push.isPermissionGranted$.pipe(first()).toPromise();
    if (token && granted) {
      return token;
    }
    if (!granted && retryCount < maxRetries) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return this.getActivatedToken(maxRetries, retryCount + 1);
    }
  }
}
