<kd-drawer-user-panel></kd-drawer-user-panel>

<div class="inner-wrapper flex-fill bg-white scroll">
  <div class="my-places">
    <h2 class="pad">Mina platser</h2>
    <kd-menu-list (itemClick)="closeDynamicWindows()">
      <kd-menu-list-item
        *ngFor="let it of place.myLocations$ | async"
        [label]="it.city"
        [routerLink]="['/', it.id]"
      ></kd-menu-list-item>
      <kd-menu-list-item
        label="Lägg till ny plats"
        icon="far fa-plus"
        cssClass="opacity-75"
        [routerLink]="['/', 'setup', 'find']"
      ></kd-menu-list-item>
    </kd-menu-list>
  </div>

  <ng-container *ngIf="myUser.stores$ | async as stores">
    <ng-container *ngIf="stores.length">
      <hr />
      <div class="my-stores pt-1 bg-blackish inverted">
        <h2 class="pad">{{ stores.length | singural: 'Min butik':'Mina butiker' }}</h2>
        <kd-menu-list (itemClick)="closeDynamicWindows()">
          <kd-menu-list-item
            *ngFor="let it of stores"
            [label]="it.title"
            [routerLink]="['/', 'admin', 'store', it.id]"
          ></kd-menu-list-item>
        </kd-menu-list>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="myUser.isAdmin">
    <div class="super-admin bg-bronze">
      <kd-menu-list (itemClick)="dynamicWindows.open('admin')">
        <kd-menu-list-item label="Fiffit Admin"></kd-menu-list-item>
      </kd-menu-list>
    </div>
  </ng-container>

  <div class="flex-fill justify-content-end">
    <hr class="mt-0" />
    <kd-menu-list class="ts-80" (itemClick)="gotoPage($event.id)">
      <kd-menu-list-item id="about" label="Om Fiffit" icon=""></kd-menu-list-item>
      <!-- <kd-menu-list-item id="faq" label="Frågor &amp; svar" icon=""></kd-menu-list-item> -->
      <kd-menu-list-item
        id="rate"
        label="Tyck till om appen"
        icon=""
        [showActionDot]="notRated$ | async"
      ></kd-menu-list-item>
      <kd-menu-list-item id="connect-store" label="Anslut din butik" icon=""></kd-menu-list-item>
    </kd-menu-list>
    <hr />
    <kd-menu-list class="ts-80" (itemClick)="openUrl($event.id)">
      <kd-menu-list-item
        id="https://www.facebook.com/fiffitapp"
        label="Följ oss på Facebook"
        icon="fab fa-facebook"
      ></kd-menu-list-item>
      <kd-menu-list-item
        id="https://www.instagram.com/fiffitapp/"
        label="Följ oss på Instagram"
        icon="fab fa-instagram"
      ></kd-menu-list-item>
    </kd-menu-list>
  </div>

  <!-- Keep for future use
  <hr>
  <footer class="flex-fill justify-content-end px-2 py-1">
    <div class="row align-items-center">
      <div class="col-6"><img src="/assets/img/logo-fiffit-white.svg" alt="Fiffit"></div>
      <div class="col pl-0">
        <div class="ts-70">
          <h1 class="ts-100 p-0 m-0 font-base">Fiffit AB</h1>
          <p class="p-0 m-0">

          </p>
        </div>
      </div>
    </div>
  </footer>
  -->
</div>
