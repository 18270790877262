import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, interval } from 'rxjs';
import { filter, first, takeUntil, withLatestFrom } from 'rxjs/operators';
import { NGXS } from './core/ngxs';
import { CartService, NavigatorService } from './core/services';
import { DestroyWatcher } from './shared/helpers';

/**
 * FYI: When this is reached, we have loaded (by InitialLoadedGuard)
 * bulks for user (or anonymous) and system.
 */
@Component({
  selector: 'kd-app-init',
  template: `<router-outlet></router-outlet>`,
  styles: [``],
})
export class AppInitComponent extends DestroyWatcher implements OnInit {
  @HostBinding('attr.class') hostClass = 'flex-fill';

  constructor(
    private navigator: NavigatorService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxs: Store,
    private cart: CartService
  ) {
    super();
    this.cart.init();
  }

  ngOnInit() {
    this.initAutoRefreshData();
    this.decideStartingPoint();

    this.router.events
      .pipe(
        takeUntil(this.ngDestroyed$),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => this.decideStartingPoint());
  }

  decideStartingPoint() {
    if (this.route.firstChild) {
      // only redirect when no route children present
      return;
    }
    this.navigator.gotoStartingPoint(true);
  }

  private initAutoRefreshData() {
    interval(30 * 60 * 1000)
      .pipe(
        takeUntil(this.ngDestroyed$),
        withLatestFrom(
          combineLatest([
            this.ngxs.select(NGXS.Bulk.select.shouldSystemLoad),
            this.ngxs.select(NGXS.Bulk.select.shouldUserLoad),
            this.ngxs.select(NGXS.Bulk.select.shouldLocationLoad),
          ])
        )
      )
      .subscribe(([_, [shouldSystemLoad, shouldUserLoad, shouldLocationLoad]]) => {
        if (shouldSystemLoad) {
          this.ngxs.dispatch(new NGXS.Bulk.actions.GetSystem());
        }
        if (shouldUserLoad) {
          const token$ = this.ngxs.select(NGXS.Me.select.token);
          token$.pipe(first()).subscribe((token) => {
            this.ngxs.dispatch(new NGXS.Bulk.actions.GetUser(token, true));
          });
        }
        if (shouldLocationLoad) {
          const locationId$ = this.ngxs.select(NGXS.Location.select.selectedId);
          locationId$.pipe(first()).subscribe((locationId) => {
            if (locationId) {
              this.ngxs.dispatch(new NGXS.Bulk.actions.GetLocation(locationId));
            }
          });
        }
      });
  }
}
