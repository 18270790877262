

export interface IStoreUser {
  id?: string;
  userId?: string;
  storeId?: string;
}


export enum StoreUserRoleType {
  Default = 'default',
  Admin = 'admin'
}
