import * as PollActions from './poll.actions';
import { PollSelectors } from './poll.selectors';
import { PollApiService } from './poll.service';
import { PollState } from './poll.state';

export const Poll = {
  state: PollState,
  actions: PollActions,
  select: PollSelectors,
  api: PollApiService,
};
