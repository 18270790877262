import dayjs from '../../libs/dayjs';
import { compressDate, decompressDate, discountPerc, hash } from '../../utils/misc.utils';
import { IImage, ImageHelper, ImageSizeType, TransformImageType } from '../image.model';
import { IDictionary, IMinMax } from '../interfaces';
import { ITags } from '../tag.model';
import { FilterHelper, IFilter } from './filter.model';
import { IVariant, VariantType } from './variant.model';

export interface IItem {
  id?: string;
  itemBodyId?: string; // when used, get common props from IItemBody object
  gtin?: string;
  type?: string;
  images?: IImage[];
  title?: string;
  quantityLevel?: number;
  quantity?: number;
  quantityEst?: number; // estimated
  shortInfo?: string;
  longInfo?: string;
  discountPercentage?: number;
  priceFull?: number;
  priceDiscounted?: number;
  priceUnit?: string;
  tags?: ITags;
  variants?: IVariant[];
  v?: IVariant[]; // variants when compressed
  storeId?: string;
  userId?: string;
  extId?: string;
  blockImport?: boolean;
  publishedAt?: number;
  deactivateAt?: number;
  expiredAt?: number;
  createdAt?: number;
  updatedAt?: number;

  // String with compressed content (dates uses compressDate()):
  // Structure: "itemBodyId;storeId;publishedAt;deactiveAt"
  s?: string;
}

export interface IItemEdit extends IItem {
  isPublic?: boolean;
}

/**
 * Shallow IItem to contain common props to avoid duplication of data
 */
export interface IItemBody {
  id?: string; // hash(providerId + item.extId)?
  extId?: string;
  title?: string;
  shortInfo?: string;
  longInfo?: string;
  priceFull?: number;
  priceDiscounted?: number;
  tags?: ITags;
  images?: IImage[];
}
export const ItemBodyProps: Array<keyof IItemBody> = [
  'extId',
  'title',
  'shortInfo',
  'longInfo',
  'priceFull',
  'priceDiscounted',
  'tags',
  'images',
];

export class ItemHelper {
  static isImportable(it: IItemEdit) {
    let valid = true;
    if (!it.s) {
      if (!it.itemBodyId) {
        valid = valid && (it.title || '').trim().length > 0;
        valid = valid && !!it.priceFull;
        valid = valid && !!it.priceDiscounted;
      }
      valid = valid && !!it.storeId;
    }
    return valid;
  }

  static isAutoImported(it?: IItem) {
    return !!it?.extId;
  }

  static status(item?: IItem): ItemStatus {
    if (!item?.publishedAt) {
      return ItemStatus.NotPublished;
    }
    if (this.isDeactivated(item)) {
      return ItemStatus.Deactivated;
    }
    // deprecated:
    // if (this.expireInDays(item)! < 0) {
    //   return ItemStatus.Expired;
    // }
    return ItemStatus.Published;
  }

  static isPublic(item?: IItem) {
    return this.status(item) === ItemStatus.Published;
  }

  static isDeactivated(item: IItem) {
    if (!item?.deactivateAt) {
      return false;
    }
    const diff = new Date(item.deactivateAt).getTime() - new Date().getTime();
    return diff < 0;
  }

  // deprecate?
  static expireInDays(item: IItem) {
    if (!item?.expiredAt) {
      return null;
    }
    // FYI: DO NOT use dayjs(myDate).tz() - it has memory leak issue!
    const today = dayjs.tz(new Date()).startOf('day');
    const expire = dayjs.tz(item.expiredAt).startOf('day');
    return dayjs.tz(expire).diff(today, 'day');
  }

  static lastUpdatedInDays(item: IItem) {
    const updatedAt = item?.updatedAt || item?.createdAt;
    if (!updatedAt) {
      return null;
    }
    const today = dayjs.tz(new Date()).startOf('day');
    const lastUpdated = dayjs.tz(updatedAt).startOf('day');
    return Math.abs(dayjs.tz(lastUpdated).diff(today, 'day'));
  }

  static discount(item: IItem) {
    if (item?.discountPercentage) {
      return Math.round(item.discountPercentage);
    }
    if (item?.priceFull && item?.priceDiscounted) {
      return discountPerc(item.priceFull, item.priceDiscounted);
    }
    return 0;
  }

  static images(
    item: IItem,
    sizeName: ImageSizeType,
    transformCallback: TransformImageType = (x) => x
  ) {
    const imagePaths = (item.images || []).map((img) => img.path).filter((x) => !!x) as string[];
    return imagePaths.map((imgPath) => ImageHelper.imageUrl(imgPath, sizeName, transformCallback));
  }

  static allTags(items: IItem[]) {
    const allTags: ITags = {};
    for (const it of items) {
      if (!it.tags) {
        continue;
      }
      for (const tagKey in it.tags) {
        allTags[tagKey] = allTags[tagKey] || [];
        for (const tagValue of it.tags[tagKey] || []) {
          const tv = typeof tagValue === 'string' ? tagValue.trim().toLowerCase() : tagValue;
          if (!allTags[tagKey]!.includes(tv as never)) {
            // never whatever -.-
            allTags[tagKey]!.push(tv as never);
          }
        }
      }
    }
    const sortedTags: ITags = {};
    for (const k of Object.keys(allTags).sort()) {
      sortedTags[k] = allTags[k]?.sort();
    }
    return sortedTags;
  }

  /**
   * Populates tags with extra info
   * @param item
   */
  static tags(item: IItem) {
    const tags = { ...(item.tags || {}) };
    if (item.title) {
      tags.title = [item.title];
    }
    if (item.storeId) {
      tags.storeId = [item.storeId];
    }
    const discount = this.discount(item);
    if (discount) {
      tags.discount = [discount];
    }
    return tags;
  }

  static isMatchByFilter(item: IItem, filter: IFilter, filtersByIds: IDictionary<IFilter>) {
    const itemTags = this.tags(item);
    return FilterHelper.isMatch(itemTags, filter, filtersByIds);
  }

  static isMatchBySearchQuery(
    item: IItem,
    q?: string,
    props: (keyof IItem)[] = ['title', 'shortInfo', 'longInfo']
  ) {
    const isPropMatch = (prop: keyof IItem) =>
      !!(q && item) && (item[prop] + '').toLowerCase().indexOf(q.toLowerCase().trim()) >= 0;
    let r = !q?.trim();
    for (const prop of props) {
      r = r || isPropMatch(prop);
    }
    return r;
  }

  static isMatchByDiscountRange(item: IItem, discountRange?: IMinMax) {
    let r = true;
    if (discountRange) {
      const discountPerc = ItemHelper.discount(item);
      r = r && (!discountRange.min || discountPerc >= discountRange.min);
      r = r && (!discountRange.max || discountPerc <= discountRange.max);
    }
    return r;
  }

  static isMatchByPriceRange(item: IItem, priceRange?: IMinMax) {
    let r = true;
    r = r && (!priceRange?.min || !item.priceDiscounted || item.priceDiscounted >= priceRange.min);
    r = r && (!priceRange?.max || !item.priceDiscounted || item.priceDiscounted <= priceRange.max);
    return r;
  }

  static itemBodyId(item?: IItem, providerId?: string) {
    return hash(`${providerId}-${item?.extId}`);
  }

  static itemBody(item?: IItem, providerId?: string) {
    if (!item?.extId) {
      return undefined;
    }
    const itemBody: IItemBody = {};
    for (const key of ItemBodyProps) {
      (itemBody as any)[key] = item?.[key];
    }
    itemBody.id = this.itemBodyId(item, providerId);
    return itemBody;
  }

  /**
   * Collects duplications in IItemBody objects and clean up IItems to reduce data
   * @param items
   * @param itemBodiesByIds
   */
  static utilizeItemBodies(
    items?: IItem[],
    itemBodiesByIds?: IDictionary<IItemBody>,
    providerId?: string
  ) {
    itemBodiesByIds = itemBodiesByIds || {};
    for (const item of items || []) {
      const itemBody = this.itemBody(item, providerId);
      if (!itemBody?.id) {
        continue;
      }
      // clear IItem props to reduce data
      for (const key of ItemBodyProps) {
        delete item[key];
      }
      item.itemBodyId = itemBody.id;
      itemBodiesByIds[itemBody.id] = {
        ...(itemBodiesByIds[itemBody.id] || {}),
        ...itemBody,
      };
    }
    return itemBodiesByIds;
  }

  /**
   * When using itemBody, we compress its most important info into a single string to reduce data
   * @param item
   * @param cnum compressNum used with compressDate()
   */
  static compress(item?: IItem, cnum = 10000) {
    if (!item) {
      return;
    }
    if (item.itemBodyId) {
      const publishedAt = compressDate(item.publishedAt, cnum);
      const deactivateAt = compressDate(item.deactivateAt, cnum);
      const s = [item.itemBodyId, item.storeId, publishedAt, deactivateAt].join(';');
      item.s = s;
      delete item.itemBodyId;
      delete item.storeId;
      delete item.publishedAt;
      delete item.deactivateAt;
      delete item.createdAt;
      // clear props that ItemBody holds
      for (const key of ItemBodyProps) {
        delete item[key];
      }
      // variants
      if (item.variants?.length) {
        item.v = item.variants.map((v) => {
          if (v.quantity) {
            v.q = v.quantity;
            delete v.quantity;
          }
          return v;
        });
        delete item.variants;
      }
    }
    return item;
  }

  /**
   * Convert compressed values into original forms
   * @param item
   * @param cnum compressNum used with decompressDate()
   */
  static decompress(item?: IItem, cnum = 10000) {
    if (!item) {
      return;
    }
    if (item.s) {
      const [itemBodyId, storeId, publishedAt, deactivateAt] = item.s.split(';');
      item.itemBodyId = itemBodyId;
      item.storeId = storeId;
      item.publishedAt = decompressDate(publishedAt, cnum);
      item.deactivateAt = decompressDate(deactivateAt, cnum);
      delete item.s;
    }
    // variants
    if (item.v?.length) {
      item.variants = item.v.map((v) => {
        if (v.q) {
          v.quantity = v.q;
          delete v.q;
        }
        return v;
      });
      delete item.v;
    }
    return item;
  }

  /**
   * Decompress item and merge with itemBody
   * @param itemRaw
   * @param itemBodyByIds
   */
  static embody(itemRaw?: IItem, itemBodyByIds: IDictionary<IItemBody> = {}) {
    if (!itemRaw) return;
    let item = this.decompress(Object.assign({}, itemRaw));
    if (item?.itemBodyId) {
      const itemBody = Object.assign({}, itemBodyByIds[item.itemBodyId]);
      if (!itemBody.id && !item.title) {
        // no usable data... = undefined
        return;
      }
      delete itemBody.id;
      item = Object.assign({}, itemBody || {}, item); // merge itemBody before item's props
    }
    return item;
  }

  static itemShortInfoByVariants(variants: IVariant[] = []): string {
    const colors: string[] = [];
    const sizes: string[] = [];
    for (const v of variants) {
      const color = (v[VariantType.Color] ?? '') + '';
      const size = (v[VariantType.Size] ?? '') + '';
      if (color && !colors.includes(color)) {
        colors.push(v[VariantType.Color] + '');
      }
      if (size && !sizes.includes(size)) {
        sizes.push(v[VariantType.Size] + '');
      }
    }
    const colorText = colors.length > 1 ? `${colors.length} färger` : colors[0];
    const sizeText = sizes.length > 1 ? `${sizes.length} storlekar` : sizes[0];
    const shortInfo = [colorText, sizeText].filter((x) => !!x).join(', ');
    return shortInfo;
  }
}

export enum ItemStatus {
  Published = 'Published',
  NotPublished = 'NotPublished',
  Expired = 'Expired',
  Deactivated = 'Deactivated',
}

export enum ItemType {
  Ad = 'Ad',
}
