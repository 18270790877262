import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IUserEvent, IUserEventDictionary, UserEventHelper } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as UserEventActions from './user-event.actions';
import { UserEventApiService } from './user-event.service';

export interface IUserEventState extends IEntityState<IUserEvent>, IStatusState {
  queue: IUserEventDictionary;
  lastSavedAt: Date;
}

@State<IUserEventState>({
  name: 'userEvent',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
    queue: {},
    lastSavedAt: null,
  },
})
@Injectable()
export class UserEventState {
  constructor(private api: UserEventApiService) {}

  @Action(UserEventActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IUserEventState>,
    { userEventsOrDictionary }: UserEventActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, UserEventActions.UpsertMany);
    const userEvents: IUserEvent[] = Array.isArray(userEventsOrDictionary)
      ? userEventsOrDictionary
      : UserEventHelper.dictionaryToList(userEventsOrDictionary);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), userEvents));
    status.setLoading(false);
  }

  @Action(UserEventActions.GetMine)
  async getMine(ctx: StateContext<IUserEventState>, {}: UserEventActions.GetMine) {
    const status = new StatusUtils(ctx, UserEventActions.GetMine);
    let error: Error;
    try {
      const data = await this.api.getMine$().toPromise();
      await ctx.dispatch(new UserEventActions.UpsertMany(data)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(UserEventActions.Save)
  async save(ctx: StateContext<IUserEventState>, { queue }: UserEventActions.Save) {
    const status = new StatusUtils(ctx, UserEventActions.Save);
    let error: Error;
    try {
      // first, update queue info
      const cleanedQueue = { ...ctx.getState().queue };
      Object.keys(queue).forEach((id) => delete cleanedQueue[id]);
      ctx.patchState({
        lastSavedAt: new Date(),
        queue: cleanedQueue,
      });

      const savedSummary = await this.api.save$(queue).toPromise();
      await ctx.dispatch(new UserEventActions.UpsertMany(savedSummary)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(UserEventActions.Track)
  async track(ctx: StateContext<IUserEventState>, { trackedQueue }: UserEventActions.Track) {
    const queue = { ...ctx.getState().queue };
    for (const q of trackedQueue) {
      const id = UserEventHelper.id(q);
      queue[id] = UserEventHelper.isTimeType(q.type) ? new Date().getTime() : (queue[id] || 0) + 1;
    }
    ctx.patchState({ queue });
  }
}
