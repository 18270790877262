import { ICategory } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[Category] UpsertMany';
  constructor(public categories: ICategory[], public loadRelated?: boolean) {}
}

export class GetAll {
  static readonly type = '[Category] GetAll';
  constructor() {}
}

export class Save {
  static readonly type = '[Category] Save';
  constructor(public categories: ICategory[]) {}
}

export class Remove {
  static readonly type = '[Category] Remove';
  constructor(public categories: ICategory[]) {}
}

export class LoadRelated {
  static readonly type = '[Category] LoadRelated';
  constructor(public categories: ICategory[]) {}
}
