import * as FilterActions from './filter.actions';
import { FilterSelectors } from './filter.selectors';
import { FilterApiService } from './filter.service';
import { FilterState } from './filter.state';

export const Filter = {
  state: FilterState,
  actions: FilterActions,
  select: FilterSelectors,
  api: FilterApiService,
};
