import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicRouterOutletDirective } from './dynamic-router-outlet.directive';
import { DynamicWindowsService } from './dynamic-windows.service';
import { DynamicWindowsInitService } from './dynamic-windows-init.service';

@NgModule({
  declarations: [DynamicRouterOutletDirective],
  exports: [DynamicRouterOutletDirective],
  imports: [CommonModule],
  providers: [
    DynamicWindowsService,
    DynamicWindowsInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (dwInit: DynamicWindowsInitService) => async () => {
        return !!dwInit.initialRoutes.length;
      },
      deps: [DynamicWindowsInitService],
      multi: true
    }
  ],
})
export class DynamicWindowsModule {}
