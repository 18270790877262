import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'dynamic-router-outlet',
  exportAs: 'outlet',
})
export class DynamicRouterOutletDirective implements OnInit, OnDestroy {
  outlet: RouterOutlet;

  @Input() name: string;
  @Output() init = new EventEmitter<RouterOutlet>();

  constructor(
    private parentContexts: ChildrenOutletContexts,
    private location: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.outlet = new RouterOutlet(
      this.parentContexts,
      this.location,
      this.resolver,
      this.name,
      this.changeDetector
    );
    this.outlet.ngOnInit();
    this.init.emit(this.outlet);
  }

  ngOnDestroy() {
    if (this.outlet) {
      this.outlet.ngOnDestroy();
    }
  }
}
