import { createSelector, Selector } from '@ngxs/store';
import { IUserState, UserState } from './user.state';

export class UserSelectors {
  @Selector([UserState])
  static state(s: IUserState) {
    return s;
  }

  @Selector([UserState])
  static status(s: IUserState) {
    return s.status;
  }
  @Selector([UserState])
  static hasLoaded(s: IUserState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([UserState])
  static entities(s: IUserState) {
    return s.entities;
  }

  @Selector([UserState])
  static ids(s: IUserState) {
    return s.ids;
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IUserState) => {
      return s.entities[id];
    });
  }
}
