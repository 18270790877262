import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyCurrency',
})
export class FriendlyCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(amount: number, currencySuffix: string = ':-') {
    if ([undefined, null].indexOf(amount) >= 0) {
      return '';
    }

    let t = this.currencyPipe.transform(amount, 'SEK') || '';
    t = t.replace(/\s(SEK|kr)/g, ''); // remove currency code
    t = t.replace(/,00/g, ''); // remove decimals if zeroes, "xx.00"
    t += t ? currencySuffix : ''; // custom price symbol

    return t;
  }
}
