import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, switchMap } from 'rxjs/operators';
import { BackendService } from '~/core/services/backend.service';
import { IStoreUser } from '~_shared/models';

@Injectable()
export class StoreUserApiService {
  constructor(private fireAuth: AngularFireAuth, private backend: BackendService) {}

  getMine$() {
    return this.fireAuth.authState.pipe(
      first(),
      switchMap((auth) => this.getByUser$(auth.uid))
    );
  }

  getByUser$(userId: string) {
    return this.backend.get<IStoreUser[]>(`store-users/by/user/${userId}`);
  }
}
