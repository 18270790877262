import { StateContext } from '@ngxs/store';
import lodashCloneDeep from 'lodash/cloneDeep';
import lodashUniq from 'lodash/uniq';
import { ILoadStatus } from '~_shared/models';
import { findProp } from '~_shared/utils/misc.utils';

export interface IStatusState {
  status: ILoadStatus;
}

export class StatusUtils {
  private _ctx: StateContext<IStatusState>;
  private _actionId: string;

  get ctx() {
    return this._ctx;
  }
  get actionId() {
    return this._actionId;
  }

  constructor(ctx: StateContext<IStatusState>, actionId: string | any, setInitialLoading = true) {
    this._ctx = ctx;
    this._actionId = actionId && actionId.type ? actionId.type : actionId;

    if (setInitialLoading) {
      this.setLoading(true);
      this.setError();
    }
  }

  static defaultState() {
    return {
      status: {
        loading: false,
        loadings: {},
        loaded: {},
        errors: {},
      } as ILoadStatus,
    };
  }

  setLoading(loading: boolean) {
    const s = this.ctx.getState();
    const status = lodashCloneDeep(s.status);
    const wasLoading = status.loading || !!status.loadings[this.actionId];
    status.loading = loading;
    if (this.actionId) {
      status.loadings[this.actionId] = loading;
    }

    if (wasLoading && !loading) {
      status.lastLoaded = new Date();
      if (this.actionId) {
        delete status.loadings[this.actionId];
        status.loaded[this.actionId] = new Date();
      }
    }

    this.ctx.patchState({ status });
    return this;
  }

  setError(data?: any, actionId?: string | any) {
    actionId = actionId && actionId.type ? actionId.type : actionId || this.actionId;
    const s = this.ctx.getState();
    const status = lodashCloneDeep(s.status);
    status.errors = status.errors || {};
    if (data == null) {
      delete status.errors[actionId];
    } else {
      data = findProp(data, 'error.error') || data; // if API error, get details
      status.errors[actionId] = data;
    }
    this.ctx.patchState({ status });
    return this;
  }

  setLoadedIds(ids?: string[]) {
    const s = this.ctx.getState();
    const status = lodashCloneDeep(s.status);
    status.loaded = status.loaded || {};
    status.loaded.ids = lodashUniq([...(status.loaded.ids || []), ...(ids || [])]);
    this.ctx.patchState({ status });
    return this;
  }
}
