import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { ICartItem } from '~_shared/models';

@Injectable()
export class CartItemApiService {
  constructor(private backend: BackendService) {}

  getMine$() {
    return this.backend.get<ICartItem[]>(`cart`);
  }

  save$(cartItems: ICartItem[]) {
    return this.backend.patch<ICartItem[]>(`cart`, cartItems);
  }

  removeById$(cartItemId: string) {
    return this.backend.delete<ICartItem[]>(`cart/${cartItemId}`);
  }
  removeByIds$(cartItemIds: string[]) {
    return this.backend.post<ICartItem[]>(`cart/remove`, cartItemIds);
  }
}
