import { Component, Input } from '@angular/core';

export interface IMenuListItem<T = any> {
  id?: string | number;
  label?: string;
  subLabel?: string;
  icon?: string;
  data?: T;
  cssClass?: string;
  routerLink?: any[] | string;
  queryParams?: any;
  fragment?: string;
  routerLinkActiveOptions?: { exact: boolean };
  isActive?: boolean;
  isLoading?: boolean;
  showActionDot?: boolean;
}

@Component({
  selector: 'kd-menu-list-item',
  template: '',
})
export class MenuListItemComponent implements IMenuListItem {
  @Input() id: string | number;
  @Input() label: string;
  @Input() icon: string;
  @Input() cssClass: string;
  @Input() routerLink: any[] | string;
  @Input() queryParams: any;
  @Input() fragment: string;
  @Input() routerLinkActiveOptions: { exact: boolean };
  @Input() isActive: boolean;
  @Input() isLoading: boolean;
  @Input() showActionDot: boolean;

  constructor() {}
}
