import { IAddress } from '../address.model';
import { IImage } from '../image.model';
import { ILatLngShort } from '../lat-lng.model';

export interface IStore {
  id?: string;
  title?: string;
  simpleAddress?: string; // TODO: remove
  address?: IAddress;
  telephone?: string;
  email?: string;
  homepageUrl?: string;
  facebookUrl?: string;
  instagramUser?: string;
  contactName?: string;
  geopos?: ILatLngShort;
  imageLogo?: IImage;
  imageCover?: IImage;
  logoImagePath?: string;
  coverImagePath?: string;
  dibs?: IStoreDibs;
  extId?: string;
  providerId?: string;
  lockAutoUpdates?: boolean;
  disabled?: boolean;
  userCount?: number;
}

export interface IStoreDibs {
  active?: boolean;
  reservesForNumDays?: number;
  info?: string;
}

export class StoreHelper {
  static isImportable(s: IStore) {
    let valid = true;
    // valid = valid && !!s.id; // required?
    valid = valid && (s.title || '').trim().length > 0;
    // TODO: more?
    return valid;
  }
}
