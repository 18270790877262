import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PlaceService } from '~/core/services/place.service';

@Injectable()
export class HasLocationGuard implements CanActivate {
  constructor(private router: Router, private place: PlaceService) {}

  /**
   * Verify that location is loaded in state (or load it!)
   * NOTE: Only simple location entity - NOT bulk location.
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return of(route).pipe(
      map((r) => r.params.locationId + ''),
      switchMap((id) => {
        if (id.toLowerCase() === '$location') {
          return this.place.selectedLocationId$;
        }
        return of(id);
      }),
      switchMap((id) => this.place.getOrLoadLocation$(id)),
      map((loc) => {
        if (!loc || !loc.id) {
          return this.router.parseUrl('/');
        }
        this.place.setSelectedLocation$(loc.id);
        return true;
      })
    );
  }
}
