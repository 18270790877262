import * as ShoppingListActions from './shopping-list.actions';
import { ShoppingListSelectors } from './shopping-list.selectors';
import { ShoppingListApiService } from './shopping-list.service';
import { ShoppingListState } from './shopping-list.state';

export const ShoppingList = {
  state: ShoppingListState,
  actions: ShoppingListActions,
  select: ShoppingListSelectors,
  api: ShoppingListApiService,
};
