import { HasLocationGuard } from '../guards/has-location.guard';
import { InitialLoadedGuard } from './initial-loaded.guard';

export const coreGuards = [
  HasLocationGuard,
  InitialLoadedGuard,
];

export * from '../guards/has-location.guard';
export * from '../guards/initial-loaded.guard';
