import { IUserEvent, IUserEventDictionary, UserEventType } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[UserEvent] UpsertMany';
  constructor(public userEventsOrDictionary: IUserEvent[] | IUserEventDictionary) {}
}

export class GetMine {
  static readonly type = '[UserEvent] GetMine';
  constructor() {}
}

export class Track {
  static readonly type = '[UserEvent] Track';
  constructor(public trackedQueue: Array<{ type: UserEventType; entityId?: string }>) {}
}

export class Save {
  static readonly type = '[UserEvent] Save';
  constructor(public queue: IUserEventDictionary) {}
}
