import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'environments/environment';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { ngxsServices, ngxsStates } from '.';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(ngxsStates, { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: [
        // 'me.token',
        // 'me.pushToken',
        // 'me.updates',
        'me.selected.locationId',
        // 'place',
        'userEvent.lastSavedAt',
        'userEvent.queue',
      ],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment?.production,
    }),
    NgxsResetPluginModule.forRoot(),
  ],
  providers: [...ngxsServices],
})
export class AppNgxsModule {}
