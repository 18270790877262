import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '~/shared/modules/material';
import { MenuListItemComponent } from './menu-list-item.component';
import { MenuListComponent } from './menu-list.component';


@NgModule({
  declarations: [
    MenuListComponent,
    MenuListItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
  ],
  exports: [
    MenuListComponent,
    MenuListItemComponent,
  ],
})
export class MenuListModule { }
