import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DtRemoveYearPipe } from './dt-remove-year.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DtRemoveYearPipe],
  exports: [DtRemoveYearPipe],
})
export class DateTweaksModule {}
