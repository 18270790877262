import { Selector } from '@ngxs/store';
import { IPollQuestion } from '~_shared/models';
import { IDictionary } from '../_utils';
import { IPollState, PollState } from './poll.state';

export class PollSelectors {
  @Selector([PollState])
  static state(s: IPollState) {
    return s;
  }

  @Selector([PollState])
  static status(s: IPollState) {
    return s.status;
  }
  @Selector([PollState])
  static hasLoaded(s: IPollState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([PollState])
  static entities(s: IPollState) {
    return s.entities;
  }

  @Selector([PollState])
  static ids(s: IPollState) {
    return s.ids;
  }

  @Selector([PollState])
  static all(s: IPollState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([PollSelectors.entities])
  static entityByIdFn(questionEntities: IDictionary<IPollQuestion>) {
    return (qid: string) => questionEntities[qid];
  }

  @Selector([PollState])
  static answerByQuestionIds(s: IPollState) {
    return s.answerByQuestionIds;
  }
}
