import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';


export abstract class DestroyWatcher implements OnDestroy {
  protected ngDestroyed$ = new Subject<void>();

  constructor() { }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

}
