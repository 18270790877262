import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IUserEventDictionary } from '~_shared/models';

@Injectable()
export class UserEventApiService {
  constructor(private backend: BackendService) {}

  getMine$() {
    return this.backend.get<IUserEventDictionary>(`user-events`);
  }

  save$(queue: IUserEventDictionary) {
    return this.backend.post<IUserEventDictionary>(`user-events`, queue);
  }
}
