import { IPlaceFilter, IUser, IUserJoin, IUserUpdate } from '~_shared/models';

export class SetToken {
  static readonly type = '[Me] SetToken';
  constructor(public token: string) {}
}

export class SetAuth {
  static readonly type = '[Me] SetAuth';
  constructor(public authObj: firebase.User) {}
}

export class LoginByEmail {
  static readonly type = '[Me] LoginByEmail';
  constructor(public login: IUserJoin) {}
}
export class LoginByFacebook {
  static readonly type = '[Me] LoginByFacebook';
  constructor() {}
}
export class LoginByGoogle {
  static readonly type = '[Me] LoginByGoogle';
  constructor() {}
}
export class LoginByApple {
  static readonly type = '[Me] LoginByApple';
  constructor() {}
}

export class CreateAnonymous {
  static readonly type = '[Me] CreateAnonymous';
  constructor() {}
}

export class MigrateAnonymous {
  static readonly type = '[Me] MigrateAnonymous';
  constructor(public oldToken: string, public newToken: string) {}
}

export class CreateUser {
  static readonly type = '[Me] CreateUser';
  constructor(public newUser: IUser) {}
}
export class PushTokenUpdate {
  static readonly type = '[Me] PushTokenUpdate';
  constructor(public newToken: string) {}
}
export class SetUserUpdates {
  static readonly type = '[Me] SetUserUpdates';
  constructor(public updates: IUserUpdate) {}
}

export class SetSelected {
  static readonly type = '[Me] SetSelected';
  constructor(public selected: IMeSelected, public clear?: boolean) {}
}
export class DeselectLocation {
  static readonly type = '[Me] DeselectLocation';
  constructor(public locationId?: string) {}
}

export interface IMeSelected extends IPlaceFilter {}
