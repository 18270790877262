import { createSelector, Selector } from '@ngxs/store';
import { CategoryHelper, ICategory } from '~_shared/models';
import { MeSelectors } from '../me/me.selectors';
import { IDictionary } from '../_utils';
import { CategoryState, ICategoryState } from './category.state';

export class CategorySelectors {
  @Selector([CategoryState])
  static state(s: ICategoryState) {
    return s;
  }

  @Selector([CategoryState])
  static status(s: ICategoryState) {
    return s.status;
  }
  @Selector([CategoryState])
  static hasLoaded(s: ICategoryState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([CategoryState])
  static entities(s: ICategoryState) {
    return s.entities;
  }

  @Selector([CategoryState])
  static ids(s: ICategoryState) {
    return s.ids;
  }

  @Selector([CategoryState])
  static all(s: ICategoryState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([CategorySelectors.entities])
  static categoryAll(entities: IDictionary<ICategory>) {
    return CategoryHelper.getChildren(entities)?.[0];
  }

  // alias, really
  @Selector([MeSelectors.selectedCategoryId])
  static selectedId(selectedCategoryId: string) {
    return selectedCategoryId;
  }

  @Selector([CategorySelectors.selectedId, CategorySelectors.categoryAll])
  static selectedOrDefaultId(selectedCategoryId: string, categoryAll: ICategory) {
    return selectedCategoryId ?? categoryAll?.id; // home/"All" as default
  }

  @Selector([CategoryState])
  static tree(s: ICategoryState) {
    return CategoryHelper.getTreeByParent(s.entities);
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: ICategoryState) => {
      return s.entities[id];
    });
  }
}
