import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import uniq from 'lodash/uniq';
import { IUser } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as UserActions from './user.actions';
import { UserApiService } from './user.service';

export interface IUserState extends IEntityState<IUser>, IStatusState {}

@State<IUserState>({
  name: 'user',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class UserState {
  constructor(private api: UserApiService) {}

  @Action(UserActions.UpsertMany)
  async upsertMany(ctx: StateContext<IUserState>, { users }: UserActions.UpsertMany) {
    const status = new StatusUtils(ctx, UserActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), users));
    status.setLoading(false);
  }

  @Action(UserActions.GetById)
  async getById(ctx: StateContext<IUserState>, { id }: UserActions.GetById) {
    const status = new StatusUtils(ctx, UserActions.GetByIds);
    const user = await this.api.getById$(id).toPromise();
    await ctx.dispatch(new UserActions.UpsertMany([user])).toPromise();
    status.setLoadedIds([user?.id]).setLoading(false);
  }

  @Action(UserActions.GetByIds)
  async getByIds(ctx: StateContext<IUserState>, { ids }: UserActions.GetByIds) {
    const s = () => ctx.getState();
    const nonExistingIds = uniq(ids).filter((id) => !s().entities[id]);
    if (nonExistingIds.length === 0) {
      return;
    }
    const status = new StatusUtils(ctx, UserActions.GetByIds);
    const users = await this.api.getByIds$(nonExistingIds).toPromise();
    await ctx.dispatch(new UserActions.UpsertMany(users)).toPromise();
    status.setLoadedIds(nonExistingIds).setLoading(false);
  }

  @Action(UserActions.Update)
  async update(ctx: StateContext<IUserState>, { user }: UserActions.Update) {
    const status = new StatusUtils(ctx, UserActions.Update);
    const updatedStore = await this.api.update$(user).toPromise();
    await ctx.dispatch(new UserActions.UpsertMany([updatedStore])).toPromise();
    status.setLoading(false);
  }
}
