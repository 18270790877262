import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IFilter } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as FilterActions from './filter.actions';
import { FilterApiService } from './filter.service';

export interface IFilterState extends IEntityState<IFilter>, IStatusState {}

@State<IFilterState>({
  name: 'filter',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class FilterState {
  constructor(private api: FilterApiService) {}

  @Action(FilterActions.UpsertMany)
  async upsertMany(ctx: StateContext<IFilterState>, { filters }: FilterActions.UpsertMany) {
    const status = new StatusUtils(ctx, FilterActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), filters));
    status.setLoading(false);
  }

  @Action(FilterActions.GetMine)
  async getAll(ctx: StateContext<IFilterState>, {}: FilterActions.GetMine) {
    const status = new StatusUtils(ctx, FilterActions.GetMine);
    let error: Error;
    try {
      const filters = await this.api.getMine$().toPromise();
      await ctx.dispatch(new FilterActions.UpsertMany(filters)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(FilterActions.GetByIds)
  async getByIds(ctx: StateContext<IFilterState>, { ids }: FilterActions.GetByIds) {
    const s = () => ctx.getState();
    const nonExistingIds = ids.filter((id) => id && !s().entities[id]);
    if (nonExistingIds.length === 0) {
      return;
    }
    const status = new StatusUtils(ctx, FilterActions.GetByIds);
    const filters = await this.api.getByIds$(nonExistingIds).toPromise();
    await ctx.dispatch(new FilterActions.UpsertMany(filters)).toPromise();
    status.setLoadedIds(nonExistingIds).setLoading(false);
  }

  @Action(FilterActions.Save)
  async save(ctx: StateContext<IFilterState>, { filters }: FilterActions.Save) {
    const status = new StatusUtils(ctx, FilterActions.Save);
    let error: Error;
    try {
      await ctx.dispatch(new FilterActions.UpsertMany(filters)).toPromise();
      this.api.save$(filters).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(FilterActions.Remove)
  async remove(ctx: StateContext<IFilterState>, { filters }: FilterActions.Remove) {
    const status = new StatusUtils(ctx, FilterActions.Remove);
    let error: Error;
    try {
      ctx.patchState(EntityUtils.removeMany(ctx.getState(), filters));
      const filterIds = filters.map((ci) => ci.id);
      this.api.removeByIds$(filterIds).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }
}
