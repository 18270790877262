import { createSelector, Selector } from '@ngxs/store';
import { IStore } from '~_shared/models';
import { IStoreState, StoreState } from './store.state';

export class StoreSelectors {
  @Selector([StoreState])
  static state(s: IStoreState) {
    return s;
  }

  @Selector([StoreState])
  static status(s: IStoreState) {
    return s.status;
  }
  @Selector([StoreState])
  static hasLoaded(s: IStoreState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([StoreState])
  static entities(s: IStoreState) {
    return s.entities;
  }

  @Selector([StoreState])
  static ids(s: IStoreState) {
    return s.ids;
  }

  @Selector([StoreState])
  static all(s: IStoreState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([StoreState])
  static idsByLocation(s: IStoreState) {
    return s.idsByLocation;
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IStoreState) => {
      return s.entities[id];
    });
  }

  static entitiesByLocationFn(locationId: string) {
    return createSelector([this.idsByLocation, this.entities], (idsByLoc, es) => {
      const ids: string[] = idsByLoc[locationId] || [];
      return ids.map((id) => es[id] as IStore);
    });
  }

  static connectedStoreByIdFn(connectStoreId: number) {
    return createSelector([this.state], (s: IStoreState) => {
      return s.connectedStoreByIds[connectStoreId];
    });
  }
}
