import dayjs from '../../libs/dayjs';
import { hash } from '../../utils/misc.utils';

export interface IUserEvent {
  id?: string;
  c?: number; // count
  ts?: number; // unix timestamp
}

export interface IUserEventDictionary {
  [id: string]: number; // number = count OR timestamp
}

export interface IUserEventIdentity {
  userId?: string;
  userIdHashed?: string;
  date?: Date;
  type?: UserEventType;
  entityId?: string;
  entityIdHashed?: string;
}

export interface IUserEventFilterQuery {
  userIds?: string[];
  userIdsHashed?: string[];
  dates?: Date[];
  types?: UserEventType[];
  entityIds?: string[];
  entityIdsHashed?: string[];
  timestampAfter?: number | Date | string;
}

export enum UserEventCountType {
  Unknown = 0,
  ShowLocation = 60,
  ShowPlace = 70,
  ShowStoreRow = 110,
  ScrollStoreRow = 111,
  ShowStoreRowItem = 120,
  ShowStoreDetail = 130,
  ShowStoreDetailBack = 135,
  ShowStoreDetailItem = 140,
  ShowStoreDetailItemBack = 145,
  ShowBrowseViewList = 150,
  ShowBrowseViewMap = 160,
  ShowCategory = 200,
  ShowUserFilter = 210,
  OrderCartAddStore = 510,
  OrderCartAddItem = 511,
  OrderCartRemoveStore = 515,
  OrderCartRemoveItem = 516,
  OrderCheckoutShowStore = 520,
  OrderCheckoutShowItem = 521,
  OrderCheckoutSubmitStore = 525,
  OrderCheckoutSubmitItem = 526,
  ShoppingListShowQuickStore = 550,
  ShoppingListShowQuickItem = 551,
  ShoppingListUseQuickStore = 552,
  ShoppingListUseQuickItem = 553,
  ShoppingListShowList = 554,
  ShoppingListShowEditList = 555,
  ShoppingListShowEditItem = 556,
}

export enum UserEventTimeType {
  ShowOrderStatus = 600,
  ShowOrderDetails = 610,
  ShowOrderMessages = 620,
  ShowOrderMessage = 621,
  ShowStoreOrderStatus = 700,
  ShowStoreOrderDetails = 710,
  ShowStoreOrderMessages = 720,
  ShowStoreOrderMessage = 721,
  ShoppingListUsed = 559,
}

export const UserEventAllType = { ...UserEventCountType, ...UserEventTimeType };

export type UserEventType = UserEventCountType | UserEventTimeType;

export class UserEventHelper {
  /**
   * Create id out of UserEventIdentity parts
   * @param identity
   */
  static id(identity?: IUserEventIdentity) {
    const x: IUserEventIdentity = { ...(identity || {}) };
    x.userId = x.userId || 'unknown';
    x.userIdHashed = x.userIdHashed || hash(x.userId);
    x.date = x.date || new Date();
    x.type = x.type || UserEventCountType.Unknown;
    x.entityId = x.entityId;
    x.entityIdHashed = x.entityIdHashed || (x.entityId ? hash(x.entityId) : '0');
    const strParts = [x.userIdHashed, this.idDate(x.date), x.type, x.entityIdHashed];
    const finalId = strParts.filter((x) => !!x).join('-');
    return finalId;
  }

  /**
   * Parse stringified id to IUserEventIdentity object
   * @param id
   */
  static parseId(id?: string) {
    const strParts = (id || '').split('-');
    if (strParts.length === 1) {
      strParts.push('0'); // append 0 as entityId
    }
    if (strParts.length === 2) {
      strParts.unshift(this.idDate()); // prepend today's date
    }
    if (strParts.length === 3) {
      strParts.unshift(''); // prepend empty hashedUserId
    }
    const [userIdHashed, idDate, type, entityIdHashed] = strParts;
    const identity: IUserEventIdentity = {
      // userId always undefined
      userIdHashed,
      date: this.parseIdDate(idDate),
      type: +type as UserEventType,
      entityIdHashed,
    };
    return identity;
  }

  /**
   * Ensure that id uses passed user id
   * @param id
   * @param userId
   */
  static ensureId(id: string, userId?: string) {
    if (userId) {
      const identity = UserEventHelper.parseId(id);
      delete identity.userIdHashed;
      identity.userId = userId;
      return this.id(identity);
    }
    return id;
  }

  /**
   * Stringify date into YYMMDD
   * @param date
   */
  static idDate(date = new Date()) {
    return dayjs(date).format('YYMMDD');
  }
  /**
   * Recreate date from stringified
   * @param idDate
   */
  static parseIdDate(idDate?: string) {
    if (idDate && /^\d{6,6}$/.test(idDate || '')) {
      const d = /^(?<YY>\d{2,2})(?<MM>\d{2,2})(?<DD>\d{2,2})$/.exec(idDate)?.groups || {};
      const dateStr = `20${d.YY}-${d.MM}-${d.DD}`;
      return new Date(dateStr);
    }
    return;
  }

  /**
   * Check if an user event object matches filter query
   * @param userEvent
   * @param query
   */
  static isMatch(userEvent?: IUserEvent, query?: IUserEventFilterQuery) {
    if (!query) {
      return true;
    }
    const identity = this.parseId(userEvent?.id);
    let r = true;

    let userIdsHashed = query.userIdsHashed || [];
    if (query.userIds?.length && !userIdsHashed.length) {
      userIdsHashed = query?.userIds.map(hash);
    }

    if (userIdsHashed.length && identity.userIdHashed) {
      r = r && userIdsHashed.includes(identity.userIdHashed);
    }
    if (query.dates?.length) {
      const queryIdDates = query.dates.map((d) => this.idDate(d));
      const ueIdDate = this.idDate(identity.date);
      r = r && queryIdDates.includes(ueIdDate);
    }
    if (query.types?.length && identity.type) {
      r = r && query.types.includes(identity.type);
    }
    if (query.entityIds?.length && identity.entityId) {
      r = r && query.entityIds?.includes(identity.entityId);
    }
    if (query.timestampAfter) {
      if (typeof query.timestampAfter === 'object' && query.timestampAfter.getTime) {
        query.timestampAfter = query.timestampAfter.getTime();
      }
      if (typeof query.timestampAfter === 'string') {
        query.timestampAfter = new Date(query.timestampAfter).getTime();
      }
      r = r && this.isTimeType(identity.type) && (userEvent?.ts || 0) > query.timestampAfter;
    }
    return r;
  }

  /**
   * Filter user events by identity query
   * @param userEvents
   * @param query
   */
  static filter(userEvents?: IUserEvent[], query?: IUserEventFilterQuery) {
    return (userEvents || []).filter((ue) => this.isMatch(ue, query));
  }

  static isTimeType(type?: UserEventType) {
    return Object.values(UserEventTimeType).includes(+(type || 0));
  }

  /**
   * Convert shorthand dictionaries into array lists with fixed id and props
   * @param data
   * @param userId
   */
  static dictionaryToList(data?: IUserEventDictionary, userId?: string) {
    const userEvents: IUserEvent[] = [];
    for (const rawId in data || {}) {
      const id = UserEventHelper.ensureId(rawId, userId);
      const { type } = UserEventHelper.parseId(id);
      const userEvent: IUserEvent = { id };
      if (UserEventHelper.isTimeType(type)) {
        userEvent.ts = data?.[rawId] || new Date().getTime();
      } else {
        userEvent.c = Math.abs(data?.[rawId] || 1);
      }
      userEvents.push(userEvent);
    }
    return userEvents;
  }

  /**
   * Merge numbers for multiple dates into single item
   * @param userEvents
   */
  static summary(userEvents?: IUserEvent[], timeTypesOnly?: boolean) {
    const summary: IUserEventDictionary = {};
    for (const ue of userEvents || []) {
      const identity = this.parseId(ue.id);
      if (timeTypesOnly && !this.isTimeType(identity.type)) {
        continue;
      }
      const summaryId = this.id({ type: identity.type, entityIdHashed: identity.entityIdHashed }); // skip userId and date
      const currValue = summary[summaryId] || 0;
      if (this.isTimeType(identity.type)) {
        if (ue.ts && ue.ts > currValue) {
          summary[summaryId] = ue.ts; // set latest timestamp
        }
      } else {
        summary[summaryId] = currValue + (ue.c || 1);
      }
    }
    return summary;
  }

  /**
   * Merge numbers for multiple dates into single item for user
   * @param allUserEvents
   * @param userId
   */
  static userSummary(allUserEvents?: IUserEvent[], userId?: string, timeTypesOnly?: boolean) {
    const userUserEvents = userId
      ? this.filter(allUserEvents, { userIds: [userId] })
      : allUserEvents || [];
    return this.summary(userUserEvents, timeTypesOnly);
  }
}
