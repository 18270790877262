import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IAddressGeopos, ILatLng, ILocation } from '~_shared/models';

@Injectable()
export class LocationApiService {
  constructor(private backend: BackendService) {}

  getById$(id: string) {
    return this.backend.get<ILocation>(`locations/${id}`);
  }

  getByIds$(ids: string[]) {
    if (ids.length < 10) {
      return this.backend.get<ILocation[]>(`locations/ids`, { params: { ids: ids.join(',') } });
    } else {
      return this.backend.post<ILocation[]>(`locations/ids`, ids);
    }
  }

  getBySearchPos$(pos: ILatLng) {
    return this.backend.post<ILocation>(`locations/search/pos`, pos);
  }

  getBySearchZip$(q: string) {
    return this.backend.get<ILocation[]>(`locations/search/zip2city`, { params: { q } });
  }

  getBySearchCity$(q: string) {
    return this.backend.get<ILocation[]>(`locations/search/city`, { params: { q } });
  }

  searchAddressGeopos$(q: string) {
    return this.backend.get<IAddressGeopos>(`locations/search/address-geopos`, { params: { q } });
  }
}
