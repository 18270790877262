import { IItemBody } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[ItemBody] UpsertMany';
  constructor(public items: IItemBody[], loadRelated?: boolean) {}
}

export class GetByIds {
  static readonly type = '[ItemBody] GetByIds';
  constructor(public ids: string[]) {}
}
