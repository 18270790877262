import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import {
  IBulkItems,
  IBulkItemsQuery,
  IBulkLocation,
  IBulkSystem,
  IBulkUser,
} from '~_shared/models';

@Injectable()
export class BulkApiService {
  constructor(private backend: BackendService) {}

  getLocation$(locationId: string) {
    return this.backend.get<IBulkLocation>(`bulk/location/${locationId}`);
  }
  getItemsByQuery$(query: IBulkItemsQuery) {
    const params = {
      r: query.radiusInKm,
      uf: query.userFilterId,
      c: query.categoryId,
      max: query.maxItemsPerStore,
    };
    Object.keys(params).forEach((key) => (params[key] == null ? delete params[key] : void 0)); // remove nulls
    return this.backend.get<IBulkItems>(`bulk/item-list/${query.locationId}`, { params });
  }
  getItemsByIds$(itemIds: string[]) {
    return this.backend.post<IBulkItems>(`bulk/item-list/ids`, { itemIds });
  }
  getItemsByStoreAdmin$(storeId: string) {
    return this.backend.get<IBulkItems>(`bulk/item-list/store-admin/${storeId}`);
  }
  getSystem$() {
    return this.backend.get<IBulkSystem>(`bulk/system`);
  }
  getUser$() {
    return this.backend.get<IBulkUser>(`bulk/user`);
  }
}
