import { IDictionary } from '../models/interfaces';
import { PushTopic } from '../models/push-topic.model';

export interface IGlobalSettings {
  adminUserIds: string[];
  appTitle: string;
  defaultTimezone: string;
  place: {
    radiusKm: {
      min: number;
      max: number;
    };
  };
  requireEmailVerificationInDays: number;
  colors: IDictionary<string>;
  imageSizes: IDictionary<number>;
  pushTopics: PushTopic[];
  poll?: {
    questionIds?: {
      health?: string;
      share?: string;
      tips?: string;
    };
  };
}

export const GLOBAL_SETTINGS: IGlobalSettings = {
  adminUserIds: [], // rather populate in database, in path `helper/globalSettings`
  appTitle: 'Fiffit',
  defaultTimezone: 'UTC', // America/New_York', //'Europe/Stockholm',
  requireEmailVerificationInDays: 7,
  place: {
    radiusKm: {
      min: 1,
      max: 50,
    },
  },
  colors: {
    primary: '#e74145',
    primaryDark: '#cf3a3e',
    secondary: '#56e18b',
    textBase: '#7b7c7e',
    success: '#2daf2d',
    danger: '#df4a4f',
    warning: '#f07b26',
    info: '#1177c8',
    mark: '#ffe847',
  },

  imageSizes: {
    small: 240,
    medium: 960,
    large: 1920,
  },
  pushTopics: Object.values(PushTopic),
  poll: {
    questionIds: {
      health: 'health',
      share: 'share',
      tips: 'tips',
    },
  },
};
