import { IUser } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[User] UpsertMany';
  constructor(public users: IUser[]) {}
}

export class GetById {
  static readonly type = '[User] GetById';
  constructor(public id: string) {}
}

export class GetByIds {
  static readonly type = '[User] GetByIds';
  constructor(public ids: string[]) {}
}

export class Update {
  static readonly type = '[User] Update';
  constructor(public user: Partial<IUser>) {}
}
