import { hash } from '../../utils/misc.utils';
import { IDictionary, IMinMax } from '../interfaces';
import { CategoryHelper, ICategory } from './category.model';
import { IFilter } from './filter.model';
import { IItem, ItemHelper } from './item.model';

export interface IUserFilter {
  id?: string;
  userId?: string;
  title?: string;
  categoryIds?: string[];
  searchQuery?: string;
  discountPercentRange?: IMinMax;
  discountedPriceRange?: IMinMax;
}

export class UserFilterHelper {
  static id(uf?: IUserFilter) {
    return uf?.id || hash(uf?.userId + '-' + new Date().getTime());
  }

  static filterItems(
    items: IItem[],
    userFilter: IUserFilter,
    catsByIds: IDictionary<ICategory>,
    filtersByIds: IDictionary<IFilter>
  ) {
    const cats = (userFilter.categoryIds || []).map((catId) => catsByIds[catId]);
    const filteredItems = CategoryHelper.filterItems(items, cats, catsByIds, filtersByIds).filter(
      (item) => {
        let r = true;
        r = r && ItemHelper.isMatchBySearchQuery(item, userFilter.searchQuery);
        r = r && ItemHelper.isMatchByDiscountRange(item, userFilter.discountPercentRange);
        r = r && ItemHelper.isMatchByPriceRange(item, userFilter.discountedPriceRange);
        return r;
      }
    );
    return filteredItems;
  }
}
