import { sortKeys } from '../../utils/sort.utils';
import { IDictionary } from '../interfaces';
import { IItem, IItemBody } from './item.model';

export interface IBulkItems {
  items: IItem[];
  itemIdsByStoresHash: IDictionary<IDictionary<string[]>>;
  itemBodies: IItemBody[];
}

export interface IBulkItemsQuery {
  locationId: string;
  radiusInKm?: number;
  userFilterId?: string;
  categoryId?: string;
  maxItemsPerStore?: number;
  userId?: string;
}

export class BulkItemListHelper {
  static queryHashId(query: IBulkItemsQuery) {
    return JSON.stringify(sortKeys(query));
  }
}
