import { hash } from '../../utils/misc.utils';
import { ICategory } from './category.model';
import { IFilter, IFilterChain } from './filter.model';

export interface ICategoryFilterInput {
  id: string;
  title: string;
  titleAliases?: string[];
  sortIndex?: number;
  isHidden?: boolean;
  filterByChildren?: boolean;
  filterChains?: IFilterChain[];
}

/**
 * Makes building category/filter trees easier
 */
export class CategoryFilter {
  id: string;
  parent?: CategoryFilter;
  readonly children: CategoryFilter[] = [];

  constructor(private input: ICategoryFilterInput, parent?: CategoryFilter) {
    this.id = input.id;
    this.parent = parent;
  }

  get isRoot() {
    return !this.parent;
  }

  /**
   * Build hashed id by merging all parents' ids + optional prefix
   */
  hashId(prefix?: string) {
    const ids: string[] = [];
    let cf: CategoryFilter | undefined = this;
    while (cf?.id && !cf?.isRoot) {
      ids.push(cf.id);
      cf = cf.parent;
    }
    if (prefix) {
      ids.push(prefix);
    }
    return hash(ids.reverse().join('-'));
  }
  get categoryId() {
    return this.hashId('Category');
  }
  get filterId() {
    return this.input.filterChains ? this.hashId('Filter') : undefined;
  }

  get category(): ICategory {
    return {
      id: this.categoryId,
      title: this.input.title,
      titleAliases: this.input.titleAliases,
      filterId: this.filterId,
      filterByChildren: this.input.filterByChildren,
      parentId: this.parent?.isRoot ? undefined : this.parent?.categoryId,
      sortIndex: this.input.sortIndex,
      isHidden: this.input.isHidden,
    };
  }

  get filter(): IFilter | undefined {
    return this.input.filterChains
      ? {
          id: this.filterId,
          filterChains: this.input.filterChains,
        }
      : undefined;
  }

  static create(input: ICategoryFilterInput, parent?: CategoryFilter) {
    return new CategoryFilter(input, parent);
  }

  addChild(input: CategoryFilter | ICategoryFilterInput) {
    const child = input instanceof CategoryFilter ? input : CategoryFilter.create(input);
    child.parent = this;
    this.children.push(child);
    return this; // chainable
  }

  addChildren(inputs: Array<CategoryFilter | ICategoryFilterInput>) {
    for (const input of inputs || []) {
      this.addChild(input);
    }
    return this; // chainable
  }

  getCategories(cf: CategoryFilter = this, all: ICategory[] = []) {
    if (!cf.isRoot && cf.category) {
      all.push(cf.category);
    }
    cf.children.forEach((c) => this.getCategories(c, all));
    return all;
  }

  getFilters(cf: CategoryFilter = this, all: IFilter[] = []) {
    if (!cf.isRoot && cf.filter) {
      all.push(cf.filter);
    }
    cf.children.forEach((c) => this.getFilters(c, all));
    return all;
  }
}
