import { Pipe, PipeTransform } from '@angular/core';
import { singural } from '~_shared/utils/misc.utils';

@Pipe({
  name: 'singural',
})
export class SinguralPipe implements PipeTransform {
  transform(value: number, outputSingular, outputPlural = false) {
    return singural(value, outputSingular, outputPlural);
  }
}
