import { IPollAnswer, IPollQuestion } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[Poll] UpsertMany';
  constructor(public polls: IPollQuestion[]) {}
}

export class GetByIds {
  static readonly type = '[Poll] GetByIds';
  constructor(public ids: string[]) {}
}

export class UpsertAnswers {
  static readonly type = '[Poll] UpsertAnswers';
  constructor(public answers: IPollAnswer[]) {}
}
export class SaveAnswers {
  static readonly type = '[Poll] SaveAnswers';
  constructor(public answers: IPollAnswer[]) {}
}
export class ClearAnswers {
  static readonly type = '[Poll] ClearAnswers';
  constructor(public questionIds: string[]) {}
}
