import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { GLOBAL_SETTINGS } from '../utils/global-settings';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(GLOBAL_SETTINGS.defaultTimezone);

export default dayjs;
