import { hash } from '../../utils/misc.utils';

export interface IPollQuestion {
  id: string;
  questionText: string;
  notesInternal?: string;
  type: PollQuestionType;
  choices: IPollChoice[];
  next?: IPollNext;
}

export enum PollQuestionType {
  RadioBoxes = 'radios',
  CheckBoxes = 'checks',
  Buttons = 'buttons',
  TextMulti = 'textmulti',
  TextSingle = 'textsingle',
  Rate = 'rate',
  Merger = 'merger', // references to multiple questions
}

export type PollAnswerValue = string | number;

export interface IPollChoice {
  text: string;
  value: PollAnswerValue;
  extras?: IPollChoiceExtras; // | more?
  next?: IPollNext;
  submit?: {
    buttonText?: string; // default: Send
  };
}

export interface IPollNext {
  qid?: string;
  url?: string;
  urlType?: PollChoiceNextUrlType;
  successHeadline?: string;
}

export interface IPollChoiceExtras {
  optional?: boolean;
  cssClass?: string;
  maxLength?: number;
  inputType?: 'text' | 'email' | 'tel' | 'number';
}
export enum PollChoiceNextUrlType {
  Internal = 'internal',
  External = 'external',
  DynamicWindow = 'dw',
}

export interface IPollAnswer {
  id?: string; // hash(questionId + userId)
  userId?: string;
  questionId: string;
  value: PollAnswerValue;
  createdAt?: number;
  refAnswerId?: string; // prev answer
}

export class PollAnswerHelper {
  static id(questionId: string, userId: string) {
    return hash(questionId + userId);
  }
}
