import { mapValues } from 'lodash';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { LatLng } from '../lat-lng.model';
import { IItem, IItemBody } from './item.model';
import { ILocation } from './location.model';
import { IStore } from './store.model';

export interface IBulkProductSite {
  locationByIds?: {
    [locationId: string]: ILocation;
  };
  storeIdsByLocationId?: {
    [locationId: string]: string[];
  };
  itemIdsByStoreIds?: {
    [storeId: string]: string[];
  };
  storeByIds?: {
    [storeId: string]: IStore;
  };
  itemByIds?: {
    [itemId: string]: IItem;
  };
  itemBodyByIds?: {
    [itemBodyId: string]: IItemBody;
  };

  /** Populated by BulkProductSiteHelper.appendLocationIdsByProvince() */
  locationIdsByProvince?: {
    [provinceName: string]: string[];
  };
  locationInfoByIds?: {
    [locationId: string]: ILocationInfo;
  };
}

interface ILocationInfo {
  storeIds: string[];
  itemIds: string[];
  radiusInKm: number;
  // more?
}

export class BulkProductSiteHelper {
  static appendLocationIdsByProvince(data: IBulkProductSite): void {
    const locationIds = Object.keys(data.locationInfoByIds || {});
    const locations = locationIds.map((lid) => data.locationByIds?.[lid]!);
    const locationsByProvince = groupBy(locations, 'province');
    delete locationsByProvince['null'];
    delete locationsByProvince[''];
    data.locationIdsByProvince = mapValues(locationsByProvince, (locs) => {
      const locationIds = locs.map((l) => l.id!);
      // sorted by store count desc
      const sortedLocationIds = orderBy(
        locationIds,
        [(lid) => (data.locationInfoByIds?.[lid]?.storeIds || []).length],
        ['desc']
      );
      return sortedLocationIds;
    });
  }

  static appendLocationInfoByIds(data: IBulkProductSite): void {
    data.locationInfoByIds = {};
    for (const locationId of Object.keys(data.locationByIds || {})) {
      const locationInfo = this.getLocationInfo(data, locationId);
      if (locationInfo?.storeIds?.length) {
        data.locationInfoByIds[locationId] = locationInfo;
      }
    }
  }

  static getLocationInfo(data: IBulkProductSite, locationId: string): ILocationInfo | undefined {
    const location = data.locationByIds?.[locationId];
    const locationPos = LatLng.fromObject(location?.geopos);
    if (!location || !locationPos.latitude || !locationPos.longitude) {
      return;
    }
    const { storeIdsInRange: storeIds, radiusInKm } = this.getLocationStoreIdsInRange(
      data,
      locationId
    );
    const itemIds = flatten(storeIds.map((sid) => data.itemIdsByStoreIds?.[sid] || []));
    // const stores = storeIds.map(sid => data.storeByIds?.[sid]!);

    return { storeIds, itemIds, radiusInKm };
  }

  /**
   * Get stores within range of location;
   * check every 5 km until at least `minStoresNum` stores found in max `maxRadiusInKm` km
   */
  static getLocationStoreIdsInRange(
    data: IBulkProductSite,
    locationId: string,
    radiusInKm = 5,
    minStoresNum = 5,
    maxRadiusInKm = 50
  ): { storeIdsInRange: string[]; radiusInKm: number } {
    const location = data.locationByIds?.[locationId];
    const locationPos = LatLng.fromObject(location?.geopos);
    if (!location || !locationPos.latitude || !locationPos.longitude) {
      return { storeIdsInRange: [], radiusInKm };
    }
    const storeIdsInRange: string[] = [];
    for (const sid of data.storeIdsByLocationId?.[locationId] || []) {
      const store = data.storeByIds?.[sid];
      const storePos = LatLng.fromObject(store?.geopos);
      const distanceInMeters = storePos.getDistance(locationPos);
      const isInRange = distanceInMeters <= radiusInKm * 1000;
      if (isInRange) {
        storeIdsInRange.push(sid);
      }
    }
    const isDone = storeIdsInRange.length >= minStoresNum || radiusInKm >= maxRadiusInKm;
    return isDone
      ? { storeIdsInRange, radiusInKm }
      : this.getLocationStoreIdsInRange(
          data,
          locationId,
          radiusInKm + 5,
          minStoresNum,
          maxRadiusInKm
        );
  }
}
