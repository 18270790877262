import * as ItemActions from './item.actions';
import { ItemSelectors } from './item.selectors';
import { ItemApiService } from './item.service';
import { ItemState } from './item.state';

export const Item = {
  state: ItemState,
  actions: ItemActions,
  select: ItemSelectors,
  api: ItemApiService,
};
