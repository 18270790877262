import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map, tap } from 'rxjs/operators';

@Injectable()
export class DrawerService {
  isOpen = false;
  isOpen$ = this.route.queryParams.pipe(
    map((qs) => !!(qs && qs.drawer)),
    tap((isOpen) => (this.isOpen = isOpen))
  );

  constructor(private router: Router, private route: ActivatedRoute) {}

  async toggle(open?: boolean) {
    open = open ?? !this.isOpen;

    this.router.navigate([], {
      // relativeTo: this.route, // TODO: fix!
      queryParamsHandling: 'merge',
      queryParams: { drawer: open ? 1 : null },
      replaceUrl: !open,
    });
  }

  open() {
    return this.toggle(true);
  }

  close() {
    return this.toggle(false);
  }

  closeOnNextNav() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        first()
      )
      .subscribe(() => this.close());
  }
}
