import { GLOBAL_SETTINGS as C } from '../../utils/global-settings';
import { ILocation } from './location.model';

export interface IPlace {
  id?: string;
  userId?: string;
  locationId?: string;
  radiusKm?: number;
  storeIds?: string[];
}

export interface IPlaceAndLocation {
  place?: IPlace;
  location?: ILocation;
}

export interface IPlaceFilter {
  locationId?: string;
  userFilterId?: string;
  categoryId?: string;
}

export class PlaceHelper {
  static getId(place: IPlace) {
    return `${place.userId}--${place.locationId}`;
  }

  static radiusKm(value: number) {
    if (value < C.place.radiusKm.min || isNaN(value)) {
      return C.place.radiusKm.min;
    }
    if (value > C.place.radiusKm.max) {
      return C.place.radiusKm.max;
    }
    return value;
  }
}
