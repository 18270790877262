// tslint:disable: no-any
import { IItem, ItemHelper } from '../models/db/item.model';
import { ILocation } from '../models/db/location.model';
import { IOrder, OrderHelper } from '../models/db/order.model';
import { IStore } from '../models/db/store.model';
import { LatLng } from '../models/lat-lng.model';

/**
 * Sort stores after common logic
 */
export const sortStores = (
  stores: IStore[],
  location: ILocation,
  itemsByStore?: { [storeId: string]: IItem[] }
) => {
  return (stores || []).sort((a, b) => {
    if (a?.dibs?.active && !b?.dibs?.active) {
      return -1;
    }
    if (!a?.dibs?.active && b?.dibs?.active) {
      return 1;
    }
    const distance = (v: IStore) =>
      LatLng.fromObject(v.geopos).getDistance(LatLng.fromObject(location.geopos));
    if (distance(a) < distance(b)) {
      return -1;
    }
    if (distance(a) > distance(b)) {
      return 1;
    }
    return 0;
  });
};

/**
 * Sort items after common logic
 */
export const sortItems = (items: IItem[]) => {
  return (items || []).sort((a, b) => {
    if (ItemHelper.isPublic(a) && !ItemHelper.isPublic(b)) {
      return -1;
    }
    if (!ItemHelper.isPublic(a) && ItemHelper.isPublic(b)) {
      return 1;
    }
    if (a?.blockImport && !b?.blockImport) {
      return -1;
    }
    if (!a?.blockImport && b?.blockImport) {
      return 1;
    }
    const isGreaterThan = (a: any, b: any) => !(a <= b);
    if (isGreaterThan(a.publishedAt, b.publishedAt)) {
      return -1;
    }
    if (isGreaterThan(b.publishedAt, a.publishedAt)) {
      return 1;
    }
    if (isGreaterThan(a.updatedAt, b.updatedAt)) {
      return -1;
    }
    if (isGreaterThan(b.updatedAt, a.updatedAt)) {
      return 1;
    }
    return 0;
  });
};

/**
 * Sort orders after common logic
 */
export const sortOrders = (orders: IOrder[]) => {
  return (orders || []).sort((a, b) => {
    const now = Date.now();
    const aStatus = OrderHelper.lastStatus(a);
    const bStatus = OrderHelper.lastStatus(b);

    if ((aStatus?.changedAt || a.createdAt || now) > (bStatus?.changedAt || b.createdAt || now)) {
      return -1;
    }
    if ((aStatus?.changedAt || a.createdAt || now) < (bStatus?.changedAt || b.createdAt || now)) {
      return 1;
    }

    return 0;
  });
};

export const sortKeys = <T extends object>(obj: T) => {
  return Object.keys(obj)
    .sort()
    .reduce((sortedObj, key) => {
      (sortedObj as any)[key] = (obj as any)[key];
      return sortedObj;
    }, {} as T);
};
