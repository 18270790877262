import { Component, Inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { NGXS } from '~/core/ngxs';
import { MyUserService, PlaceService, WINDOW } from '~/core/services';
import { GLOBAL_SETTINGS } from '~_shared/utils/global-settings';
import { DynamicWindowsService } from '../dynamic-windows';
import { DrawerService } from './drawer.service';

@Component({
  selector: 'kd-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent {
  constructor(
    public myUser: MyUserService,
    public place: PlaceService,
    public dynamicWindows: DynamicWindowsService,
    public drawer: DrawerService,
    private ngxs: Store,
    @Inject(WINDOW) private window: Window
  ) {}

  gotoPage(page: string) {
    this.dynamicWindows.open(['pages', page]);
  }

  openUrl(url: string) {
    this.window.open(url, '_system');
  }

  /**
   * Close all dynamic windows after navigation been made
   */
  closeDynamicWindows() {
    setTimeout(() => {
      this.dynamicWindows.closeAll();
    }, 10);
  }

  readonly notRated$ = this.ngxs
    .select(NGXS.Poll.select.answerByQuestionIds)
    .pipe(
      map((answerByQuestionIds) => !answerByQuestionIds[GLOBAL_SETTINGS.poll.questionIds.health])
    );
}
