<div class="loading-wrapper">
  <div class="loading">
    <svg
      version="1.1"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 783.4 478.7"
      style="enable-background: new 0 0 783.4 478.7;"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0 {
          fill: #ffffff;
        }
      </style>
      <g>
        <path
          id="XMLID_55_"
          class="st0"
          d="M161.3,478.7l0-3.1V277.3c0-29.4,26.3-55.7,58.8-55.7h0v198.4
		C220.1,452.4,193.8,478.7,161.3,478.7z"
        />
        <path
          id="XMLID_54_"
          class="st0"
          d="M525.2,478.7L525.2,478.7l0-198.4c0-32.5,26.3-58.8,58.8-58.8h0v198.4
		C584,452.4,557.7,478.7,525.2,478.7z"
        />
        <path
          id="XMLID_53_"
          class="st0"
          d="M724.5,280.4v139.5c0,32.5-26.3,58.8-58.8,58.8h0V280.4h-58.8v0c0-32.5,26.3-58.8,58.8-58.8
		h117.7v0C783.4,254.1,757,280.4,724.5,280.4L724.5,280.4z"
        />
        <path
          id="XMLID_51_"
          class="st0"
          d="M58.8,309.8L58.8,309.8c0,16.2,13.2,29.4,29.4,29.4h50.2v0c0,32.5-26.3,58.8-58.8,58.8H58.8
		v27.6c0,29.3-23.8,53.1-53.1,53.1H0l0-152.6c0-29.3,11.6-57.3,32.3-78l0,0c17-16.9,39.9-26.5,63.9-26.5h62.3v0
		c0,32.5-26.3,58.8-58.8,58.8H88.2C72,280.4,58.8,293.6,58.8,309.8z"
        />
        <path
          id="XMLID_50_"
          class="st0"
          d="M442.9,309.8L442.9,309.8c0,16.2,13.2,29.4,29.4,29.4h30v0c0,32.5-26.3,58.8-58.8,58.8h-0.6
		v27.6c0,29.3-23.8,53.1-53.1,53.1h-5.7V326.1c0-29.3,11.6-57.3,32.3-78l0,0c17-16.9,39.9-26.5,63.9-26.5h42.1v8.6
		c0,27.7-22.5,50.2-50.2,50.2h0C456.1,280.4,442.9,293.6,442.9,309.8z"
        />
        <path
          id="XMLID_49_"
          class="st0"
          d="M301.8,309.8L301.8,309.8c0,16.2,13.2,29.4,29.4,29.4h30v0c0,32.5-26.3,58.8-58.8,58.8h-0.6
		v27.6c0,29.3-23.8,53.1-53.1,53.1H243V326.1c0-29.3,11.6-57.3,32.3-78l0,0c17-16.9,39.9-26.5,63.9-26.5h42.1v8.6
		c0,27.7-22.5,50.2-50.2,50.2h0C315,280.4,301.8,293.6,301.8,309.8z"
        />
        <g>
          <path
            class="st0"
            d="M602,20.9c-17.3-21.6-91.2,34.9-91.2,34.9S466-2,399.5,0.1C333-2,287.3,55.7,287.3,55.7s-73.9-56.7-91.2-35
			c-17.3,21.6-8.2,42.8-2.4,91.7c4.5,38.6,40.5,76.1,55.4,90.1c1.5,1.4,3.2,2.6,5.1,3.5c-3.4-8.9-5.2-18.6-5.2-28.7
			c0-45.2,36.7-81.9,81.9-81.9c30,0,56.2,16.2,70.5,40.3c14.3-24.1,40.5-40.3,70.5-40.3c45.2,0,81.9,36.7,81.9,81.9
			c0,8.4-1.3,16.6-3.7,24.2c15.5-14.7,50-51.4,54.4-89.2C610.2,63.4,619.3,42.5,602,20.9z"
          />
          <path
            class="st0"
            d="M322,200.4c-0.2-1.1-0.3-2.2-0.3-3.3c0-10.8,8.7-19.5,19.5-19.5c0.8,0,1.5,0.1,2.3,0.1c0.5,0,0.9,0.1,1.3,0.2
			c9,1.7,15.9,9.6,15.9,19.2c0,1.6-0.2,3.1-0.6,4.6c8.6,1,16.3,2.4,22.4,4.2c4.4-8.1,7.1-17.3,7.3-27.1c0-0.4,0-0.9,0-1.3
			s0-0.9,0-1.3c-0.7-32-26.9-57.8-59.1-57.8c-32.6,0-59.1,26.5-59.1,59.1c0,10.8,2.9,20.9,8,29.6C289.6,203.6,304.6,201.1,322,200.4
			z"
          />
          <path
            class="st0"
            d="M420.3,206.4c5.8-1.8,13-3.3,21.4-4.4c-0.4-1.6-0.6-3.2-0.6-4.9c0-10.8,8.7-19.5,19.5-19.5
			s19.5,8.7,19.5,19.5c0,1.1-0.1,2.2-0.3,3.2c17.6,0.5,33.1,2.8,43.6,6.1c4.8-8.6,7.6-18.5,7.6-29c0-32.6-26.5-59.1-59.1-59.1
			c-32.2,0-58.4,25.8-59.1,57.8c0,0.4,0,0.9,0,1.3s0,0.9,0,1.3C412.9,188.8,415.6,198.2,420.3,206.4z"
          />
        </g>
      </g>
    </svg>
  </div>
</div>
