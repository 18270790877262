import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IShoppingList } from '~_shared/models';

@Injectable()
export class ShoppingListApiService {
  constructor(private backend: BackendService) {}

  getMine$() {
    return this.backend.get<IShoppingList[]>(`shopping-list`);
  }

  save$(shoppingList: IShoppingList) {
    return this.backend.patch<IShoppingList>(`shopping-list`, shoppingList);
  }
}
