import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IConnectStore, ILocation, IStore } from '~_shared/models';

@Injectable()
export class StoreApiService {
  constructor(private backend: BackendService) {}

  getAll$() {
    const params = { limit: 1000 };
    return this.backend.get<IStore[]>(`stores`, { params });
  }

  getById$(id: string) {
    return this.backend.get<IStore>(`stores/${id}`);
  }

  getByIds$(ids: string[]) {
    if (ids.length < 10) {
      return this.backend.get<IStore[]>(`stores/ids`, { params: { ids: ids.join(',') } });
    } else {
      return this.backend.post<IStore[]>(`stores/ids`, ids);
    }
  }

  getByLocation$(locationId: string) {
    return this.backend.get<IStore[]>(`stores/search/location/${locationId}`);
  }

  search$(name: string, location: ILocation) {
    const q = `${name} ${location?.city}`;
    const pos = `${location?.geopos.lat},${location?.geopos.lng}`;
    const params = { q, pos };
    return this.backend.get<IStore[]>(`stores/search/name`, { params });
  }

  update$(store: IStore) {
    return this.backend.put<IStore>(`stores/${store.id}`, store);
  }

  connect$(connectStore: IConnectStore) {
    return this.backend.post<IConnectStore>(`stores/connect`, connectStore);
  }
}
