import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UserEventService } from '../modules/user-event/user-event.service';

@Injectable()
export class ShoppingListService {
  constructor(private datePipe: DatePipe, private ue: UserEventService) {}

  showEditItemDetails = false;
  showPrices = false;

  defaultListTitle = `Min lista ${this.datePipe.transform(Date.now(), 'd MMM')}`.replace('.', '');

  readonly isUsed$ = this.ue.isTracked$(this.ue.Type.ShoppingListUsed);
}
