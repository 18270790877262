import { Component, OnInit } from '@angular/core';
import { MyUserService } from '~/core/services';
import { DynamicWindowsService } from '../../dynamic-windows';

@Component({
  selector: 'kd-drawer-user-panel',
  templateUrl: './drawer-user-panel.component.html',
  styleUrls: ['./drawer-user-panel.component.scss'],
})
export class DrawerUserPanelComponent implements OnInit {
  constructor(public myUser: MyUserService, public dynamicWindows: DynamicWindowsService) {}

  ngOnInit() {}
}
