import { ICartItem } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[CartItem] UpsertMany';
  constructor(public cartItems: ICartItem[], public loadRelated?: boolean) {}
}

export class GetMine {
  static readonly type = '[CartItem] GetMine';
  constructor() {}
}

export class Save {
  static readonly type = '[CartItem] Save';
  constructor(public cartItems: ICartItem[]) {}
}

export class Remove {
  static readonly type = '[CartItem] Remove';
  constructor(public cartItems: ICartItem[]) {}
}

export class LoadRelated {
  static readonly type = '[CartItem] LoadRelated';
  constructor(public cartItems: ICartItem[]) {}
}
