import { createSelector, Selector } from '@ngxs/store';
import { IUserEvent, IUserEventFilterQuery, UserEventHelper } from '~_shared/models';
import { IUserEventState, UserEventState } from './user-event.state';

export class UserEventSelectors {
  @Selector([UserEventState])
  static state(s: IUserEventState) {
    return s;
  }

  @Selector([UserEventState])
  static status(s: IUserEventState) {
    return s.status;
  }
  @Selector([UserEventState])
  static hasLoaded(s: IUserEventState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([UserEventState])
  static entities(s: IUserEventState) {
    return s.entities;
  }

  @Selector([UserEventState])
  static ids(s: IUserEventState) {
    return s.ids;
  }

  @Selector([UserEventState])
  static all(s: IUserEventState) {
    const all = s.ids.map((id) => s.entities[id]);
    const queue = UserEventHelper.dictionaryToList(s.queue);
    return [...all, ...queue];
  }

  @Selector([UserEventState])
  static queue(s: IUserEventState) {
    return s.queue;
  }

  @Selector([UserEventState])
  static lastSavedAt(s: IUserEventState) {
    return s.lastSavedAt;
  }

  static filterAll(query: IUserEventFilterQuery) {
    return createSelector([this.all], (all: IUserEvent[]) => UserEventHelper.filter(all, query));
  }
}
