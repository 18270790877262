import { Selector } from '@ngxs/store';
import { IItemBodyState, ItemBodyState } from './item-body.state';

export class ItemBodySelectors {
  @Selector([ItemBodyState])
  static state(s: IItemBodyState) {
    return s;
  }

  @Selector([ItemBodyState])
  static status(s: IItemBodyState) {
    return s.status;
  }
  @Selector([ItemBodyState])
  static hasLoaded(s: IItemBodyState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([ItemBodyState])
  static entities(s: IItemBodyState) {
    return s.entities;
  }

  @Selector([ItemBodyState])
  static ids(s: IItemBodyState) {
    return s.ids;
  }

  @Selector([ItemBodyState])
  static all(s: IItemBodyState) {
    return s.ids.map((id) => s.entities[id]);
  }
}
