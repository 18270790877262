import { createSelector, Selector } from '@ngxs/store';
import { IStoreUser } from '~_shared/models';
import { IDictionary } from '../_utils';
import { IStoreUserState, StoreUserState } from './store-user.state';

export class StoreUserSelectors {
  @Selector([StoreUserState])
  static state(s: IStoreUserState) {
    return s;
  }

  @Selector([StoreUserState])
  static status(s: IStoreUserState) {
    return s.status;
  }

  @Selector([StoreUserState])
  static entities(s: IStoreUserState) {
    return s.entities;
  }

  @Selector([StoreUserState])
  static ids(s: IStoreUserState) {
    return s.ids;
  }

  @Selector([StoreUserState])
  static all(s: IStoreUserState) {
    return s.ids.map((id) => s.entities[id]);
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IStoreUserState) => s.entities[id]);
  }

  static entityByUserIdFn(userId: string) {
    return createSelector([this.entities], (es: IDictionary<IStoreUser>) => {
      return Object.values(es).filter((e) => e.userId === userId);
    });
  }
}
