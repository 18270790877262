import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '~/core/services/window.service';
import { DynamicWindowsHelper } from './dynamic-windows.helper';

@Injectable()
export class DynamicWindowsInitService {
  public initialRoutes: string[] = [];

  constructor(@Inject(WINDOW) private window: Window) {
    if (window && window.location && window.history) {
      DynamicWindowsHelper.collectInitialRoutes(this.window, this.initialRoutes);
    }
  }

  // Somehow this won't be found in APP_INITIALIZER for some reason...
  // Using static helper in DynamicWindowsHelper instead
  public collectInitialRoutes() {}
}
