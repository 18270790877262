import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppInitComponent } from './app-init.component';
import { HasLocationGuard, InitialLoadedGuard } from './core/guards';

export const dynamicWindowsRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('~/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'admin/store/:storeId/settings',
    loadChildren: () =>
      import('~/admin/store-admin/store-admin-settings/store-admin-settings.module').then(
        (m) => m.StoreAdminSettingsModule
      ),
  },
  {
    path: 'item-details',
    loadChildren: () =>
      import('~/browse/item-details/item-details.module').then((m) => m.ItemDetailsModule),
  },
  {
    path: 'join',
    loadChildren: () => import('~/user/join/join.module').then((m) => m.JoinModule),
  },
  {
    path: 'connect-store',
    loadChildren: () =>
      import('~/admin/connect-store-form/connect-store-form.module').then(
        (m) => m.ConnectStoreFormModule
      ),
  },
  {
    path: 'edit-address',
    loadChildren: () =>
      import('~/admin/edit-address/edit-address.module').then((m) => m.EditAddressModule),
  },
  {
    path: 'cart/edit/:itemId',
    loadChildren: () =>
      import('~/browse/cart/cart-item-form-modal/cart-item-form-modal.module').then(
        (m) => m.CartItemFormModalModule
      ),
  },
  {
    path: 'dibs/details/:mode',
    loadChildren: () =>
      import('~/browse/dibs/dibs-order-details/dibs-order-details.module').then(
        (m) => m.DibsOrderDetailsModule
      ),
  },
  {
    path: 'dibs',
    loadChildren: () =>
      import('~/browse/dibs/dibs-modals/dibs-modals.module').then((m) => m.DibsModalsModule),
  },
  {
    path: 'shopping-list',
    loadChildren: () =>
      import('~/browse/shopping-list/shopping-list-windows/shopping-list-windows.module').then(
        (m) => m.ShoppingListWindowsModule
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('~/user/user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('~/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'polls',
    loadChildren: () => import('~/core/modules/poll/poll.module').then((m) => m.PollModule),
  },
];

const routes: Routes = [
  {
    path: '',
    component: AppInitComponent,
    canActivate: [InitialLoadedGuard],
    children: [
      {
        path: 'setup',
        loadChildren: () => import('./setup/setup.module').then((m) => m.SetupModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: ':locationId',
        loadChildren: () => import('./browse/browse.module').then((m) => m.BrowseModule),
        canActivate: [HasLocationGuard],
      },
      // { path: '**', redirectTo: '' },
    ],
  },
  {
    path: 'dynamic-windows-just-for-preloading-dont-use',
    children: dynamicWindowsRoutes,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
