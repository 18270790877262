import { hash } from '../utils/misc.utils';

export interface IImage {
  path?: string;
  description?: string;
  externalUrl?: string;
}

export class ImageHelper {
  static readonly storageFolder = 'i';

  /**
   * Get full image url
   */
  static imageUrl(
    imageOrPath: IImage | string,
    sizeName: ImageSizeType,
    transformCallback: TransformImageType = (x) => x
  ) {
    let imagePath: string = '';
    switch (typeof imageOrPath) {
      case 'object': {
        if (imageOrPath.path) {
          imagePath = imageOrPath.path;
        } else if (imageOrPath.externalUrl) {
          imagePath = this.externalUrlToPath(imageOrPath.externalUrl);
        }
        break;
      }
      case 'string': {
        imagePath = imageOrPath;
        break;
      }
    }
    if (!imagePath) {
      return;
    }
    const fullImagePath = ImageHelper.getPathWithSize(imagePath, sizeName);
    if (transformCallback && typeof transformCallback === 'function') {
      return transformCallback(fullImagePath);
    }
    return fullImagePath;
  }

  /**
   * Concat storageFolder + fileName to valid image path
   * @param fileName
   */
  static fileNameToPath(fileName: string) {
    return `/${this.storageFolder}/${fileName}`;
  }

  /**
   * Make valid image path out of external url
   * @param externalUrl
   */
  static externalUrlToPath(externalUrl: string) {
    const externalUrlClean = externalUrl.toLowerCase().trim();
    const fileExt = externalUrlClean.match(/\.[\w]+$/)?.[0] ?? '.png';
    const fileBaseName = hash(externalUrlClean);
    const fileName = fileBaseName + fileExt;
    return this.fileNameToPath(fileName);
  }

  /**
   * Get various part info from image path
   * @param filePath
   */
  static getPathInfo(filePath: string): IPathInfo {
    // tslint:disable-next-line: no-parameter-reassignment
    filePath = filePath || '';
    const pathArr = filePath.replace('/\\/g', '/').split('/');
    const fileName = pathArr.pop() || 'unknown.jpg';
    const fileNameArr = fileName.split('.');
    const ext = fileNameArr.pop() || 'jpg';
    const name = fileNameArr.join('.');
    const path = pathArr.join('/');

    const sizeRegex = /@s\-(.+)$/;
    const originalName = name.replace(sizeRegex, '');
    const originalFileName = `${originalName}.${ext}`;
    const sizeMatch = name.match(sizeRegex);
    const sizeName = sizeMatch ? sizeMatch[1] : undefined;
    return {
      filePath,
      path,
      fileName,
      name,
      ext,
      originalName,
      originalFileName,
      sizeName,
    };
  }

  static getPathWithoutSize(filePath: string) {
    const pi = this.getPathInfo(filePath);
    const newFilePath = `${pi.path}/${pi.originalName}.${pi.ext}`;
    return newFilePath;
  }

  static getPathWithSize(filePath: string, sizeName: ImageSizeType) {
    const pi = this.getPathInfo(this.getPathWithoutSize(filePath));
    const newName = `${pi.name}@s-${sizeName}`;
    const newFilePath = `${pi.path}/${newName}.${pi.ext}`;
    return newFilePath;
  }

  static resolve(url: string, baseUrl = 'https://c.fiffit.com/files'): IImage {
    const cleanUrl = url.toLowerCase().startsWith(baseUrl.toLowerCase())
      ? url.substr(baseUrl.length)
      : url;
    const isExternal = cleanUrl.indexOf('://') > 0;
    if (isExternal) {
      return {
        path: ImageHelper.externalUrlToPath(cleanUrl),
        externalUrl: cleanUrl,
      };
    }
    return {
      path: cleanUrl,
    };
  }
}

export interface IPathInfo {
  filePath: string;
  path: string;
  fileName: string;
  name: string;
  ext: string;
  originalName: string;
  originalFileName: string;
  sizeName?: string;
}

export type ImageSizeType = 'small' | 'medium' | 'large';

export type TransformImageType = (imagePath: string) => string;
