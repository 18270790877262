import { IImage } from '../image.model';
import { IDictionary } from '../interfaces';

export interface IOrderItem {
  itemId: string;
  title: string;
  shortInfo?: string;
  image?: IImage;
  priceFull?: number;
  priceDiscounted?: number;
  extId?: string;
  quantity: {
    ordered: number;
    reserved?: number;
    collected?: number;
  };
  message?: string;
}

export interface IOrder {
  id?: string;
  userId: string;
  storeId: string;
  userTelephone?: string;
  orderItems: IOrderItem[];
  status: OrderStatus;
  statusChanges: IOrderStatusChange[];
  delivery?: IOrderDelivery;
  createdAt?: number;
}

export interface IOrderStatusChange {
  status: OrderStatus;
  userId: string;
  note?: string;
  changedAt: number;
}

export interface IOrderStatusWithQuantityChange {
  orderId: string;
  statusChange: IOrderStatusChange;
  orderItemsQuantity: IDictionary<number>;
}

export interface IOrderDelivery {
  code: string;
  verifiedAt?: number;
  verifiedByUserId?: string;
}

export enum OrderStatus {
  NotSent = 'notsent',
  Sent = 'sent', // awaits store response
  Accepted = 'accepted',
  Declined = 'declined',
  Cancelled = 'cancelled',
  Collected = 'collected',
}

export class OrderHelper {
  static isValid(order: IOrder) {
    let r = true;
    r = r && !!order.storeId;
    r = r && !!order.userId;
    r = r && order.orderItems?.length > 0;
    return r;
  }
  static isActive(order: IOrder) {
    const activeStatuses = [OrderStatus.Sent, OrderStatus.Accepted];
    return activeStatuses.includes(order.status);
  }

  static updateStatus(order: IOrder, newStatusChange: IOrderStatusChange) {
    order.statusChanges = order.statusChanges || [];
    if (order.statusChanges.length === 0 && order.status) {
      // add previous status to log if missing
      order.statusChanges.push({
        status: order.status,
        userId: newStatusChange.userId,
        changedAt: Date.now(),
      });
    }
    order.statusChanges.push(newStatusChange);
    order.status = newStatusChange.status;
    return order;
  }

  static lastStatus(order: IOrder) {
    return order.statusChanges?.[order.statusChanges.length - 1];
  }

  static itemQuantity(oit: IOrderItem) {
    return oit.quantity.collected ?? oit.quantity.reserved ?? oit.quantity.ordered;
  }
  static itemQuantityOld(oit: IOrderItem) {
    if (oit.quantity.ordered !== this.itemQuantity(oit)) {
      return oit.quantity.ordered;
    }
    return;
  }
}
