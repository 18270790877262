import { inject, InjectionToken } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from 'environments/environment';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { IReceivedPushMessage } from '../../../../../../../_shared/models';
import { IPushService } from './push.models';

class PushWebService implements IPushService {
  private _message: firebase.messaging.Messaging;
  private _permissionStatus$ = new BehaviorSubject<NotificationPermission>('default');
  private _vapidKey$ = new BehaviorSubject<string>(null);
  private _messages$ = new Subject<IReceivedPushMessage>();

  constructor(private fireMessaging: AngularFireMessaging) {
    // AngularFire is buggy: https://github.com/angular/angularfire/issues/2299
    // set directly to firebase for now
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase); // need this in case FirebaseModule hasnt initialized yet
    }
    if (!firebase.messaging.isSupported()) {
      return;
    }
    this._message = firebase.messaging();
    this._message.usePublicVapidKey(environment.firebase.messagingVapidKey);
    this._vapidKey$.next(environment.firebase.messagingVapidKey);

    // IF USING THIS LATER, MAP message TO IReceivedPushMessage
    // this._message.onMessage((message) => this._messages$.next(message));
  }

  private checkPermission() {
    const permissionStatus = (this.isSupported() && Notification.permission) || 'default';
    if (permissionStatus !== this._permissionStatus$.value) {
      this._permissionStatus$.next(permissionStatus);
    }
  }

  isSupported() {
    return firebase.messaging.isSupported();
  }

  readonly isSupported$ = of(this.isSupported());

  readonly isPermissionGranted$ = this._permissionStatus$.pipe(
    tap(() => this.checkPermission()),
    map((x) => x === 'granted')
  );

  readonly isPermissionDenied$ = this._permissionStatus$.pipe(
    tap(() => this.checkPermission()),
    map((x) => x === 'denied')
  );

  readonly messages$ = this._messages$.asObservable();

  getToken$(requestPermissionIfNotGranted?: boolean): Observable<string> {
    return this._vapidKey$.pipe(
      filter((vapidKey) => !!vapidKey),
      switchMap(() => this.isPermissionGranted$),
      switchMap((isGranted) => {
        if (isGranted || requestPermissionIfNotGranted) {
          return this.fireMessaging.requestToken.pipe(catchError(() => of(null)));
        }
        return of(null);
      })
    );
  }
}

// Make the class (service) dynamically injectable
export const PUSH_WEB_SERVICE_TOKEN = new InjectionToken<PushWebService>('kdPushWebService', {
  factory: () => new PushWebService(inject(AngularFireMessaging)),
});
