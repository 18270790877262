import { IShoppingList } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[ShoppingList] UpsertMany';
  constructor(public shoppingLists: IShoppingList[], public loadRelated?: boolean) {}
}

export class GetMine {
  static readonly type = '[ShoppingList] GetMine';
  constructor() {}
}

export class Save {
  static readonly type = '[ShoppingList] Save';
  constructor(public shoppingLists: IShoppingList[]) {}
}

export class LoadRelated {
  static readonly type = '[ShoppingList] LoadRelated';
  constructor(public shoppingLists: IShoppingList[]) {}
}
