import { Selector } from '@ngxs/store';
import { environment } from 'environments/environment';
import { BulkItemListHelper, IBulkItemsQuery, ILoadStatus, IPlace } from '~_shared/models';
import { isOld } from '~_shared/utils/misc.utils';
import { MeSelectors } from '../me/me.selectors';
import { PlaceSelectors } from '../place/place.selectors';
import { IDictionary } from '../_utils';
import * as BulkActions from './bulk.actions';
import { BulkState, IBulkState } from './bulk.state';

export class BulkSelectors {
  @Selector([BulkState])
  static state(s: IBulkState) {
    return s;
  }

  @Selector([BulkState])
  static status(s: IBulkState) {
    return s.status;
  }
  @Selector([BulkSelectors.status])
  static statusLoading(status: ILoadStatus) {
    return status.loading;
  }
  @Selector([BulkSelectors.status])
  static statusLoadings(status: ILoadStatus) {
    return status.loadings;
  }
  @Selector([BulkSelectors.status])
  static statusLoaded(status: ILoadStatus) {
    return status.loaded;
  }

  @Selector([BulkSelectors.status])
  static hasLoaded(status: ILoadStatus) {
    return !status?.loading && !!status?.lastLoaded;
  }

  @Selector([BulkSelectors.statusLoadings, BulkSelectors.statusLoaded])
  static shouldLoadFn(loadings: { [actionId: string]: any }, loaded: { [actionId: string]: any }) {
    return (actionId?: string, maxAgeInSec = environment.dataMaxAgeInSec) =>
      !loadings[actionId] && isOld(loaded[actionId], maxAgeInSec);
  }

  @Selector([BulkSelectors.statusLoadings])
  static isSystemLoading(statusLoading: IDictionary) {
    return !!statusLoading[BulkActions.GetSystem.type];
  }
  @Selector([BulkSelectors.statusLoaded])
  static hasSystemLoaded(statusLoaded: IDictionary) {
    return !!statusLoaded[BulkActions.GetSystem.type];
  }
  @Selector([BulkSelectors.shouldLoadFn])
  static shouldSystemLoad(shouldLoadFn: (actionId?: string, maxAgeInSec?: number) => boolean) {
    return shouldLoadFn(BulkActions.GetSystem.type);
  }

  @Selector([BulkSelectors.statusLoadings, MeSelectors.token])
  static isUserLoading(statusLoading: IDictionary, token: string) {
    const actionId = token ? `${BulkActions.GetUser.type}: ${token}` : BulkActions.GetUser.type;
    return !!statusLoading[actionId];
  }
  @Selector([BulkSelectors.statusLoaded, MeSelectors.token])
  static hasUserLoaded(statusLoaded: IDictionary, token: string) {
    const actionId = token ? `${BulkActions.GetUser.type}: ${token}` : BulkActions.GetUser.type;
    return !!statusLoaded[actionId];
  }
  @Selector([BulkSelectors.shouldLoadFn, MeSelectors.token])
  static shouldUserLoad(
    shouldLoadFn: (actionId?: string, maxAgeInSec?: number) => boolean,
    token: string
  ) {
    const actionId = token ? `${BulkActions.GetUser.type}: ${token}` : BulkActions.GetUser.type;
    return shouldLoadFn(actionId);
  }

  @Selector([BulkSelectors.statusLoadings])
  static isLocationLoadingFn(statusLoadings: IDictionary) {
    return (locationId: string) => {
      const actionId = `${BulkActions.GetLocation.type}: ${locationId}`;
      return statusLoadings[actionId];
    };
  }
  @Selector([BulkSelectors.statusLoaded])
  static hasLocationLoadedFn(statusLoaded: IDictionary) {
    return (locationId: string) => {
      const actionId = `${BulkActions.GetLocation.type}: ${locationId}`;
      return statusLoaded[actionId];
    };
  }
  @Selector([BulkSelectors.shouldLoadFn])
  static shouldLocationLoadFn(shouldLoadFn: (actionId?: string, maxAgeInSec?: number) => boolean) {
    return (locationId: string) => {
      const actionId = `${BulkActions.GetLocation.type}: ${locationId}`;
      return shouldLoadFn(actionId);
    };
  }
  @Selector([BulkSelectors.shouldLocationLoadFn, PlaceSelectors.selectedOrDefault])
  static shouldLocationLoad(shouldLocationLoadFn: (locationId: string) => boolean, place: IPlace) {
    return place?.locationId && shouldLocationLoadFn(place.locationId);
  }

  @Selector([BulkSelectors.statusLoadings])
  static isItemListLoadingFn(statusLoadings: IDictionary) {
    return (query: IBulkItemsQuery) => {
      const queryHash = BulkItemListHelper.queryHashId(query);
      const actionId = `${BulkActions.GetItemsByQuery.type}: ${queryHash}`;
      return statusLoadings[actionId];
    };
  }
  @Selector([BulkSelectors.statusLoaded])
  static hasItemListLoadedFn(statusLoaded: IDictionary) {
    return (query: IBulkItemsQuery) => {
      const queryHash = BulkItemListHelper.queryHashId(query);
      const actionId = `${BulkActions.GetItemsByQuery.type}: ${queryHash}`;
      return statusLoaded[actionId];
    };
  }
  @Selector([BulkSelectors.shouldLoadFn])
  static shouldItemListLoadFn(shouldLoadFn: (actionId?: string, maxAgeInSec?: number) => boolean) {
    return (query: IBulkItemsQuery) => {
      const queryHash = BulkItemListHelper.queryHashId(query);
      const actionId = `${BulkActions.GetItemsByQuery.type}: ${queryHash}`;
      return shouldLoadFn(actionId);
    };
  }
}
