import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ICartItem } from '~_shared/models';
import * as ItemActions from '../item/item.actions';
import * as StoreActions from '../store/store.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as CartItemActions from './cart-item.actions';
import { CartItemApiService } from './cart-item.service';

export interface ICartItemState extends IEntityState<ICartItem>, IStatusState {}

@State<ICartItemState>({
  name: 'cartItem',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class CartItemState {
  constructor(private api: CartItemApiService) {}

  @Action(CartItemActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<ICartItemState>,
    { cartItems, loadRelated }: CartItemActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, CartItemActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), cartItems));
    if (loadRelated) {
      await ctx.dispatch(new CartItemActions.LoadRelated(cartItems)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(CartItemActions.GetMine)
  async getMine(ctx: StateContext<ICartItemState>, {}: CartItemActions.GetMine) {
    const status = new StatusUtils(ctx, CartItemActions.GetMine);
    let error: Error;
    try {
      const cartItems = await this.api.getMine$().toPromise();
      await ctx.dispatch(new CartItemActions.UpsertMany(cartItems)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(CartItemActions.Save)
  async save(ctx: StateContext<ICartItemState>, { cartItems }: CartItemActions.Save) {
    const status = new StatusUtils(ctx, CartItemActions.Save);
    let error: Error;
    try {
      await ctx.dispatch(new CartItemActions.UpsertMany(cartItems)).toPromise();
      this.api.save$(cartItems).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(CartItemActions.Remove)
  async remove(ctx: StateContext<ICartItemState>, { cartItems }: CartItemActions.Remove) {
    const status = new StatusUtils(ctx, CartItemActions.Remove);
    let error: Error;
    try {
      ctx.patchState(EntityUtils.removeMany(ctx.getState(), cartItems));
      const cartItemIds = cartItems.map((ci) => ci.id);
      this.api.removeByIds$(cartItemIds).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(CartItemActions.LoadRelated)
  async loadRelated(ctx: StateContext<ICartItemState>, { cartItems }: CartItemActions.LoadRelated) {
    const storeIds: string[] = [];
    const itemIds: string[] = [];
    for (const ci of cartItems || []) {
      if (!storeIds.includes(ci.storeId)) {
        storeIds.push(ci.storeId);
      }
      if (!itemIds.includes(ci.itemId)) {
        itemIds.push(ci.itemId);
      }
    }
    if (storeIds.length) {
      ctx.dispatch(new StoreActions.GetByIds(storeIds));
    }
    if (itemIds.length) {
      ctx.dispatch(new ItemActions.GetByIds(itemIds));
    }
  }
}
