import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { ICategory } from '~_shared/models';

@Injectable()
export class CategoryApiService {
  constructor(private backend: BackendService) {}

  getAll$() {
    return this.backend.get<ICategory[]>(`categories`);
  }

  save$(categories: ICategory[]) {
    return this.backend.patch<ICategory[]>(`categories`, categories);
  }

  removeById$(categoryId: string) {
    return this.backend.delete<ICategory[]>(`categories/${categoryId}`);
  }
  removeByIds$(categoryIds: string[]) {
    return this.backend.post<ICategory[]>(`categories/remove`, categoryIds);
  }
}
