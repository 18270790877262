import { Component } from '@angular/core';
import { of } from 'rxjs';
import { DynamicWindowsService } from './core/modules/dynamic-windows';
import { MyUserService, NativeTalkerService } from './core/services';

/**
 * FYI: This is the only route/comp available without having
 * loaded bulk for user and system (handled by InitialLoadedGuard)
 */
@Component({
  selector: 'kd-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  constructor(
    public dynamicWindows: DynamicWindowsService,
    private myUser: MyUserService,
    private nativeTalker: NativeTalkerService
  ) {
    this.myUser.init();
    this.nativeTalker.init();
  }

  readonly loadFailure$ = of(false); // TODO: use?
  // this.myUser.initialLoadedDelayed$.pipe(
  //   filter((x) => !!x),
  //   switchMap(() => this.ngxs.select(NGXS.Me.select.loadFailure))
  // );
}
