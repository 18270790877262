import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IItemBody } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as ItemBodyActions from './item-body.actions';
import { ItemBodyApiService } from './item-body.service';

export interface IItemBodyState extends IEntityState<IItemBody>, IStatusState {}

@State<IItemBodyState>({
  name: 'itemBody',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class ItemBodyState {
  constructor(private api: ItemBodyApiService) {}

  @Action(ItemBodyActions.UpsertMany)
  async upsertMany(ctx: StateContext<IItemBodyState>, { items }: ItemBodyActions.UpsertMany) {
    const status = new StatusUtils(ctx, ItemBodyActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), items));
    status.setLoading(false);
  }

  @Action(ItemBodyActions.GetByIds)
  async getByIds(ctx: StateContext<IItemBodyState>, { ids }: ItemBodyActions.GetByIds) {
    const s = ctx.getState();
    const nonExistingIds = ids.filter((id) => !s.entities[id]);
    if (nonExistingIds.length === 0) {
      return;
    }
    const status = new StatusUtils(ctx, ItemBodyActions.GetByIds);
    const items = await this.api.getByIds$(nonExistingIds).toPromise();
    await ctx.dispatch(new ItemBodyActions.UpsertMany(items)).toPromise();
    status.setLoadedIds(nonExistingIds).setLoading(false);
  }
}
