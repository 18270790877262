import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActionBarModule } from '~/shared/components/action-bar/action-bar.module';
import { MenuListModule } from '~/shared/components/menu-list/menu-list.module';
import { MaterialModule } from '~/shared/modules/material';
import { PipesModule } from '~/shared/pipes/pipes.module';
import { DrawerUserPanelComponent } from './drawer-user-panel/drawer-user-panel.component';
import { DrawerWrapperComponent } from './drawer-wrapper/drawer-wrapper.component';
import { DrawerComponent } from './drawer.component';
import { DrawerService } from './drawer.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MenuListModule,
    ActionBarModule,
    MaterialModule,
    PipesModule,
  ],
  declarations: [DrawerComponent, DrawerUserPanelComponent, DrawerWrapperComponent],
  providers: [DrawerService],
  exports: [DrawerComponent, DrawerWrapperComponent],
})
export class DrawerModule {}
