import { NgModule } from '@angular/core';
import { DateTweaksModule } from './date-tweaks/date-tweaks.module';
import { DiscountPipeModule } from './discount/discount-pipe.module';
import { FriendlyCurrencyPipeModule } from './friendly-currency/friendly-currency-pipe.module';
import { SimpleAddressPipeModule } from './simple-address/simple-address-pipe.module';
import { SinguralPipeModule } from './singural/singural-pipe.module';
import { StringTweaksModule } from './string-tweaks/string-tweaks.module';

const pipeModules = [
  SimpleAddressPipeModule,
  SinguralPipeModule,
  DiscountPipeModule,
  FriendlyCurrencyPipeModule,
  DateTweaksModule,
  StringTweaksModule,
];

@NgModule({
  imports: [...pipeModules],
  exports: [...pipeModules],
  declarations: [],
  providers: [],
})
export class PipesModule {}
