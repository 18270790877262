import { inject, InjectionToken } from '@angular/core';
import 'firebase/messaging';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  IReceivedPushMessage,
  ITalkEventPushActivated,
  ITalkEventPushStatusResult,
  NativeToWebEvent,
  WebToNativeEvent,
} from '../../../../../../../_shared/models';
import { NativeTalkerService } from '../../services/native-talker.service';
import { IPushService } from './push.models';

class PushTnsService implements IPushService {
  private _permissionStatus$ = new BehaviorSubject<NotificationPermission>('default');
  private _token$ = new BehaviorSubject<string>(null);

  constructor(private nativeTalker: NativeTalkerService) {
    // listen for token
    this.nativeTalker
      .listenTo$<ITalkEventPushActivated>(NativeToWebEvent.PUSH_ACTIVATED)
      .pipe(map((data) => data?.token))
      .subscribe((token) => this._token$.next(token));
  }

  readonly isSupported$ = of(true);

  readonly isPermissionGranted$ = this._permissionStatus$.pipe(map((x) => x === 'granted'));
  readonly isPermissionDenied$ = this._permissionStatus$.pipe(map((x) => x === 'denied'));

  readonly messages$ = this.nativeTalker.listenTo$<IReceivedPushMessage>(
    NativeToWebEvent.PUSH_RECEIVED
  );

  getToken$(requestPermissionIfNotGranted?: boolean): Observable<string> {
    return from(this.getStatus()).pipe(
      switchMap(() => combineLatest([this._token$, this.isPermissionGranted$])),
      switchMap(([token, isGranted]) => {
        if (isGranted) {
          // When e.g IOS, we want to trigger token fetch whenever native push is enabled/granted
          requestPermissionIfNotGranted = true;
        }
        if (!token && requestPermissionIfNotGranted) {
          return this.requestPermission$().pipe(map((r) => r.token));
        } else {
          return of(token);
        }
      })
    );
  }

  private requestPermission$() {
    this.nativeTalker.talk(WebToNativeEvent.PUSH_ACTIVATE);
    return this.nativeTalker
      .listenOnce$<ITalkEventPushActivated>(NativeToWebEvent.PUSH_ACTIVATED)
      .pipe(
        withLatestFrom(this.getStatus()),
        map(([r, s]) => {
          return r;
        })
      );
  }

  private async getStatus() {
    this.nativeTalker.talk(WebToNativeEvent.PUSH_STATUS);
    const r = await this.nativeTalker
      .listenOnce$<ITalkEventPushStatusResult>(NativeToWebEvent.PUSH_STATUS_RESULT)
      .toPromise();
    if (r?.enabled === true) {
      this._permissionStatus$.next('granted');
    } else if (r?.enabled === false) {
      this._permissionStatus$.next('denied');
    } else {
      this._permissionStatus$.next('default');
    }
    return r;
  }
}

// Make the class (service) dynamically injectable
export const PUSH_TNS_SERVICE_TOKEN = new InjectionToken<PushTnsService>('kdPushTnsService', {
  factory: () => new PushTnsService(inject(NativeTalkerService)),
});
