import * as UserFilterActions from './user-filter.actions';
import { UserFilterSelectors } from './user-filter.selectors';
import { UserFilterApiService } from './user-filter.service';
import { UserFilterState } from './user-filter.state';

export const UserFilter = {
  state: UserFilterState,
  actions: UserFilterActions,
  select: UserFilterSelectors,
  api: UserFilterApiService,
};
