import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { friendlyId, friendlyZipcode } from '../../utils/misc.utils';

export enum LocationAccurancy {
  ZipCode = 'zipcode',
  City = 'city',
}

export interface ILocation {
  id?: string;
  city?: string;
  zipcode?: string;
  municipality?: string;
  county?: string;
  province?: string;
  geopos?: { lat: number; lng: number };
  accurancy?: LocationAccurancy;
  fetchedAt?: number; // last stores fetched
}

export class LocationHelper implements ILocation {
  static title(it?: ILocation) {
    let title = '';

    if (it?.city) {
      title = it.city;

      if (title === title.toUpperCase()) {
        title = startCase(toLower(title));
      }
    }

    if (it?.zipcode) {
      const zip = friendlyZipcode(it.zipcode);
      title += title ? ` (${zip})` : zip;
      return title;
    }
    if (it?.province) {
      title += title ? `, ${it.province}` : it.province;
      return title;
    }
    if (it?.municipality) {
      title += title ? `, ${it.municipality}` : it.municipality;
      return title;
    }

    return title;
  }

  static id(it: ILocation) {
    const title = LocationHelper.title(it).replace(/((\d{1,})\s(\d{1,}))/g, '$2$3');
    const id = friendlyId(title);
    return id;
  }
}
