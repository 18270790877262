import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IStore } from '~_shared/models';
import { ImageSizeType } from '~_shared/models/image.model';
import { NGXS } from '../ngxs';
import { ImageService } from './image.service';
import { PlaceService } from './place.service';

@Injectable()
export class StoreService {
  constructor(private ngxs: Store, private place: PlaceService, private imageSvc: ImageService) {}

  readonly isLoading$ = this.ngxs.select(NGXS.Store.select.hasLoaded).pipe(map((x) => !x));

  getByLocation$(locationId?: string) {
    const locationId$ = locationId ? of(locationId) : this.place.selectedLocationId$;
    return locationId$.pipe(
      switchMap((lid) => this.ngxs.select(NGXS.Store.select.entitiesByLocationFn(lid)))
    );
  }

  logoUrl(store: IStore, size: ImageSizeType = 'small') {
    return this.imageSvc.imageUrl(store?.imageLogo, size) ?? this.getLogoByTitle(store?.title);
  }

  get logoPatterns() {
    return {
      '/assets/img/store-logos/astridochaporna.png': /^(astrid och aporna)/i,
      '/assets/img/store-logos/citygross.svg': /^(city gross)/i,
      '/assets/img/store-logos/coop-extra.png': /(coop extra)/i,
      '/assets/img/store-logos/coop-forum.png': /(coop forum)/i,
      '/assets/img/store-logos/coop-konsum.png': /(coop konsum)/i,
      '/assets/img/store-logos/coop-lilla.png': /(lilla coop)/i,
      '/assets/img/store-logos/coop-nara.png': /(coop nära)/i,
      '/assets/img/store-logos/coop-stora.png': /(stora extra)/i,
      '/assets/img/store-logos/coop.png': /(coop)/i,
      '/assets/img/store-logos/gram-malmo.png': /^(gram malmö)/i,
      '/assets/img/store-logos/handlarn.png': /(handlar)/i,
      '/assets/img/store-logos/hemkop.png': /(hemköp)/i,
      '/assets/img/store-logos/ica-kvantum.svg': /^(ica kvantum)/i,
      '/assets/img/store-logos/ica-maxi.svg': /^(maxi ica)|(ica maxi)/i,
      '/assets/img/store-logos/ica-nara.png': /^(ica nära)/i,
      '/assets/img/store-logos/ica-supermarket.png': /^(ica supermarket)/i,
      '/assets/img/store-logos/ica.png': /^(ica)/i,
      '/assets/img/store-logos/lidl.png': /^(lidl)/i,
      '/assets/img/store-logos/matoppet.png': /(matöppet)/i,
      '/assets/img/store-logos/nara-dej.png': /^(nära dej)/i,
      '/assets/img/store-logos/netto.png': /^(netto)/i,
      '/assets/img/store-logos/tempo.png': /^(tempo)/i,
      '/assets/img/store-logos/time.png': /^(time)/i,
      '/assets/img/store-logos/willys-hemma.png': /^(willys hemma)/i,
      '/assets/img/store-logos/willys.png': /^(willys)/i,
    };
  }

  getLogoByTitle(title: string) {
    if (typeof title !== 'string') {
      return;
    }
    return Object.keys(this.logoPatterns).find((img) => {
      const regex = this.logoPatterns[img];
      return !!title.match(regex);
    });
  }
}
