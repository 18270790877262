import { IFilter } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[Filter] UpsertMany';
  constructor(public filters: IFilter[]) {}
}

export class GetMine {
  static readonly type = '[Filter] GetMine';
  constructor() {}
}

export class GetByIds {
  static readonly type = '[Filter] GetByIds';
  constructor(public ids: string[]) {}
}

export class Save {
  static readonly type = '[Filter] Save';
  constructor(public filters: IFilter[]) {}
}

export class Remove {
  static readonly type = '[Filter] Remove';
  constructor(public filters: IFilter[]) {}
}
