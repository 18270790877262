import { createSelector, Selector } from '@ngxs/store';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import { IDictionary, IShoppingList, IShoppingListUser, IStore } from '~_shared/models';
import { MeSelectors } from '../me/me.selectors';
import {
  IShoppingListItemInfo,
  ShoppingListItemSelectors,
} from '../shopping-list-item/shopping-list-item.selectors';
import { ShoppingListUserSelectors } from '../shopping-list-user/shopping-list-user.selectors';
import { StoreSelectors } from '../store/store.selectors';
import { IShoppingListState, ShoppingListState } from './shopping-list.state';

export class ShoppingListSelectors {
  @Selector([ShoppingListState])
  static state(s: IShoppingListState) {
    return s;
  }

  @Selector([ShoppingListState])
  static status(s: IShoppingListState) {
    return s.status;
  }
  @Selector([ShoppingListState])
  static hasLoaded(s: IShoppingListState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([ShoppingListState])
  static entities(s: IShoppingListState) {
    return s.entities;
  }

  @Selector([ShoppingListState])
  static ids(s: IShoppingListState) {
    return s.ids;
  }

  @Selector([ShoppingListState])
  static all(s: IShoppingListState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([
    ShoppingListSelectors.entities,
    ShoppingListItemSelectors.entitiesByListIds,
    ShoppingListUserSelectors.entitiesByListIds,
    StoreSelectors.entities,
    MeSelectors.userId,
  ])
  static infoByIds(
    listByIds: IDictionary<IShoppingList>,
    itemsByListIds: IDictionary<IShoppingListItemInfo[]>,
    usersByListIds: IDictionary<IShoppingListUser[]>,
    storeByIds: IDictionary<IStore>,
    currUserId: string
  ) {
    return Object.keys(listByIds).reduce((infoByIds, listId) => {
      infoByIds[listId] = {
        ...listByIds[listId],
        items: itemsByListIds[listId] || [],
        users: usersByListIds[listId] || [],
        groupedItems: [],
        itemsCompleted: [],
        itemsNotCompleted: [],
      };
      // adds .me (current user's)
      infoByIds[listId].me = infoByIds[listId].users.find((u) => u.userId === currUserId);
      // adds .priceDiscountedTotal + .priceFullTotal
      Object.assign(infoByIds[listId], calcTotalPrices(infoByIds[listId].items));
      // adds .itemsCompleted + .itemsNotCompleted for whole list
      infoByIds[listId].items.forEach((it) =>
        infoByIds[listId][it.completedAt ? 'itemsCompleted' : 'itemsNotCompleted'].push(it)
      );
      // add .groupedItems
      const itemsByGroup = groupBy(
        infoByIds[listId].items,
        (it) => it.groupStoreId ?? it.groupText
      );
      infoByIds[listId].groupedItems = values(
        mapValues(itemsByGroup, (items) => {
          const r: IGroupedItems = {
            groupedStoreId: items[0].groupStoreId,
            groupedText: items[0].groupText,
            store: storeByIds[items[0].groupStoreId],
            items: orderBy(items, ['title', 'id']),
            itemsCompleted: [],
            itemsNotCompleted: [],
            ...calcTotalPrices(items), // adds .priceDiscountedTotal + .priceFullTotal
          };
          r.items.forEach((it) =>
            r[it.completedAt ? 'itemsCompleted' : 'itemsNotCompleted'].push(it)
          );
          return r;
        })
      );
      infoByIds[listId].groupedItems = orderBy(infoByIds[listId].groupedItems, [
        (gits) => gits.groupedText || gits.store?.title,
      ]);
      return infoByIds;
    }, {} as IDictionary<IShoppingListInfo>);
  }

  @Selector([ShoppingListSelectors.infoByIds])
  static allInfos(infoByIds: IDictionary<IShoppingListInfo>) {
    return orderBy(Object.values(infoByIds), ['createdAt'], ['desc']);
  }

  @Selector([ShoppingListSelectors.allInfos])
  static activeInfos(infos: IShoppingListInfo[]) {
    return infos.filter((li) => !li.me?.archivedAt);
  }

  @Selector([ShoppingListSelectors.allInfos])
  static archivedInfos(infos: IShoppingListInfo[]) {
    return infos.filter((li) => !!li.me?.archivedAt);
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IShoppingListState) => {
      return s.entities[id];
    });
  }

  static infoByIdFn(id: string) {
    return createSelector([this.infoByIds], (infoByIds: IDictionary<IShoppingListInfo>) => {
      return infoByIds[id];
    });
  }

  @Selector([ShoppingListSelectors.infoByIds])
  static listItemByListAndItemFn(infoByIds: IDictionary<IShoppingListInfo>) {
    const listItemByListAndItem = mapValues(infoByIds, (li) =>
      keyBy(li.items, (lit) => lit.itemId)
    );
    const fn = (listId: string, itemId: string) => listItemByListAndItem?.[listId]?.[itemId];
    return fn;
  }

  @Selector([ShoppingListSelectors.activeInfos])
  static infosByItemIdFn(activeInfos: IShoppingListInfo[]) {
    const infosByItemId: IDictionary<IShoppingListInfo[]> = {};
    for (const li of activeInfos) {
      for (const lit of li.items) {
        if (lit.itemId) {
          infosByItemId[lit.itemId] = infosByItemId[lit.itemId] || [];
          infosByItemId[lit.itemId].push(li);
        }
      }
    }
    const fn = (itemId: string) => infosByItemId[itemId] || [];
    return fn;
  }
}

interface IShoppingListInfo extends IShoppingList {
  groupedItems: Array<IGroupedItems>;
  users: IShoppingListUser[];
  me?: IShoppingListUser;
  items: IShoppingListItemInfo[];
  itemsCompleted: IShoppingListItemInfo[];
  itemsNotCompleted: IShoppingListItemInfo[];
  priceDiscountedTotal?: number;
  priceFullTotal?: number;
}

interface IGroupedItems {
  groupedText?: string;
  groupedStoreId?: string;
  store?: IStore;
  items: IShoppingListItemInfo[];
  itemsCompleted: IShoppingListItemInfo[];
  itemsNotCompleted: IShoppingListItemInfo[];
  priceDiscountedTotal?: number;
  priceFullTotal?: number;
  // sortOrder?
}

const calcTotalPrices = (
  items?: IShoppingListItemInfo[]
): {
  priceDiscountedTotal?: number;
  priceFullTotal?: number;
} => {
  let priceDiscountedTotal: number | undefined;
  let priceFullTotal: number | undefined;
  for (const it of items || []) {
    const q = it.quantity > 0 ? it.quantity : 1;
    const priceDiscounted = it.item?.priceDiscounted ?? it.priceDiscounted;
    const priceFull = it.item?.priceFull ?? it.priceFull ?? priceDiscounted;
    if (priceDiscounted || priceFull) {
      priceDiscountedTotal = priceDiscountedTotal || 0;
      priceDiscountedTotal += q * (priceDiscounted ?? 0);
      priceFullTotal = priceFullTotal || 0;
      priceFullTotal += q * (priceFull ?? 0);
    }
  }
  return { priceDiscountedTotal, priceFullTotal };
};
