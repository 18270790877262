import { hash } from '../../utils/misc.utils';
import { IImage } from '../image.model';

export interface IShoppingList {
  id?: string;
  title?: string;
  createdUserId?: string;
  createdAt?: number;
  updatedAt?: number;
}

export interface IShoppingListUser {
  id?: string;
  shoppingListId: string;
  userId: string;
  access?: ShoppingListAccessType[];
  createdUserId?: string;
  createdAt?: number;
  updatedAt?: number;
  archivedAt?: number;
}

export enum ShoppingListAccessType {
  // Read = just has its user added to the list
  Write = 1,
  Share = 2,
}

export interface IShoppingListItem {
  id?: string;
  shoppingListId: string;
  itemId?: string;
  groupText?: string;
  groupStoreId?: string;
  title?: string;
  shortInfo?: string;
  quantity?: number;
  priceUnit?: string;
  priceFull?: number;
  priceDiscounted?: number;
  image?: IImage;
  completedAt?: number;
  completedUserId?: string;
  createdUserId?: string;
  createdAt?: number;
  updatedAt?: number;
}

export class ShoppingListHelper {
  static async hasAccess(
    listUsers?: IShoppingListUser[],
    userId?: string,
    accessType?: ShoppingListAccessType
  ) {
    return (listUsers || []).some((lu) => {
      let isOk = true;
      isOk = isOk && lu.userId === userId && !!userId;
      if (accessType) {
        isOk = isOk && !!lu?.access?.includes(accessType);
      }
      return isOk;
    });
  }

  static listUserId(listUser?: IShoppingListUser) {
    return `${listUser?.shoppingListId}--${listUser?.userId}`;
  }

  static listItemId(listItem?: IShoppingListItem) {
    if (listItem?.shoppingListId && listItem.itemId) {
      return hash(`${listItem?.shoppingListId}--${listItem?.itemId}`);
    }
    return listItem?.id ?? hash(`${listItem?.shoppingListId}--${Date.now() + Math.random()}`);
  }
}
