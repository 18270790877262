import { IDictionary } from '../interfaces';
import { ITags } from '../tag.model';

export interface IFilter {
  id?: string;
  filterChains?: IFilterChain[];
}

export interface IFilterChain {
  op?: 'AND' | 'OR';
  filterId?: string;
  tags?: ITags;
}

export class FilterHelper {
  static isMatch(
    targetTags?: ITags,
    filter?: IFilter,
    filtersByIds?: IDictionary<IFilter>
  ): boolean {
    let r: boolean | undefined;
    for (const fc of filter?.filterChains || []) {
      const useAND = fc.op !== 'OR';
      if (r == null) {
        r = useAND; // AND = true, OR = false
      }
      const chainMatched = this.isMatchChain(targetTags, fc, filtersByIds);
      r = useAND ? r && chainMatched : r || chainMatched;
    }
    return !!r || r == null;
  }

  static isMatchChain(
    targetTags?: ITags,
    filterChain?: IFilterChain,
    filtersByIds?: IDictionary<IFilter>
  ): boolean {
    let r = true;

    // use filter from filterId
    if (filterChain?.filterId && filtersByIds?.[filterChain.filterId]) {
      const f = filtersByIds[filterChain.filterId];
      r = r && this.isMatch(targetTags, f, filtersByIds);
    }

    // filter by tags
    if (filterChain?.tags) {
      r = r && this.isMatchTags(targetTags, filterChain.tags);
    }

    return r;
  }

  static isMatchTags(targetTags?: ITags, filterTags?: ITags) {
    targetTags = targetTags || {};
    filterTags = filterTags || {};
    let r = true;
    all: for (const ftKey in filterTags || {}) {
      const ttValues = (targetTags[ftKey] || []).map((x) => x.toString().toLowerCase());
      for (const ftVal of filterTags[ftKey] || []) {
        let hasMatch = false;
        if (/^\/.+\/$/.test(ftVal.toString())) {
          // match by regex
          let reStr = ftVal.toString();
          reStr = reStr.substring(1);
          reStr = reStr.substring(0, reStr.length - 1);
          const regex = new RegExp(reStr, 'gi');
          hasMatch = !!ttValues.find((ttVal) => regex.test(ttVal));
        } else {
          // match by string
          hasMatch = ttValues.includes(ftVal.toString().toLowerCase() as never);
        }
        r = r && hasMatch;
        if (!r) {
          break all;
        }
      }
    }
    return r;
  }
}
