import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IStoreUser } from '~_shared/models';
import * as OrderActions from '../order/order.actions';
import * as StoreActions from '../store/store.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as StoreUserActions from './store-user.actions';
import { StoreUserApiService } from './store-user.service';

export interface IStoreUserState extends IEntityState<IStoreUser>, IStatusState {}

@State<IStoreUserState>({
  name: 'storeUser',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class StoreUserState {
  constructor(private api: StoreUserApiService) {}

  @Action(StoreUserActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IStoreUserState>,
    { storeUsers, loadRelated }: StoreUserActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, StoreUserActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), storeUsers));
    if (loadRelated) {
      await ctx.dispatch(new StoreUserActions.LoadRelated(storeUsers)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(StoreUserActions.GetMine)
  async getMine(ctx: StateContext<IStoreUserState>, {}: StoreUserActions.GetMine) {
    const status = new StatusUtils(ctx, StoreUserActions.GetMine);
    const storeUsers = await this.api.getMine$().toPromise();
    await ctx.dispatch(new StoreUserActions.UpsertMany(storeUsers)).toPromise();
    status.setLoading(false);
  }

  @Action(StoreUserActions.LoadRelated)
  async loadRelated(
    ctx: StateContext<IStoreUserState>,
    { storeUsers }: StoreUserActions.LoadRelated
  ) {
    const storeIds = (storeUsers || []).map((su) => su.storeId);
    if (storeIds.length) {
      ctx.dispatch(new StoreActions.GetByIds(storeIds));
      ctx.dispatch(new OrderActions.GetByStores(storeIds));
    }
  }
}
