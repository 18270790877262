import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IPushMessage } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as PushMessageActions from './push-message.actions';
import { PushMessageApiService } from './push-message.service';

export interface IPushMessageState extends IEntityState<IPushMessage>, IStatusState {}

@State<IPushMessageState>({
  name: 'pushMessage',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class PushMessageState {
  constructor(private api: PushMessageApiService) {}

  @Action(PushMessageActions.GetMine)
  async getMine(ctx: StateContext<IPushMessageState>, {}: PushMessageActions.GetMine) {
    const status = new StatusUtils(ctx, PushMessageActions.GetMine);
    const s = () => ctx.getState();
    let error: Error;
    try {
      const items = await this.api.getMine();
      ctx.patchState(EntityUtils.upsertMany(s(), items));
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }
}
