import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ICategory } from '~_shared/models';
import * as FilterActions from '../filter/filter.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as CategoryActions from './category.actions';
import { CategoryApiService } from './category.service';

export interface ICategoryState extends IEntityState<ICategory>, IStatusState {}

@State<ICategoryState>({
  name: 'category',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class CategoryState {
  constructor(private api: CategoryApiService) {}

  @Action(CategoryActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<ICategoryState>,
    { categories, loadRelated }: CategoryActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, CategoryActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), categories));
    if (loadRelated) {
      await ctx.dispatch(new CategoryActions.LoadRelated(categories)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(CategoryActions.GetAll)
  async getAll(ctx: StateContext<ICategoryState>, {}: CategoryActions.GetAll) {
    const status = new StatusUtils(ctx, CategoryActions.GetAll);
    let error: Error;
    try {
      const categories = await this.api.getAll$().toPromise();
      await ctx.dispatch(new CategoryActions.UpsertMany(categories)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(CategoryActions.Save)
  async save(ctx: StateContext<ICategoryState>, { categories }: CategoryActions.Save) {
    const status = new StatusUtils(ctx, CategoryActions.Save);
    let error: Error;
    try {
      await ctx.dispatch(new CategoryActions.UpsertMany(categories)).toPromise();
      this.api.save$(categories).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(CategoryActions.Remove)
  async remove(ctx: StateContext<ICategoryState>, { categories }: CategoryActions.Remove) {
    const status = new StatusUtils(ctx, CategoryActions.Remove);
    let error: Error;
    try {
      ctx.patchState(EntityUtils.removeMany(ctx.getState(), categories));
      const categoryIds = categories.map((ci) => ci.id);
      this.api.removeByIds$(categoryIds).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(CategoryActions.LoadRelated)
  async loadRelated(
    ctx: StateContext<ICategoryState>,
    { categories }: CategoryActions.LoadRelated
  ) {
    const filterIds: string[] = [];
    for (const c of categories || []) {
      if (!filterIds.includes(c.filterId)) {
        filterIds.push(c.filterId);
      }
    }
    if (filterIds.length) {
      ctx.dispatch(new FilterActions.GetByIds(filterIds));
    }
  }
}
