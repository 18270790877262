import { createSelector, Selector } from '@ngxs/store';
import mapValues from 'lodash/mapValues';
import { ICategory, IItem, IItemBody, ItemHelper } from '~_shared/models';
import { CategorySelectors } from '../category/category.selectors';
import { ItemBodySelectors } from '../item-body/item-body.selectors';
import { LocationSelectors } from '../location/location.selectors';
import { IDictionary } from '../_utils';
import { hashIdAll, IItemState, ItemState } from './item.state';

/**
 * FYI: Due to the large amount of items, we don't want to make
 * selectors that affect entities or ids directly.
 * Instead, these selectors are wrapped as functions, to only work
 * on specific range of items, when needed.
 */
export class ItemSelectors {
  @Selector([ItemState])
  static state(s: IItemState) {
    return s;
  }

  @Selector([ItemState])
  static status(s: IItemState) {
    return s.status;
  }
  @Selector([ItemState])
  static hasLoaded(s: IItemState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([ItemState])
  static entitiesRaw(s: IItemState) {
    return s.entities; // compressed entities!
  }

  @Selector([ItemBodySelectors.entities])
  static entityEmbodiedFn(itemBodyEntities: IDictionary<IItemBody>) {
    return (entity: IItem) => ItemHelper.embody(entity, itemBodyEntities);
  }

  @Selector([ItemSelectors.entitiesRaw, ItemSelectors.entityEmbodiedFn])
  static entityByIdFn(entitiesRaw: IDictionary<IItem>, entityEmbodiedFn: (entity: IItem) => IItem) {
    return (id: string) => entityEmbodiedFn(entitiesRaw[id]);
  }

  @Selector([ItemSelectors.entityByIdFn])
  static entitiesByIdsFn(entityByIdFn: (id: string) => IItem) {
    return (ids: string[], publicOnly = true) => {
      const validItems = ids.reduce((list, id) => {
        const entity = entityByIdFn(id);
        let isOk = !!entity;
        isOk = isOk && ((publicOnly && ItemHelper.isPublic(entity)) || !publicOnly);
        if (entity) {
          list.push(entity);
        }
        return list;
      }, [] as IItem[]);
      return validItems;
    };
  }

  @Selector([ItemState])
  static ids(s: IItemState) {
    return s.ids;
  }

  @Selector([ItemState])
  static idsByStoresHash(s: IItemState) {
    return s.idsByStoresHash;
  }

  @Selector([ItemSelectors.idsByStoresHash])
  static idsByStoresFn(idsByStoresHash: IDictionary<IDictionary<string[]>>) {
    return (hashId: string) => idsByStoresHash[hashId] || {};
  }

  @Selector([ItemSelectors.idsByStoresHash])
  static idsByStoresAll(idsByStoresHash: IDictionary<IDictionary<string[]>>) {
    return idsByStoresHash[hashIdAll] || {};
  }

  @Selector([
    ItemSelectors.idsByStoresFn,
    LocationSelectors.selectedId,
    CategorySelectors.categoryAll,
  ])
  static idsByStoresForLocation(
    idsByStoresFn: (hashId: string) => IDictionary<string[]>,
    locationId: string,
    catAll: ICategory
  ): IDictionary<string[]> {
    const hashId = `${locationId}_${catAll.id}`; // default hashId = curr location + "All" cat (first root)
    return idsByStoresFn(hashId);
  }

  @Selector([ItemSelectors.idsByStoresForLocation, ItemSelectors.entitiesByIdsFn])
  static entitiesByStoresForLocation(
    idsByStoresForLocation: IDictionary<string[]>,
    entitiesByIdsFn: (ids: string[]) => IItem[]
  ): IDictionary<IItem[]> {
    return mapValues(idsByStoresForLocation, entitiesByIdsFn);
  }
  /**
   * @deprecated OLD - Shouldnt use entitiesByStoresForLocation
   * @todo Rewrite for store admin listing?
   */
  static entitiesByStoreFn(storeId: string, publicOnly = true) {
    console.log('entitiesByStoreFn');
    return createSelector(
      [this.entitiesByStoresForLocation],
      (entitiesByStoresForLocation: IDictionary<IItem[]>) => entitiesByStoresForLocation[storeId]
    );
  }

  /**
   * @deprecated OLD - Shouldnt use entitiesByStoresForLocation
   * @todo Rewrite for store admin listing?
   */
  static entitiesByStoresFn(storeIds: string[], publicOnly = true) {
    console.log('entitiesByStoresFn');
    return createSelector(
      [this.entitiesByStoresForLocation],
      (entitiesByStoresForLocation: IDictionary<IItem[]>) => {
        const entitiesByStores: IDictionary<IItem[]> = {};
        for (const storeId of storeIds) {
          entitiesByStores[storeId] = entitiesByStoresForLocation[storeId] || [];
        }
        return entitiesByStores;
      }
    );
  }
}
