import * as BulkActions from './bulk.actions';
import { BulkSelectors } from './bulk.selectors';
import { BulkApiService } from './bulk.service';
import { BulkState } from './bulk.state';

export const Bulk = {
  state: BulkState,
  actions: BulkActions,
  select: BulkSelectors,
  api: BulkApiService,
};
