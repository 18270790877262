import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IItem, IItemEdit } from '~_shared/models';

@Injectable()
export class ItemApiService {
  constructor(private backend: BackendService) {}

  getById$(id: string) {
    return this.backend.get<IItem>(`items/${id}`);
  }

  getByIds$(ids: string[]) {
    if (ids.length < 10) {
      return this.backend.get<IItem[]>(`items/ids`, { params: { ids: ids.join(',') } });
    } else {
      return this.backend.post<IItem[]>(`items/ids`, ids);
    }
  }

  getByStores$(storeIds: string[]) {
    return storeIds.length <= 20
      ? this.backend.get<IItem[]>(`items/by/stores`, { params: { storeIds: storeIds.join(',') } })
      : this.backend.post<IItem[]>(`items/by/stores`, { storeIds });
  }

  getByStoreAdmin$(storeId: string) {
    return this.backend.get<IItem[]>(`items/by/store-admin/${storeId}`);
  }

  save$(item: IItemEdit) {
    if (!item || !item.storeId) {
      throw new Error('Missing storeId');
    }
    return this.backend.patch<IItem>(`items/by/store-admin/${item.storeId}`, item);
  }
}
