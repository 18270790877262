import { createSelector, Selector } from '@ngxs/store';
import { FilterState, IFilterState } from './filter.state';

export class FilterSelectors {
  @Selector([FilterState])
  static state(s: IFilterState) {
    return s;
  }

  @Selector([FilterState])
  static status(s: IFilterState) {
    return s.status;
  }
  @Selector([FilterState])
  static hasLoaded(s: IFilterState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([FilterState])
  static entities(s: IFilterState) {
    return s.entities;
  }

  @Selector([FilterState])
  static ids(s: IFilterState) {
    return s.ids;
  }

  @Selector([FilterState])
  static all(s: IFilterState) {
    return s.ids.map((id) => s.entities[id]);
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IFilterState) => {
      return s.entities[id];
    });
  }
}
