import { IEnvironment } from './environment.interface';

// const backendBaseUrl = 'http://localhost:5001/fiffit-dev/europe-west1';
const backendBaseUrl = 'https://c-dev.fiffit.com';

export const environment: IEnvironment = {
  fileBaseUrl: `${backendBaseUrl}/files`,
  apiBaseUrl: `${backendBaseUrl}/api`,
  googleApiKey: 'AIzaSyC0t25Gu2Au_i-8CTFE7ILkjXrlbdhuzD8',
  firebase: {
    apiKey: 'AIzaSyC0t25Gu2Au_i-8CTFE7ILkjXrlbdhuzD8',
    authDomain: 'fiffit-dev.firebaseapp.com',
    databaseURL: 'https://fiffit-dev.firebaseio.com',
    projectId: 'fiffit-dev',
    storageBucket: 'fiffit-dev.appspot.com',
    messagingSenderId: '476572825394',
    messagingVapidKey:
      'BP4Dm7Bz2xgrqRDSigtr6dvXg3aaTcP1FhpKalr5wuSDN8sapu1d-1M8p12bYuFRqnR89nXacwvmLOfn0WM01QU',
    appId: '1:476572825394:web:4fe05d84eb9ce224dec92b',
    measurementId: 'nope', // dont measure dev
  },
  dataMaxAgeInSec: 30 * 60,
};
