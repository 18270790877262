export type TagValue = Array<string | number> | undefined;

export interface ITags {
  provider?: string[];
  category?: string[];
  gender?: string[];
  shoeSizeEU?: number[];
  shoeSizeUS?: number[];
  size?: (string | number)[];
  color?: string[];
  brand?: string[];

  // populated by item data
  storeId?: string[];
  discount?: number[];

  // misc
  [prop: string]: TagValue;
}

export class TagHelper {
  static setShoeSize(tags: ITags, size?: string | number): 'EU' | 'US' | 'general' | undefined {
    if (!size) return;
    const sizeNum = +(size + '').replace(/[^0-9\.]+/g, '');
    if (sizeNum > 0 && sizeNum <= 15) {
      tags.shoeSizeUS = tags.shoeSizeUS || [];
      tags.shoeSizeUS.push(sizeNum);
      return 'US';
    }
    if (sizeNum > 20 && sizeNum <= 50) {
      tags.shoeSizeEU = tags.shoeSizeEU || [];
      tags.shoeSizeEU.push(sizeNum);
      return 'EU';
    }
    // general size
    tags.size = tags.size || [];
    tags.size.push(size);
    return 'general';
  }
}
