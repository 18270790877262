import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'kd-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  // tslint:disable-next-line: no-any
  @Input() back: boolean | RouterLink | any[] | (($event: MouseEvent) => void);

  constructor(private router: Router, private route: ActivatedRoute, private location: Location) {}

  onBack($event: MouseEvent) {
    switch (typeof this.back) {
      case 'function':
        return this.back.call(this, $event);
      case 'boolean':
        return this.location.back();
      case 'object':
        return this.router.navigate(this.back as any[], { relativeTo: this.route });
      case 'string':
        return this.router.navigateByUrl(this.back, { relativeTo: this.route });
    }
  }
}
