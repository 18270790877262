import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IConnectStore, IStore } from '~_shared/models';
import * as StoreUserActions from '../store-user/store-user.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as StoreActions from './store.actions';
import { StoreApiService } from './store.service';

export interface IStoreState extends IEntityState<IStore>, IStatusState {
  idsByLocation: { [locationId: string]: string[] };
  connectedStoreByIds: { [connectedStoreId: number]: IConnectStore };
}

@State<IStoreState>({
  name: 'store',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
    idsByLocation: {},
    connectedStoreByIds: {},
  },
})
@Injectable()
export class StoreState {
  constructor(private api: StoreApiService) {}

  @Action(StoreActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IStoreState>,
    { stores, locationId }: StoreActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, StoreActions.UpsertMany);
    const s = ctx.getState();
    const idsByLocation = { ...s.idsByLocation };
    idsByLocation[locationId] = stores.map((store) => store.id);
    ctx.patchState({ ...EntityUtils.upsertMany(ctx.getState(), stores), idsByLocation });
    status.setLoading(false);
  }

  @Action(StoreActions.GetAll)
  async getAll(ctx: StateContext<IStoreState>, {}: StoreActions.GetAll) {
    const status = new StatusUtils(ctx, StoreActions.GetAll);
    const stores = await this.api.getAll$().toPromise();
    await ctx.dispatch(new StoreActions.UpsertMany(stores)).toPromise();
    status.setLoading(false);
  }

  @Action(StoreActions.GetById)
  async getById(ctx: StateContext<IStoreState>, { id }: StoreActions.GetById) {
    const status = new StatusUtils(ctx, StoreActions.GetById);
    const s = ctx.getState();
    const store = s.entities[id] || (await this.api.getById$(id).toPromise());
    await ctx.dispatch(new StoreActions.UpsertMany([store])).toPromise();
    status.setLoading(false);
  }

  @Action(StoreActions.GetByIds)
  async getByIds(ctx: StateContext<IStoreState>, { ids }: StoreActions.GetByIds) {
    const s = ctx.getState();
    const nonExistingIds = ids.filter((id) => !s.entities[id]);
    if (nonExistingIds.length === 0) {
      return;
    }
    const status = new StatusUtils(ctx, StoreActions.GetByIds);
    const stores = await this.api.getByIds$(nonExistingIds).toPromise();
    await ctx.dispatch(new StoreActions.UpsertMany(stores)).toPromise();
    status.setLoading(false);
  }

  @Action(StoreActions.GetByLocation)
  async getByLocation(ctx: StateContext<IStoreState>, { locationId }: StoreActions.GetByLocation) {
    const status = new StatusUtils(ctx, StoreActions.GetByLocation);
    const stores = await this.api.getByLocation$(locationId).toPromise();
    await ctx.dispatch(new StoreActions.UpsertMany(stores, locationId)).toPromise();
    status.setLoading(false);
  }

  @Action(StoreActions.Update)
  async update(ctx: StateContext<IStoreState>, { store, stateOnly }: StoreActions.Update) {
    const status = new StatusUtils(ctx, StoreActions.Update);
    const updatedStore = stateOnly ? store : await this.api.update$(store).toPromise();
    const s = ctx.getState();
    if (updatedStore) {
      ctx.patchState(EntityUtils.updateOne(s, updatedStore));
    }
    status.setLoading(false);
  }

  @Action(StoreActions.Connect)
  async connect(
    ctx: StateContext<IStoreState>,
    { connectStoreId, connectStore }: StoreActions.Connect
  ) {
    const status = new StatusUtils(ctx, StoreActions.Connect);
    const connectedStore = await this.api.connect$(connectStore).toPromise();
    const s = ctx.getState();
    if (connectedStore) {
      ctx.patchState({
        ...EntityUtils.upsertOne(s, connectedStore.store),
        connectedStoreByIds: { ...s.connectedStoreByIds, [connectStoreId]: connectedStore },
      });
    }
    status.setLoading(false);
    ctx.dispatch(new StoreUserActions.GetMine());
  }
}
