import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IPlace } from '~_shared/models';
import * as LocationActions from '../location/location.actions';
import * as MeActions from '../me/me.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as PlaceActions from './place.actions';
import { PlaceApiService } from './place.service';

export interface IPlaceState extends IEntityState<IPlace>, IStatusState {}

@State<IPlaceState>({
  name: 'place',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class PlaceState {
  constructor(private api: PlaceApiService) {}

  @Action(PlaceActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IPlaceState>,
    { places, loadRelated }: PlaceActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, PlaceActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), places));
    if (loadRelated) {
      await ctx.dispatch(new PlaceActions.LoadRelated(places)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(PlaceActions.GetMine)
  async getMine(ctx: StateContext<IPlaceState>, {}: PlaceActions.GetMine) {
    const status = new StatusUtils(ctx, PlaceActions.GetMine);
    const places = await this.api.getMine();
    await ctx.dispatch(new PlaceActions.UpsertMany(places)).toPromise();
    status.setLoading(false);
  }

  @Action(PlaceActions.Upsert)
  async upsert(ctx: StateContext<IPlaceState>, { place }: PlaceActions.Upsert) {
    const status = new StatusUtils(ctx, PlaceActions.Upsert);

    const savedPlace = await this.api.upsert(place);
    await ctx.dispatch(new PlaceActions.UpsertMany([savedPlace])).toPromise();
    status.setLoading(false);
  }

  @Action(PlaceActions.Delete)
  async delete(ctx: StateContext<IPlaceState>, { locationId }: PlaceActions.Delete) {
    const status = new StatusUtils(ctx, PlaceActions.Delete);
    const s = ctx.getState();
    const allPlaces = Object.values(s.entities);
    const removeItem = allPlaces.find((x) => x.locationId === locationId);
    ctx.patchState(EntityUtils.removeOne(s, removeItem));
    if (removeItem) {
      await ctx.dispatch(new MeActions.DeselectLocation(removeItem.locationId)).toPromise();
      this.api.delete(removeItem.id);
    }
    status.setLoading(false);
  }

  @Action(PlaceActions.LoadRelated)
  async loadRelated(ctx: StateContext<IPlaceState>, { places }: PlaceActions.LoadRelated) {
    const locationIds = places.map((p) => p?.locationId);
    await ctx.dispatch(new LocationActions.GetByIds(locationIds)).toPromise();
  }
}
