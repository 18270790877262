import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kd-animation-decline',
  templateUrl: './animation-decline.component.html',
  styleUrls: ['./animation-decline.component.scss'],
})
export class AnimationDeclineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
