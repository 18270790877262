import getBoundsOfDistance from 'geolib/es/getBoundsOfDistance';
import getDistance from 'geolib/es/getDistance';

export interface ILatLng {
  latitude: number;
  longitude: number;
}
export interface ILatLngShort {
  lat: number;
  lng: number;
}

export class LatLng implements ILatLng {
  constructor(public latitude: number = 0, public longitude: number = 0) {}

  toString() {
    return this.latitude + ',' + this.longitude;
  }

  round(decimals = 3) {
    const d = Math.pow(10, decimals);
    return new LatLng(Math.round(this.latitude * d) / d, Math.round(this.longitude * d) / d);
  }

  /**
   * Get distance in meters
   */
  getDistance(destination: LatLng, accuracy?: number) {
    return LatLngHelper.getDistance(this, destination, accuracy);
  }

  static fromString(latLngAsString: string) {
    const latlng = (latLngAsString + ',').split(',');
    return new LatLng(parseFloat(latlng[0]), parseFloat(latlng[1]));
  }

  // tslint:disable-next-line: no-any
  static fromObject(o: any) {
    const latitude: number = o?.latitude || o?.lat || 0;
    const longitude: number = o?.longitude || o?.lng || o?.lon || 0;
    return new LatLng(latitude, longitude);
  }
}

export class LatLngHelper {
  static getDistance(fromPos: ILatLng, toPos: ILatLng, accuracy?: number) {
    return getDistance(fromPos, toPos, accuracy);
  }

  static getBoundsOfDistance(point: ILatLng, distanceInMeters: number) {
    return getBoundsOfDistance(point, distanceInMeters);
  }
}
