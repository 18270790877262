export class DynamicWindowsHelper {
  /**
   * Checks if outlet name is one of the dynamically generated
   */
  static isDynamicOutlet(outletName: string) {
    return /^w\d{1,}$/.test(outletName);
  }

  /**
   * Find and store dynamic window routes and redirect to url without found routes.
   * Found routes will later be executed again by DynamicWindowService.
   */
  static collectInitialRoutes(window: Window, initialRoutes: string[]) {
    const url = window.location.href;

    // handle named outlets url, e.g '(w2:w/b//w0:w/my-url)'
    const newUrl = url.replace(/(\(.*\))/, (match) => {
      match = match.replace(/^\(|\)$/g, ''); // remove parantheses = 'w2:w/b//w0:w/my-url'
      const namedOutlets = match.split('//');

      const initialRoutesRaw: string[] = [];
      const namedOutletsWithoutDW = namedOutlets.filter((no) => {
        const [key, val] = no.split(':'); // e.g 'w0:w/my-url'
        const keyNum = parseInt(key.replace(/[^\d]/gi, ''));

        if (DynamicWindowsHelper.isDynamicOutlet(key) && val) {
          const dwRoute = val.replace(/^w\//, ''); // remove 'w/' from 'w/my-url'
          initialRoutesRaw[keyNum] = dwRoute; // save 'my-url'
        } else {
          return true;
        }
      });

      for (const r of initialRoutesRaw) {
        if (r) {
          initialRoutes.push(r);
        }
      }
      return namedOutletsWithoutDW.length ? `(${namedOutletsWithoutDW.join('//')})` : '';
    });

    window.history.replaceState(null, null, newUrl);
  }
}
