import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IPollAnswer, IPollQuestion } from '~_shared/models';

@Injectable()
export class PollApiService {
  constructor(private backend: BackendService) {}

  getByIds$(ids: string[]) {
    if (ids.length < 10) {
      return this.backend.get<IPollQuestion[]>(`poll/questions/ids`, {
        params: { ids: ids.join(',') },
      });
    } else {
      return this.backend.post<IPollQuestion[]>(`poll/questions/ids`, ids);
    }
  }

  saveAnswers$(answers: IPollAnswer[]) {
    return this.backend.patch<IPollAnswer[]>('poll/answer', answers);
  }

  clearAnswers$(questionIds: string[]) {
    return this.backend.post<IPollAnswer[]>('poll/answers/clear', questionIds);
  }
}
