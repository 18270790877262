import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AnimationsModule } from '~/shared/modules/animations/animations.module';
import { UiModule } from '~/shared/modules/ui/ui.module';
import { coreGuards } from './guards';
import { DrawerModule } from './modules/drawer/drawer.module';
import { DynamicWindowsModule } from './modules/dynamic-windows';
import { FirebaseModule } from './modules/firebase/firebase.module';
import { PushModule } from './modules/push/push.module';
import { AppNgxsModule } from './ngxs/ngxs.module';
import { coreServices } from './services';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FirebaseModule,
    AppNgxsModule,
    DrawerModule,
    DynamicWindowsModule,
    UiModule,
    PushModule,
    AnimationsModule,
  ],
  providers: [...coreServices, ...coreGuards, CurrencyPipe, DatePipe],
  exports: [DrawerModule, DynamicWindowsModule, AnimationsModule],
})
export class CoreModule {}
