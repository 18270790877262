import { IOrder, IOrderStatusWithQuantityChange } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[Order] UpsertMany';
  constructor(public orders: IOrder[], public loadRelated?: boolean) {}
}

export class GetMine {
  static readonly type = '[Order] GetMine';
  constructor() {}
}

export class GetByStores {
  static readonly type = '[Order] GetByStores';
  constructor(public storeIds: string[]) {}
}

export class Create {
  static readonly type = '[Order] Create';
  constructor(public order: IOrder) {}
}

export class Accept {
  static readonly type = '[Order] Accept';
  constructor(public data: IOrderStatusWithQuantityChange[]) {}
}

export class VerifyCode {
  static readonly type = '[Order] VerifyCode';
  constructor(public storeId: string, public code: string) {}
}

export class Collect {
  static readonly type = '[Order] Collect';
  constructor(public data: IOrderStatusWithQuantityChange[], public collectAsUser?: boolean) {}
}

export class Decline {
  static readonly type = '[Order] Decline';
  constructor(public data: IOrderStatusWithQuantityChange[]) {}
}

export class CancelByStore {
  static readonly type = '[Order] CancelByStore';
  constructor(public data: IOrderStatusWithQuantityChange[]) {}
}

export class CancelByUser {
  static readonly type = '[Order] CancelByUser';
  constructor(public data: IOrderStatusWithQuantityChange[]) {}
}

export class LoadRelated {
  static readonly type = '[Order] LoadRelated';
  constructor(public orders: IOrder[]) {}
}
