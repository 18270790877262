import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from '~/shared/modules/modal/modal.module';
import { LoadingFalilureComponent } from './loading-failure.component';
import { LoadingPageComponent } from './loading-page.component';

@NgModule({
  declarations: [LoadingPageComponent, LoadingFalilureComponent],
  imports: [CommonModule, ModalModule],
  exports: [LoadingPageComponent, LoadingFalilureComponent],
})
export class LoadingPageModule {}
