// tslint:disable: no-any
import { IDictionary } from '../interfaces';
import { PushTopic } from '../push-topic.model';

export interface IPushMessage {
  id?: string;
  userId?: string;
  title?: string;
  body?: string;
  imageUrl?: string;
  topic?: PushTopic;
  data?: any;
  condition?: string;
  sendResponseByToken?: IDictionary<IPushMessageResponse>;
  createdAt?: number;
  sentAt?: number;
}

export interface IPushMessageResponse {
  success?: boolean;
  messageId?: string;
  error?: any;
}

export enum PushMessageType {
  StoreOrder = 'storeorder',
  CustomerOrder = 'customerorder',
}
