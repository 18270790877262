<div class="inner-wrapper d-flex align-items-center bg-primary inverted p-1 mb-1">
  <ng-container *ngIf="myUser.user$ | async as me; else notLoggedIn">
    <div class="avatar"><i class="fas fa-user-circle ts-300"></i></div>
    <div class="info flex-fill pl-05 text-ellipsis">
      <h3 class="ts-120 m-0">{{ me.name }}</h3>
      <!-- <button
        (click)="dynamicWindows.open(['user', me.id, 'settings'])"
        class="btn btn-xs btn-white mr-05"
      >
        Hantera konto
      </button> -->
    </div>
    <div>
      <button
        mat-icon-button
        class="opacity-50-hover"
        (click)="dynamicWindows.open(['user', me.id, 'settings'])"
      >
        <i class="fas fa-cog ts-120"></i>
      </button>
    </div>
  </ng-container>
  <ng-template #notLoggedIn>
    <div class="avatar"><i class="fas fa-user-circle ts-300"></i></div>
    <div class="info pl-05 text-ellipsis">
      <h3 class="ts-120 m-0">Förlora inte dina inställningar!</h3>
      <button (click)="dynamicWindows.open('join')" class="btn btn-xs btn-accent">Logga in</button>
    </div>
  </ng-template>
</div>
