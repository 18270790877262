<div class="bg-cover" (click)="onBackdropClicked()"></div>

<div class="modal-box" [ngClass]="'size-' + size" (onResize)="onBoxResize($event)">
  <ng-content></ng-content>
</div>

<div class="close-icon-wrapper" *ngIf="isCloseIconVisible">
  <button
    class="close-icon"
    (click)="onCloseIconClicked()"
    [style.left]="closeIconLeft + 'px'"
    [style.top]="closeIconTop + 'px'"
  >
    <i class="far fa-times"></i>
  </button>
</div>
