import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { BulkItemListHelper, IDictionary } from '~_shared/models';
import { GLOBAL_SETTINGS } from '~_shared/utils/global-settings';
import * as CartItemActions from '../cart-item/cart-item.actions';
import * as CategoryActions from '../category/category.actions';
import * as FilterActions from '../filter/filter.actions';
import * as ItemBodyActions from '../item-body/item-body.actions';
import * as ItemActions from '../item/item.actions';
import * as LocationActions from '../location/location.actions';
import * as OrderActions from '../order/order.actions';
import * as PlaceActions from '../place/place.actions';
import * as PollActions from '../poll/poll.actions';
import * as ShoppingListItemActions from '../shopping-list-item/shopping-list-item.actions';
import * as ShoppingListUserActions from '../shopping-list-user/shopping-list-user.actions';
import * as ShoppingListActions from '../shopping-list/shopping-list.actions';
import * as StoreUserActions from '../store-user/store-user.actions';
import * as StoreActions from '../store/store.actions';
import * as UserEventActions from '../user-event/user-event.actions';
import * as UserFilterActions from '../user-filter/user-filter.actions';
import * as UserActions from '../user/user.actions';
import { IStatusState, StatusUtils } from '../_utils';
import * as BulkActions from './bulk.actions';
import { BulkApiService } from './bulk.service';

export interface IBulkState extends IStatusState {}

@State<IBulkState>({
  name: 'bulk',
  defaults: {
    ...StatusUtils.defaultState(),
  },
})
@Injectable()
export class BulkState {
  constructor(private api: BulkApiService) {}

  @Action(BulkActions.GetLocation)
  async getLocation(
    ctx: StateContext<IBulkState>,
    { locationId, forceLoad }: BulkActions.GetLocation
  ) {
    if (!locationId) {
      return;
    }
    const actionId = `${BulkActions.GetLocation.type}: ${locationId}`;
    if (!forceLoad) {
      // check if location been loaded recently (60s), and if so - abort
      const s = ctx.getState();
      const lastLoaded = s.status?.loaded[actionId];
      if (lastLoaded) {
        const lastLoadedAt = new Date(lastLoaded).getTime();
        const diffInSec = (new Date().getTime() - lastLoadedAt) / 1000;
        if (diffInSec < 60) {
          return;
        }
      }
    }
    const status = new StatusUtils(ctx, BulkActions.GetLocation);
    const statusToken = new StatusUtils(ctx, actionId);
    let error: Error;
    try {
      const bulk = await this.api.getLocation$(locationId).toPromise();
      // first patch entities that don't load relateds...
      await Promise.all([
        ctx.dispatch(new StoreActions.UpsertMany(bulk.stores, locationId)).toPromise(),
        ctx.dispatch(new LocationActions.UpsertMany([bulk.location])).toPromise(),
        ctx.dispatch(new PlaceActions.UpsertMany([bulk.place])).toPromise(),
      ]);
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
    statusToken.setLoading(false).setError(error);
  }

  @Action(BulkActions.GetItemsByQuery)
  async getItemsByQuery(
    ctx: StateContext<IBulkState>,
    { query, forceLoad }: BulkActions.GetItemsByQuery
  ) {
    if (!query?.locationId) {
      return;
    }
    const queryHash = BulkItemListHelper.queryHashId(query);
    const actionId = `${BulkActions.GetItemsByQuery.type}: ${queryHash}`;
    if (!forceLoad) {
      // check if bulk has been loaded recently (60s), and if so - abort
      const s = ctx.getState();
      const lastLoaded = s.status?.loaded[actionId];
      if (lastLoaded) {
        const lastLoadedAt = new Date(lastLoaded).getTime();
        const diffInSec = (new Date().getTime() - lastLoadedAt) / 1000;
        if (diffInSec < 60) {
          return;
        }
      }
    }
    const status = new StatusUtils(ctx, BulkActions.GetItemsByQuery);
    const statusToken = new StatusUtils(ctx, actionId);
    let error: Error;
    try {
      const bulk = await this.api.getItemsByQuery$(query).toPromise();
      await Promise.all([
        ctx.dispatch(new ItemBodyActions.UpsertMany(bulk.itemBodies)).toPromise(),
        ctx.dispatch(new ItemActions.UpsertMany(bulk.items)).toPromise(),
        ctx.dispatch(new ItemActions.SetIdsByStoresHash(bulk.itemIdsByStoresHash)).toPromise(),
      ]);
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
    statusToken.setLoading(false).setError(error);
  }

  @Action(BulkActions.GetItemsByIds)
  async getItemsByIds(
    ctx: StateContext<IBulkState>,
    { itemIds, affectedStoreIds, forceLoad }: BulkActions.GetItemsByIds
  ) {
    if (!itemIds?.length) {
      return;
    }
    const storeIds = affectedStoreIds || [];
    const idsHash = itemIds.join(';');
    const actionId = `${BulkActions.GetItemsByIds.type}: ${idsHash}`;
    if (!forceLoad) {
      // check if location been loaded recently (60s), and if so - abort
      const s = ctx.getState();
      const lastLoaded = s.status?.loaded[actionId];
      if (lastLoaded) {
        const lastLoadedAt = new Date(lastLoaded).getTime();
        const diffInSec = (new Date().getTime() - lastLoadedAt) / 1000;
        if (diffInSec < 60) {
          return;
        }
      }
    }
    // track status for 3 types; general action + all item ids + individual store id
    const status = new StatusUtils(ctx, BulkActions.GetItemsByIds);
    const statusIds = new StatusUtils(ctx, actionId);
    const statusStores = storeIds.map(
      (storeId) => new StatusUtils(ctx, `${BulkActions.GetItemsByIds.type}:store: ${storeId}`)
    );
    let error: Error;
    try {
      const bulk = await this.api.getItemsByIds$(itemIds).toPromise();
      await Promise.all([
        ctx.dispatch(new ItemBodyActions.UpsertMany(bulk.itemBodies)).toPromise(),
        ctx.dispatch(new ItemActions.UpsertMany(bulk.items)).toPromise(),
        // ctx.dispatch(new ItemActions.SetIdsByStoresHash(bulk.itemIdsByStoresHash)).toPromise(),
      ]);
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
    statusIds.setLoading(false).setError(error);
    statusStores.forEach((s) => s.setLoading(false).setError(error));
  }

  @Action(BulkActions.GetItemsByStoreAdmin)
  async getItemsByStoreAdmin(
    ctx: StateContext<IBulkState>,
    { storeId, forceLoad }: BulkActions.GetItemsByStoreAdmin
  ) {
    const actionId = `${BulkActions.GetItemsByStoreAdmin.type}: ${storeId}`;
    if (!forceLoad) {
      // check if location been loaded recently (600s=10min), and if so - abort
      const s = ctx.getState();
      const lastLoaded = s.status?.loaded[actionId];
      if (lastLoaded) {
        const lastLoadedAt = new Date(lastLoaded).getTime();
        const diffInSec = (new Date().getTime() - lastLoadedAt) / 1000;
        if (diffInSec < 600) {
          return;
        }
      }
    }
    const status = new StatusUtils(ctx, BulkActions.GetItemsByStoreAdmin);
    const statusIds = new StatusUtils(ctx, actionId);
    let error: Error;
    try {
      const bulk = await this.api.getItemsByStoreAdmin$(storeId).toPromise();
      await Promise.all([
        ctx.dispatch(new ItemBodyActions.UpsertMany(bulk.itemBodies)).toPromise(),
        ctx.dispatch(new ItemActions.UpsertMany(bulk.items)).toPromise(),
        ctx.dispatch(new ItemActions.SetIdsByStoresHash(bulk.itemIdsByStoresHash)).toPromise(),
      ]);
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
    statusIds.setLoading(false).setError(error);
  }

  @Action(BulkActions.GetSystem)
  async getSystem(ctx: StateContext<IBulkState>, {}: BulkActions.GetSystem) {
    const status = new StatusUtils(ctx, BulkActions.GetSystem);
    let error: Error;
    try {
      const bulk = await this.api.getSystem$().toPromise();
      await ctx.dispatch(new FilterActions.UpsertMany(bulk.filters)).toPromise();
      await ctx.dispatch(new CategoryActions.UpsertMany(bulk.categories)).toPromise();
      // simply merge fetched global settings with memory/file
      Object.assign(GLOBAL_SETTINGS, bulk.globalSettings || {});
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(BulkActions.GetUser)
  async getUser(ctx: StateContext<IBulkState>, { token, forceLoad }: BulkActions.GetUser) {
    if (!token) {
      return;
    }
    const actionId = `${BulkActions.GetUser.type}: ${token}`;
    if (!forceLoad) {
      // check if location been loaded recently (60s), and if so - abort
      const s = ctx.getState();
      const lastLoaded = s.status?.loaded[actionId];
      if (lastLoaded) {
        const lastLoadedAt = new Date(lastLoaded).getTime();
        const diffInSec = (new Date().getTime() - lastLoadedAt) / 1000;
        if (diffInSec < 60) {
          return;
        }
      }
    }
    const status = new StatusUtils(ctx, BulkActions.GetUser);
    const statusToken = new StatusUtils(ctx, actionId);
    let error: Error;
    try {
      const bulk = await this.api.getUser$().toPromise();
      const users = [...bulk.users, bulk.user].filter((u) => !!u);
      // first patch entities that don't load relateds...
      await Promise.all([
        ctx.dispatch(new ItemBodyActions.UpsertMany(bulk.itemBodies)).toPromise(),
        ctx.dispatch(new StoreActions.UpsertMany(bulk.stores)).toPromise(),
        ctx.dispatch(new LocationActions.UpsertMany(bulk.locations)).toPromise(),
        ctx.dispatch(new UserActions.UpsertMany(users)).toPromise(),
        ctx.dispatch(new UserEventActions.UpsertMany(bulk.userEvents)).toPromise(),
        ctx.dispatch(new UserFilterActions.UpsertMany(bulk.userFilters)).toPromise(),
        ctx.dispatch(new ItemActions.UpsertMany(bulk.items)).toPromise(),
        ctx.dispatch(new PlaceActions.UpsertMany(bulk.places)).toPromise(),
        ctx.dispatch(new CartItemActions.UpsertMany(bulk.cartItems)).toPromise(),
        ctx.dispatch(new OrderActions.UpsertMany(bulk.orders)).toPromise(),
        ctx.dispatch(new StoreUserActions.UpsertMany(bulk.storeUsers)).toPromise(),
        ctx.dispatch(new PollActions.UpsertMany(bulk.pollQuestions)).toPromise(),
        ctx.dispatch(new PollActions.UpsertAnswers(bulk.pollAnswers)).toPromise(),
        ctx.dispatch(new ShoppingListActions.UpsertMany(bulk.shoppingLists)).toPromise(),
        ctx.dispatch(new ShoppingListItemActions.UpsertMany(bulk.shoppingListItems)).toPromise(),
        ctx.dispatch(new ShoppingListUserActions.UpsertMany(bulk.shoppingListUsers)).toPromise(),
      ]);
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
    statusToken.setLoading(false).setError(error);
  }

  @Action(BulkActions.ClearStatus)
  async clearStatus(
    ctx: StateContext<IBulkState>,
    { actionIdStartsWith }: BulkActions.ClearStatus
  ) {
    const status = { ...ctx.getState().status };
    const actionIds =
      typeof actionIdStartsWith === 'string' ? [actionIdStartsWith] : actionIdStartsWith;
    const clearDictFn = (dict: IDictionary<unknown>) =>
      Object.keys(dict || {}).reduce((newDict, key) => {
        const hasMatch = actionIds.some((actionId) => key.startsWith(actionId));
        if (!hasMatch) {
          newDict[key] = dict[key];
        }
        return newDict;
      }, {});
    status.loaded = clearDictFn(status.loaded);
    status.loadings = clearDictFn(status.loadings);
    status.errors = clearDictFn(status.errors);
    ctx.patchState({ status });
  }
}
