import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IOrder, IOrderStatusWithQuantityChange } from '~_shared/models';

@Injectable()
export class OrderApiService {
  constructor(private backend: BackendService) {}

  getMine$() {
    return this.backend.get<IOrder[]>(`orders`);
  }
  getByStore$(storeId: string) {
    return this.backend.get<IOrder[]>(`orders/store/${storeId}`);
  }

  create$(order: IOrder) {
    return this.backend.post<IOrder>(`orders`, order);
  }

  accept$(data: IOrderStatusWithQuantityChange[]) {
    return this.backend.post<IOrder[]>(`orders/accept`, data);
  }
  verifyCode$(storeId: string, code: string) {
    return this.backend.post<IOrder>(`orders/${storeId}/verify-code`, { code });
  }
  collect$(data: IOrderStatusWithQuantityChange[], collectAsUser?: boolean) {
    return this.backend.post<IOrder[]>(collectAsUser ? 'orders/collect' : 'orders/deliver', data);
  }
  decline$(data: IOrderStatusWithQuantityChange[]) {
    return this.backend.post<IOrder[]>(`orders/decline`, data);
  }
  cancelByStore$(data: IOrderStatusWithQuantityChange[]) {
    return this.backend.post<IOrder[]>(`orders/cancel-store`, data);
  }
  cancelByUser$(data: IOrderStatusWithQuantityChange[]) {
    return this.backend.post<IOrder[]>(`orders/cancel-user`, data);
  }
}
