import * as MeActions from './me.actions';
import { MeSelectors } from './me.selectors';
import { MeApiService } from './me.service';
import { MeState } from './me.state';

export const Me = {
  state: MeState,
  actions: MeActions,
  select: MeSelectors,
  api: MeApiService,
};
