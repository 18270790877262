import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IUserFilter } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as UserFilterActions from './user-filter.actions';
import { UserFilterApiService } from './user-filter.service';

export interface IUserFilterState extends IEntityState<IUserFilter>, IStatusState {}

@State<IUserFilterState>({
  name: 'userFilter',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class UserFilterState {
  constructor(private api: UserFilterApiService) {}

  @Action(UserFilterActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IUserFilterState>,
    { userFilters }: UserFilterActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, UserFilterActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), userFilters));
    status.setLoading(false);
  }

  @Action(UserFilterActions.GetMine)
  async getMine(ctx: StateContext<IUserFilterState>, {}: UserFilterActions.GetMine) {
    const status = new StatusUtils(ctx, UserFilterActions.GetMine);
    let error: Error;
    try {
      const userFilters = await this.api.getMine$().toPromise();
      await ctx.dispatch(new UserFilterActions.UpsertMany(userFilters)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(UserFilterActions.Save)
  async save(ctx: StateContext<IUserFilterState>, { userFilter }: UserFilterActions.Save) {
    const status = new StatusUtils(ctx, UserFilterActions.Save);
    let error: Error;
    try {
      await ctx.dispatch(new UserFilterActions.UpsertMany([userFilter])).toPromise();
      await this.api.save$(userFilter).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(UserFilterActions.Remove)
  async remove(ctx: StateContext<IUserFilterState>, { userFilters }: UserFilterActions.Remove) {
    const status = new StatusUtils(ctx, UserFilterActions.Remove);
    let error: Error;
    try {
      ctx.patchState(EntityUtils.removeMany(ctx.getState(), userFilters));
      const userFilterIds = userFilters.map((ci) => ci.id);
      this.api.removeByIds$(userFilterIds).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }
}
