import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';
import { BehaviorSubject, combineLatest, interval, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import {
  ITalkEventLoginResult,
  ITalkEventNativeHello,
  IUserUpdate,
  NativeToWebEvent,
  PushMessageType,
  UserEventTimeType,
  WebToNativeEvent,
} from '~_shared/models';
import {
  getRequireEmailVerification,
  isEqualObj,
  isUserAdmin,
  isUserAnonymous,
} from '~_shared/utils/misc.utils';
import { DynamicWindowsService } from '../modules/dynamic-windows';
import { PushService } from '../modules/push/push.service';
import { UserEventService } from '../modules/user-event/user-event.service';
import { NGXS } from '../ngxs';
import { MeApiService } from '../ngxs/me/me.service';
import { BackendService } from './backend.service';
import { NativeTalkerService } from './native-talker.service';
import { WINDOW } from './window.service';

@Injectable()
export class MyUserService {
  private _userId$ = new BehaviorSubject<string>(null);
  readonly userId$ = this._userId$.asObservable();
  get userId() {
    return this._userId$.value;
  }

  readonly token$ = this.ngxs.select(NGXS.Me.select.token);
  readonly authRaw$ = this.ngxs.select(NGXS.Me.select.auth);
  readonly auth$ = this.authRaw$.pipe(filter((me) => me && !isUserAnonymous(me)));
  readonly user$ = this.ngxs.select(NGXS.Me.select.user);
  readonly isKnown$ = this.ngxs.select(NGXS.Me.select.isKnown);
  readonly isAnonymous$ = this.isKnown$.pipe(map((x) => !x));
  readonly stores$ = this.ngxs.select(NGXS.Me.select.stores);
  readonly pushToken$ = this.ngxs.select(NGXS.Me.select.pushToken);
  readonly pushActivated$ = combineLatest([this.push.isPermissionGranted$, this.pushToken$]).pipe(
    map(([granted, token]) => token && granted)
  );

  readonly nativeInfo$ = new BehaviorSubject<ITalkEventNativeHello>(null);
  readonly isNative$ = this.nativeInfo$.pipe(map((x) => !!x));

  readonly requireEmailVerification$ = this.auth$.pipe(map((x) => getRequireEmailVerification(x)));

  readonly lockedReason$ = combineLatest([this.requireEmailVerification$]).pipe(
    map(([reqEmailVerify]) => {
      if (reqEmailVerify && reqEmailVerify.daysUntilLock <= 0) {
        return 'RequireEmailVerification';
      }
    })
  );
  readonly isLocked$ = this.lockedReason$.pipe(map((x) => !!x));

  readonly isAdmin$ = this.userId$.pipe(map((userId) => isUserAdmin(userId)));
  get isAdmin() {
    return isUserAdmin(this.userId);
  }

  readonly isStoreUser$ = this.stores$.pipe(map((stores) => !!stores?.length));
  readonly isStoreAdmin$ = combineLatest([this.isAdmin$, this.isStoreUser$]).pipe(
    map((xx) => xx.some((x) => x))
  );

  constructor(
    private ngxs: Store,
    private backend: BackendService,
    private meApi: MeApiService,
    private fireAuth: AngularFireAuth,
    private fireDb: AngularFirestore,
    private push: PushService,
    private router: Router,
    private dynamicWindows: DynamicWindowsService,
    @Inject(WINDOW) private window: Window,
    private nativeTalker: NativeTalkerService,
    public event: UserEventService
  ) {}

  init() {
    this.trackNative();
    this.trackFirebaseUser();
    this.trackPushToken();
    this.trackPushMessages();
    this.trackUpdates();
  }

  async setAuth(authObj: firebase.User) {
    this._userId$.next(authObj?.uid);
    await this.ngxs.dispatch(new NGXS.Me.actions.SetAuth(authObj)).toPromise();
    return this.ngxs.select(NGXS.Me.select.auth).pipe(first()).toPromise();
  }

  async refreshAuth() {
    const auth = await this.fireAuth.currentUser;
    return this.setAuth(auth);
  }

  private trackNative() {
    this.nativeTalker
      .listenTo$<ITalkEventNativeHello>(NativeToWebEvent.NATIVE_HELLO)
      .subscribe((info) => {
        this.nativeInfo$.next(info);
        this.nativeTalker.talk(WebToNativeEvent.WEB_HELLO, {});
      });
    this.nativeTalker.talk(WebToNativeEvent.REQUIRE_HELLO);

    this.nativeTalker.listenTo$(NativeToWebEvent.LOGGED_OUT).subscribe(() => this.logout());

    this.nativeTalker
      .listenTo$<ITalkEventLoginResult>(NativeToWebEvent.LOGGED_IN)
      .subscribe(async (r) => {
        try {
          await this.meApi.loginWebByNativeResult(r);
        } catch (err) {
          // invalid token? invalid email? invalid something = logout and start over!
          await this.logout();
        }
      });
  }

  private trackFirebaseUser() {
    this.fireAuth.setPersistence('local');

    this.fireAuth.idToken.subscribe((token) => {
      this.ngxs.dispatch(new NGXS.Me.actions.SetToken(token));
    });

    // whenever token changes; setAuthBearer + get user bulk
    this.token$.pipe(distinctUntilChanged()).subscribe(async (token) => {
      this.backend.setAuthBearer(token);
    });

    // track new user info
    combineLatest([this.fireAuth.user, this.authRaw$])
      .pipe(filter(([fireUser, currUser]) => fireUser && !isEqualObj(fireUser?.toJSON(), currUser)))
      .subscribe(([fireUser]) => {
        this.setAuth(fireUser);
      });

    // check emailVerified every 30th sec, when needed
    combineLatest([this.isKnown$, this.requireEmailVerification$, interval(30000)])
      .pipe(takeWhile(([isKnown, reqEmail]) => !!(isKnown && reqEmail)))
      .subscribe(() => this.checkEmailVerification());
  }

  private trackUpdates() {
    this.auth$
      .pipe(
        switchMap((auth) =>
          !auth?.uid
            ? of(null)
            : this.fireDb.collection('userUpdates').doc<IUserUpdate>(auth.uid).valueChanges()
        ),
        map((data) => data || ({} as IUserUpdate))
      )
      .subscribe((updates) => {
        this.ngxs.dispatch(new NGXS.Me.actions.SetUserUpdates(updates));
      });

    // take actions on certain updates
    this.getUpdates$().subscribe(async (updates) => {
      if (updates[UserEventTimeType.ShowOrderStatus]) {
        this.ngxs.dispatch(new NGXS.Order.actions.GetMine());
      }
      if (updates[UserEventTimeType.ShowStoreOrderStatus]) {
        const storeIds = await this.ngxs.selectOnce(NGXS.Me.select.storeIds).toPromise();
        this.ngxs.dispatch(new NGXS.Order.actions.GetByStores(storeIds));
      }
    });
  }

  getUpdates$(eventTypes?: UserEventTimeType[]) {
    return this.ngxs.select(NGXS.Me.select.updates).pipe(
      startWith({} as IUserUpdate),
      pairwise(),
      map(([prev, next]) => {
        const changedUpdates = Object.keys(next).reduce<IUserUpdate>((changes, key) => {
          const isNew = !prev[key] || next[key] > prev[key];
          const isType = !eventTypes || eventTypes.some((x) => x + '' === key);
          if (isNew && isType) {
            changes[key] = next[key];
          }
          return changes;
        }, {});
        return changedUpdates;
      })
    );
  }

  private trackPushToken() {
    combineLatest([this.push.getToken$(), this.userId$])
      .pipe(
        filter(([pushToken, userId]) => !!(pushToken && userId)),
        distinctUntilChanged(isEqualObj)
      )
      .subscribe(([pushToken]) => {
        this.ngxs.dispatch(new NGXS.Me.actions.PushTokenUpdate(pushToken));
      });
  }

  private trackPushMessages() {
    this.push.messages$.subscribe(async (pm) => {
      if (pm.foreground) {
        // message received while app was open
        // TODO: show in-app message?
        return;
      }
      // (foreground=false) = user clicked notification = take actions
      switch (pm.data?.type) {
        case PushMessageType.CustomerOrder: {
          await this.router.navigateByUrl('/$location/dibs');
          if (pm.data.orderId) {
            this.dynamicWindows.open(['dibs/details/user', pm.data.orderId]);
          }
          break;
        }
        case PushMessageType.StoreOrder: {
          if (pm.data.storeId) {
            await this.router.navigate(['admin/store', pm.data.storeId]);
          }
          if (pm.data.orderId) {
            this.dynamicWindows.open(['dibs/details/store', pm.data.orderId]);
          }
          break;
        }
      }
    });
  }

  getPushToken$(requestPermissionIfNotGranted?: boolean) {
    return this.push.getToken$(requestPermissionIfNotGranted);
  }

  async logout() {
    this.nativeTalker.talk(WebToNativeEvent.LOGOUT);

    await this.fireAuth.signOut();
    await this.token$.pipe(first((t) => !t)).toPromise();
    await this.ngxs.dispatch(new StateResetAll()).toPromise();
    this.window.location.replace('/');
  }

  async checkEmailVerification() {
    const auth = await this.fireAuth.currentUser;
    await auth.reload();
    if (auth.emailVerified) {
      this.setAuth(auth);
    }
  }
}
