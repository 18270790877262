import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { BackendService } from '~/core/services/backend.service';
import { NativeTalkerService } from '~/core/services/native-talker.service';
import {
  ILinkAccountsByCredentialInput,
  IPhoneNumberStatus,
  ITalkEventLoginResult,
  IUser,
  IUserJoin,
  NativeToWebEvent,
  PhoneNumberStatus,
  WebToNativeEvent,
} from '~_shared/models';
import { cleanTelephone, isUserAnonymous } from '~_shared/utils/misc.utils';

@Injectable()
export class MeApiService {
  constructor(
    private fireAuth: AngularFireAuth,
    private backend: BackendService,
    private nativeTalker: NativeTalkerService
  ) {}

  async loginPassword(login: IUserJoin): Promise<firebase.auth.UserCredential> {
    const isNative = this.nativeTalker.hasTalker;
    return isNative ? this.loginPasswordByNative(login) : this.loginPasswordByWeb(login);
  }
  async loginFacebook(): Promise<firebase.auth.UserCredential> {
    const isNative = this.nativeTalker.hasTalker;
    return isNative ? this.loginFacebookByNative() : this.loginFacebookByWeb();
  }
  async loginGoogle(): Promise<firebase.auth.UserCredential> {
    const isNative = this.nativeTalker.hasTalker;
    return isNative ? this.loginGoogleByNative() : this.loginGoogleByWeb();
  }
  async loginApple(): Promise<firebase.auth.UserCredential> {
    const isNative = this.nativeTalker.hasTalker;
    return isNative ? this.loginAppleByNative() : this.loginAppleByWeb();
  }
  async createAnonymous(): Promise<firebase.auth.UserCredential> {
    const isNative = this.nativeTalker.hasTalker;
    return isNative ? this.createAnonymousByNative() : this.createAnonymousByWeb();
  }

  async loginPasswordByWeb(login: IUserJoin): Promise<firebase.auth.UserCredential> {
    return this.fireAuth.signInWithEmailAndPassword(login.email, login.password); // catch and link?
  }

  async loginFacebookByWeb(): Promise<firebase.auth.UserCredential> {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    return this.fireAuth.signInWithPopup(facebookProvider).catch(async (err) => {
      if (err?.code === 'auth/account-exists-with-different-credential') {
        return this.linkAccountByCredentialAndCustomToken(err);
      }
      throw err;
    });
  }

  async loginGoogleByWeb(): Promise<firebase.auth.UserCredential> {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    return this.fireAuth.signInWithPopup(googleProvider).catch(async (err) => {
      if (err?.code === 'auth/account-exists-with-different-credential') {
        return this.linkAccountByCredentialAndCustomToken(err);
      }
      throw err;
    });
  }

  async loginAppleByWeb(): Promise<firebase.auth.UserCredential> {
    // window.alert('Not supported');
    throw new Error('Not supported');
  }

  async createAnonymousByWeb(): Promise<firebase.auth.UserCredential> {
    return this.fireAuth.signInAnonymously();
  }

  async loginPasswordByNative(login: IUserJoin): Promise<firebase.auth.UserCredential> {
    this.nativeTalker.talk(WebToNativeEvent.LOGIN_PASSWORD, login);
    const r = await this.nativeTalker
      .listenOnce$<ITalkEventLoginResult>(NativeToWebEvent.LOGIN_PASSWORD_RESULT)
      .toPromise();
    if (!r.user || !r.token) {
      throw r.error ?? new Error('Unknown error');
    }
    return this.loginWebByNativeResult(r);
  }

  async loginFacebookByNative(): Promise<firebase.auth.UserCredential> {
    this.nativeTalker.talk(WebToNativeEvent.LOGIN_FACEBOOK, {});
    const r = await this.nativeTalker
      .listenOnce$<ITalkEventLoginResult>(NativeToWebEvent.LOGIN_FACEBOOK_RESULT)
      .toPromise();
    if (!r.user || !r.token) {
      throw r.error ?? new Error('Unknown error');
    }
    return this.loginWebByNativeResult(r);
  }

  async loginGoogleByNative(): Promise<firebase.auth.UserCredential> {
    this.nativeTalker.talk(WebToNativeEvent.LOGIN_GOOGLE, {});
    const r = await this.nativeTalker
      .listenOnce$<ITalkEventLoginResult>(NativeToWebEvent.LOGIN_GOOGLE_RESULT)
      .toPromise();
    if (!r.user || !r.token) {
      throw r.error ?? new Error('Unknown error');
    }
    return this.loginWebByNativeResult(r);
  }

  async loginAppleByNative(): Promise<firebase.auth.UserCredential> {
    this.nativeTalker.talk(WebToNativeEvent.LOGIN_APPLE, {});
    const r = await this.nativeTalker
      .listenOnce$<ITalkEventLoginResult>(NativeToWebEvent.LOGIN_APPLE_RESULT)
      .toPromise();
    if (!r.user || !r.token) {
      throw r.error ?? new Error('Unknown error');
    }
    return this.loginWebByNativeResult(r);
  }

  async createAnonymousByNative(): Promise<firebase.auth.UserCredential> {
    this.nativeTalker.talk(WebToNativeEvent.LOGIN_ANONYMOUS, {});
    const r = await this.nativeTalker
      .listenOnce$<ITalkEventLoginResult>(NativeToWebEvent.LOGIN_ANONYMOUS_RESULT)
      .toPromise();
    if (!r.user || !r.token) {
      throw r.error ?? new Error('Unknown error');
    }
    return this.loginWebByNativeResult(r);
  }

  async loginWebByNativeResult(r: ITalkEventLoginResult): Promise<firebase.auth.UserCredential> {
    const token = r?.token;
    const email = r?.user?.email;
    const customToken = await this.backend
      .post<string>('users/login/custom-token/token', { token, email })
      .toPromise();
    return this.fireAuth.signInWithCustomToken(customToken);
  }

  async resetPassword(email: string): Promise<'sent' | 'error-invalid'> {
    const user = await this.findByEmail(email);
    if (user) {
      await this.fireAuth.sendPasswordResetEmail(email);
      return 'sent';
    }
    return 'error-invalid';
  }

  /**
   * Merge anonymous user data with newly logged in/created user
   */
  migrateAnonymous(oldToken: string, newToken: string) {
    return this.backend.post(`users/migrate`, { oldToken, newToken }).toPromise();
  }

  ensureUser() {
    return this.backend.patch<IUser>(`users/ensure`, {}).toPromise();
  }

  async createUser(newUser: IUserJoin): Promise<firebase.User> {
    const currUser = await this.fireAuth.currentUser;
    if (currUser && !isUserAnonymous(currUser)) {
      console.warn('[Me] CreateUser: Already user - abort');
      return currUser;
    }

    let userCred: firebase.auth.UserCredential;

    if (currUser) {
      const cred = firebase.auth.EmailAuthProvider.credential(newUser.email, newUser.password);
      userCred = await currUser.linkWithCredential(cred);
    } else {
      userCred = await this.fireAuth.createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
      );
    }
    await userCred.user.updateProfile({ displayName: newUser.name });
    await userCred.user.sendEmailVerification();
    return userCred.user;
  }

  getMe() {
    return this.fireAuth.user.toPromise();
  }

  findByEmail(email: string) {
    return this.backend
      .post<IUserJoin>(`users/search/email`, { email })
      .toPromise();
  }

  pushTokenUpdate(newToken: string, oldToken?: string) {
    return this.backend
      .patch<string>(`push/subscribe`, { newToken, oldToken })
      .toPromise();
  }

  async linkAccountByCredentialAndCustomToken(
    input: ILinkAccountsByCredentialInput
  ): Promise<firebase.auth.UserCredential> {
    // input may contain .toJSON() which re-transform the data,
    // this makes sure the props stays the same:
    const pendingCred = input.credential as firebase.auth.AuthCredential;
    // 1) Validate new provider credentials
    // 2) Get custom token to temporary login
    // 3) Link accounts
    const email = input.email;
    const providerId = input.credential?.providerId;
    const providerToken = input.credential?.oauthAccessToken || input.credential?.accessToken;
    const customToken = await this.backend
      .post<string>('users/login/custom-token/provider', { email, providerId, providerToken })
      .toPromise();
    const tempCred = await this.fireAuth.signInWithCustomToken(customToken);
    const linkCred = await tempCred.user.linkWithCredential(pendingCred);

    return linkCred;
  }

  async linkAccountByCredential(credential: firebase.auth.AuthCredential) {
    const currentUser = await this.fireAuth.currentUser;
    if (!currentUser) {
      console.error('[linkAccountByCredential] No current user');
      return;
    }
    return currentUser.linkWithCredential(credential);
  }

  async linkPhone(credential: firebase.auth.PhoneAuthCredential) {
    const currentUser = await this.fireAuth.currentUser;
    if (!currentUser) {
      console.error('[linkAccountByCredential] No current user');
      return;
    }
    try {
      await currentUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID);
    } catch {}
    return currentUser.linkWithCredential(credential);
  }

  async unlinkPhone(tel: string) {
    const currentUser = await this.fireAuth.currentUser;
    if (!currentUser) {
      console.error('[linkAccountByCredential] No current user');
      return;
    }
    return this.backend
      .post<boolean>('users/phone/unlink', { tel })
      .toPromise();
  }

  async getPhoneNumberStatus(telnr: string) {
    const tel = cleanTelephone(telnr);
    const r = await this.backend
      .post<IPhoneNumberStatus>(`users/phone/status`, { tel })
      .toPromise();
    return r?.status ?? PhoneNumberStatus.NotVerified;
  }
}
