import { Component } from '@angular/core';
import { DrawerService } from '../drawer.service';

@Component({
  selector: 'kd-drawer-wrapper',
  templateUrl: './drawer-wrapper.component.html',
  styleUrls: ['./drawer-wrapper.component.scss'],
})
export class DrawerWrapperComponent {
  constructor(public drawer: DrawerService) {}

  onOpenedChange(open: boolean) {
    this.drawer.toggle(open);
    if (open) {
      this.drawer.closeOnNextNav();
    }
  }
}
