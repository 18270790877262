import { Pipe, PipeTransform } from '@angular/core';
import { IItem, ItemHelper } from '~_shared/models';

@Pipe({
  name: 'discount',
})
export class DiscountPipe implements PipeTransform {
  transform(it: IItem, ...args: unknown[]): number {
    return ItemHelper.discount(it);
  }
}
