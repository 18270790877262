import { IDictionary } from '../interfaces';
import { IFilter, IFilterChain } from './filter.model';
import { IItem, ItemHelper } from './item.model';

export interface ICategory {
  id?: string;
  title: string;
  titleAliases?: string[];
  filterId?: string;
  filterByChildren?: boolean;
  parentId?: string;
  sortIndex?: number;
  isHidden?: boolean;
}

export interface ICategoryTreeItem extends ICategory {
  parent: () => ICategoryTreeItem | undefined;
  children: () => ICategoryTreeItem[];
}

export class CategoryHelper {
  static getChildren(catsByIds?: IDictionary<ICategory>, parentId?: string) {
    return Object.values(catsByIds || {}).filter((c) => c.parentId === parentId);
  }

  static getTreeByParent(
    catsByIds?: IDictionary<ICategory>,
    parentTreeItem?: ICategoryTreeItem
  ): ICategoryTreeItem[] {
    const parentId = parentTreeItem?.id;

    catsByIds = catsByIds || {};
    const tree: ICategoryTreeItem[] = [];
    for (const cat of Object.values(catsByIds)) {
      if (cat.parentId === parentId && cat.parentId !== cat.id) {
        const parent = () => parentTreeItem;
        const treeItem: ICategoryTreeItem = { ...cat, parent, children: () => [] };
        const children = () => this.getTreeByParent(catsByIds, treeItem);
        treeItem.children = children;
        tree.push(treeItem);
      }
    }
    return tree;
  }

  static getTreeById(
    catsByIds?: IDictionary<ICategory>,
    catId?: string
  ): ICategoryTreeItem | undefined {
    const findInTree = (tree: ICategoryTreeItem[]): ICategoryTreeItem | undefined => {
      for (const cat of tree || []) {
        if (cat.id === catId) {
          return cat;
        }
        const children = cat.children();
        if (children.length) {
          const foundInChildren = findInTree(children);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return;
    };

    const rootTree = this.getTreeByParent(catsByIds);
    if (rootTree?.length) {
      return findInTree(rootTree);
    }
    return;
  }

  static getFilterIdsByChildren(catTree?: ICategoryTreeItem): string[] {
    const filterIds: string[] = [];
    if (!catTree) {
      return filterIds;
    }
    if (catTree.filterId) {
      filterIds.push(catTree.filterId);
    }
    if (catTree.filterByChildren) {
      catTree.children().forEach((subCatTree) => {
        const subFilterIds = this.getFilterIdsByChildren(subCatTree);
        subFilterIds.forEach((id) => filterIds.push(id));
      });
    }
    return filterIds;
  }

  static filterItems(
    items: IItem[],
    cats: ICategory[],
    catsByIds: IDictionary<ICategory>,
    filtersByIds: IDictionary<IFilter>
  ) {
    const filterChains: IFilterChain[] = [];
    for (const c of cats) {
      const filterIds: string[] = [];

      if (c.filterId) {
        filterIds.push(c.filterId);
      }

      if (c.filterByChildren) {
        const catTree = this.getTreeById(catsByIds, c.id);
        this.getFilterIdsByChildren(catTree).forEach((fid) => filterIds.push(fid));
      }

      for (const filterId of filterIds) {
        filterChains.push({
          op: 'OR',
          filterId,
        });
      }
    }
    const catsFilter: IFilter = { filterChains };
    const filteredItems = items.filter((it) =>
      ItemHelper.isMatchByFilter(it, catsFilter, filtersByIds)
    );
    return filteredItems;
  }
}
