import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ILatLng, ILocation } from '~_shared/models';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as LocationActions from './location.actions';
import { LocationApiService } from './location.service';

export interface ILocationFoundByPositionState {
  id: string;
  pos: ILatLng;
}

export interface ILocationState extends IEntityState<ILocation>, IStatusState {
  foundBySearchPos: ILocationFoundByPositionState;
  idsBySearchQuery: { [searchQuery: string]: string[] };
}

@State<ILocationState>({
  name: 'location',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
    foundBySearchPos: {
      id: null,
      pos: null,
    },
    idsBySearchQuery: {},
  },
})
@Injectable()
export class LocationState {
  constructor(private api: LocationApiService) {}

  @Action(LocationActions.UpsertMany)
  async upsertMany(ctx: StateContext<ILocationState>, { locations }: LocationActions.UpsertMany) {
    const status = new StatusUtils(ctx, LocationActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), locations));
    status.setLoading(false);
  }

  @Action(LocationActions.GetById)
  async getById(ctx: StateContext<ILocationState>, { id }: LocationActions.GetById) {
    const status = new StatusUtils(ctx, LocationActions.GetById);
    const s = ctx.getState();
    try {
      const exists = !!s.entities[id];
      if (!exists) {
        const location = await this.api.getById$(id).toPromise();
        await ctx.dispatch(new LocationActions.UpsertMany([location])).toPromise();
      }
    } catch (err) {
      status.setError(err);
    }
    status.setLoadedIds([id]).setLoading(false);
  }

  @Action(LocationActions.GetByIds)
  async getByIds(ctx: StateContext<ILocationState>, { ids }: LocationActions.GetByIds) {
    const status = new StatusUtils(ctx, LocationActions.GetByIds);
    const s = ctx.getState();
    const nonExistingIds = ids.filter((id) => id && !s.entities[id]);
    if (nonExistingIds.length) {
      try {
        const locations = await this.api.getByIds$(nonExistingIds).toPromise();
        await ctx.dispatch(new LocationActions.UpsertMany(locations)).toPromise();
      } catch (err) {
        status.setError(err);
      }
    }
    status.setLoadedIds(ids).setLoading(false);
  }

  @Action(LocationActions.GetBySearchPos)
  async getBySearchPos(ctx: StateContext<ILocationState>, { pos }: LocationActions.GetBySearchPos) {
    const status = new StatusUtils(ctx, LocationActions.GetBySearchPos);
    try {
      const location = await this.api.getBySearchPos$(pos).toPromise();
      await ctx.dispatch(new LocationActions.UpsertMany([location])).toPromise();
      ctx.patchState({
        foundBySearchPos: {
          id: location?.id,
          pos,
        },
      });
    } catch (err) {
      status.setError(err);
    }
    status.setLoading(false);
  }

  @Action(LocationActions.GetBySearchZip)
  async getBySearchZip(
    ctx: StateContext<ILocationState>,
    { searchText }: LocationActions.GetBySearchZip
  ) {
    const status = new StatusUtils(ctx, LocationActions.GetBySearchZip);
    const s = () => ctx.getState();

    let locations: ILocation[] = [];

    locations = (s().idsBySearchQuery[searchText] || []).map((id) => s().entities[id]);

    if (locations.length === 0) {
      // find direct hit?
      locations = s()
        .ids.filter((id) => s().entities[id].zipcode === searchText)
        .map((id) => s().entities[id]);
    }

    if (locations.length === 0) {
      locations = await this.api.getBySearchZip$(searchText).toPromise();
    }

    await ctx.dispatch(new LocationActions.UpsertMany(locations)).toPromise();
    ctx.patchState({
      idsBySearchQuery: {
        ...s().idsBySearchQuery,
        [searchText]: locations.map((l) => l.id),
      },
    });
    status.setLoading(false);
  }

  @Action(LocationActions.GetBySearchCity)
  async getBySearchCity(
    ctx: StateContext<ILocationState>,
    { searchText }: LocationActions.GetBySearchCity
  ) {
    const status = new StatusUtils(ctx, LocationActions.GetBySearchCity);
    const s = () => ctx.getState();

    let locations: ILocation[] = [];

    locations = (s().idsBySearchQuery[searchText] || []).map((id) => s().entities[id]);

    if (locations.length === 0) {
      locations = await this.api.getBySearchCity$(searchText).toPromise();
    }

    await ctx.dispatch(new LocationActions.UpsertMany(locations)).toPromise();
    ctx.patchState({
      idsBySearchQuery: {
        ...s().idsBySearchQuery,
        [searchText]: locations.map((l) => l.id),
      },
    });
    status.setLoading(false);
  }
}
