import { IUserFilter } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[UserFilter] UpsertMany';
  constructor(public userFilters: IUserFilter[]) {}
}

export class GetMine {
  static readonly type = '[UserFilter] GetMine';
  constructor() {}
}

export class Save {
  static readonly type = '[UserFilter] Save';
  constructor(public userFilter: IUserFilter) {}
}

export class Remove {
  static readonly type = '[UserFilter] Remove';
  constructor(public userFilters: IUserFilter[]) {}
}
