import * as LocationActions from './location.actions';
import { LocationSelectors } from './location.selectors';
import { LocationApiService } from './location.service';
import { LocationState } from './location.state';

export const Location = {
  state: LocationState,
  actions: LocationActions,
  select: LocationSelectors,
  api: LocationApiService,
};
