import { BackendService } from './backend.service';
import { CartService } from './cart.service';
import { DibsService } from './dibs.service';
import { GeolocationService } from './geolocation.service';
import { ImageService } from './image.service';
import { ItemService } from './item.service';
import { MyUserService } from './my-user.service';
import { NativeTalkerService } from './native-talker.service';
import { NavigatorService } from './navigator.service';
import { PlaceService } from './place.service';
import { PlatformService } from './platform.service';
import { ShoppingListService } from './shopping-list.service';
import { StoreService } from './store.service';
import { WINDOW_PROVIDERS } from './window.service';

export const coreServices = [
  BackendService,
  DibsService,
  CartService,
  GeolocationService,
  ImageService,
  ItemService,
  PlaceService,
  PlatformService,
  StoreService,
  MyUserService,
  NativeTalkerService,
  NavigatorService,
  ShoppingListService,
  WINDOW_PROVIDERS,
];

export * from './backend.service';
export * from './cart.service';
export * from './dibs.service';
export * from './geolocation.service';
export * from './image.service';
export * from './item.service';
export * from './my-user.service';
export * from './native-talker.service';
export * from './navigator.service';
export * from './place.service';
export * from './platform.service';
export * from './shopping-list.service';
export * from './store.service';
export * from './window.service';
