import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActionBarComponent } from './action-bar.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [ActionBarComponent],
  exports: [ActionBarComponent, MatButtonModule],
})
export class ActionBarModule { }
