import { Component, Input, Output, EventEmitter, QueryList, ContentChildren } from '@angular/core';
import { IMenuListItem, MenuListItemComponent } from './menu-list-item.component';

@Component({
  selector: 'kd-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent {
  @Input('items') itemsByInput: IMenuListItem[];
  @Output() itemClick = new EventEmitter<IMenuListItem>();

  @ContentChildren(MenuListItemComponent) itemsByElems: QueryList<MenuListItemComponent>;

  public get items() {
    return this.itemsByInput || this.itemsByElems.map(it => it as IMenuListItem);
  }

  constructor() { }



}
