import { Pipe, PipeTransform } from '@angular/core';
import { AddressHelper, IAddress } from '~_shared/models';

@Pipe({
  name: 'simpleAddress',
})
export class SimpleAddressPipe implements PipeTransform {
  transform(value: IAddress) {
    return AddressHelper.toSimple(value);
  }
}
