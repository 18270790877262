import { IStoreUser } from '~_shared/models';

export class UpsertMany {
  static readonly type = '[StoreUser] UpsertMany';
  constructor(public storeUsers: IStoreUser[], public loadRelated?: boolean) {}
}

export class GetMine {
  static readonly type = '[StoreUser] GetMine';
  constructor() {}
}

export class LoadRelated {
  static readonly type = '[StoreUser] LoadRelated';
  constructor(public storeUsers: IStoreUser[]) {}
}
