import { createSelector, Selector } from '@ngxs/store';
import { ILoadStatus, ILocation } from '~_shared/models';
import { MeSelectors } from '../me/me.selectors';
import { IDictionary } from '../_utils';
import { ILocationState, LocationState } from './location.state';

export class LocationSelectors {
  @Selector([LocationState])
  static state(s: ILocationState) {
    return s;
  }

  @Selector([LocationState])
  static status(s: ILocationState) {
    return s.status;
  }

  @Selector([LocationSelectors.status])
  static statusLoaded(status: ILoadStatus) {
    return status.loaded;
  }
  @Selector([LocationSelectors.statusLoaded])
  static statusLoadedIds(statusLoaded: IDictionary): (string | number)[] {
    return statusLoaded?.ids || [];
  }

  @Selector([LocationState])
  static entities(s: ILocationState) {
    return s.entities;
  }

  @Selector([LocationState])
  static ids(s: ILocationState) {
    return s.ids;
  }

  // alias, really
  @Selector([MeSelectors.selectedLocationId])
  static selectedId(selectedLocationId: string) {
    return selectedLocationId;
  }

  @Selector([LocationSelectors.entities, LocationSelectors.selectedId])
  static selected(entities: IDictionary<ILocation>, selectedId: string) {
    return entities[selectedId];
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: ILocationState) => {
      return s.entities[id];
    });
  }

  static hasLoadedIdFn(id: string) {
    return createSelector([this.statusLoadedIds], (loadedIds: (string | number)[]) => {
      return loadedIds.includes(id);
    });
  }

  @Selector([LocationState])
  static foundBySearchPos(s: ILocationState) {
    return (s.entities && s.foundBySearchPos.id && s.entities[s.foundBySearchPos.id]) || null;
  }

  static searchQueryFn(query: string) {
    return createSelector([this.state], (s: ILocationState) => {
      const ids = s.idsBySearchQuery[query] || [];
      const entities = ids.map((id) => s.entities[id]);
      return entities;
    });
  }
}
