import { createSelector, Selector } from '@ngxs/store';
import { IPlace, IPushMessage } from '~_shared/models';
import { MeSelectors } from '../me/me.selectors';
import { PlaceSelectors } from '../place/place.selectors';
import { IPushMessageState, PushMessageState } from './push-message.state';

export class PushMessageSelectors {
  @Selector([PushMessageState])
  static state(s: IPushMessageState) {
    return s;
  }

  @Selector([PushMessageState])
  static status(s: IPushMessageState) {
    return s.status;
  }

  @Selector([PushMessageState])
  static entities(s: IPushMessageState) {
    return s.entities;
  }

  @Selector([PushMessageState])
  static ids(s: IPushMessageState) {
    return s.ids;
  }

  @Selector([PushMessageState])
  static all(s: IPushMessageState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([PushMessageSelectors.all, MeSelectors.userId])
  static allMine(all: IPushMessage[], userId: string) {
    return (userId && all.filter((it) => it.userId === userId)) || [];
  }

  @Selector([PushMessageSelectors.all, MeSelectors.userId, PlaceSelectors.selectedOrDefault])
  static allMineAtPlace(all: IPushMessage[], userId: string, place: IPlace) {
    return (
      (userId && all.filter((it) => it.userId === userId && it.data?.placeId === place.id)) || []
    );
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IPushMessageState) => {
      return s.entities[id];
    });
  }
}
