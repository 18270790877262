import * as OrderActions from './order.actions';
import { OrderSelectors } from './order.selectors';
import { OrderApiService } from './order.service';
import { OrderState } from './order.state';

export const Order = {
  state: OrderState,
  actions: OrderActions,
  select: OrderSelectors,
  api: OrderApiService,
};
