// tslint:disable: no-any

import { mapValues } from 'lodash';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import dayjs from '../libs/dayjs';
import { IAddress, IDictionary } from '../models';
import { LatLng } from '../models/lat-lng.model';
import { GLOBAL_SETTINGS } from './global-settings';

/**
 * Convert hex to RGB array
 */
export const hexToRgb = (hex: string, asArray = false) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
  if (!rgb) {
    return null;
  }
  return asArray ? [rgb.r, rgb.g, rgb.b] : rgb;
};

/**
 * Hash string
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 */
export const hash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash).toString(16);
};

/**
 * Make a word singular or plural depending on counter
 */
export const singural = (
  counter: number,
  outputSingular: any,
  outputPlural: any,
  printValueBeforePlural = false
) => (counter === 1 ? outputSingular : (outputPlural + '').replace(/\$x/gi, counter + ''));

/**
 * Try finding property in nested objects
 * @param obj - Initial object to start looking in
 * @param propPath - Dot-separated path to final property
 */
export const findProp = (obj: any, propPath: string) => {
  const props = propPath.split('.');
  let currObj = obj;
  for (let i = 0; i < props.length; i++) {
    const prop = props[i];
    const isLastProp = i === props.length - 1;

    if (isLastProp || !currObj[prop]) {
      return currObj[prop];
    } else {
      currObj = currObj[prop];
    }
  }
  return undefined;
};

export const friendlyZipcode = (zipCode: string | number) => {
  let z = (zipCode + '').replace(/[^\d]/g, '');
  if (z.length >= 5) {
    z = z.substring(0, 3) + ' ' + z.substring(3);
  }
  return z;
};

export const friendlyId = (s: string) => {
  if (!s) {
    return s;
  }
  s = s + '';
  s = s.toLowerCase();
  s = s.replace(/[åä]/gi, 'a');
  s = s.replace(/[ö]/gi, 'o');
  s = s.replace(/\s/gi, '-');
  s = s.replace(/[^a-z0-9-_]/gi, '');
  return s;
};

/**
 * Detect if script is running as NodeJS
 */
export const isNode = () => !!(process && process.versions && process.versions.node);
declare const process: any;

export const validRadiusInKm = (
  radiusInKm?: number,
  min = GLOBAL_SETTINGS.place.radiusKm.min,
  max = GLOBAL_SETTINGS.place.radiusKm.max
) => {
  const r = radiusInKm ?? max;
  return r < min ? min : r > max ? max : r;
};

/**
 * Detect if object is within radius
 * @param centerPos
 * @param objectPos
 * @param radiusInMeters
 */
export const isWithinRadius = (centerPos: LatLng, objectPos: LatLng, radiusInMeters: number) => {
  const from = LatLng.fromObject(centerPos);
  const to = LatLng.fromObject(objectPos);
  const d = Math.abs(from.getDistance(to));
  return d <= radiusInMeters;
};

/**
 * Discount in decimal format
 * @param priceFull
 * @param priceDiscounted
 */
export const discountRate = (priceFull: number, priceDiscounted: number) => {
  return Math.abs(priceFull ? 1 - priceDiscounted / priceFull : 0);
};

/**
 * Discount in percentage format
 * @param priceFull
 * @param priceDiscounted
 */
export const discountPerc = (priceFull: number, priceDiscounted: number) => {
  return Math.round(discountRate(priceFull, priceDiscounted) * 100);
};

/**
 * Exceptions used in difference() to compare special objects
 */
export interface IDiffComparer<T = any> {
  constructor: T;
  isEqual: (v1: T, v2: T) => boolean;
}
export const defaultDiffComparers: IDiffComparer[] = [
  {
    constructor: Date,
    isEqual: (a: Date, b: Date) => {
      return a && b && a.getTime() === b.getTime();
    },
  },
  {
    constructor: Array,
    isEqual: (a: any[], b: any[]) => isEqualJSON(a, b),
  },
];

/**
 * Deep diff between two objects
 * (https://gist.github.com/Yimiprod/7ee176597fef230d1451)
 * @param  {Object} originalObject Original object
 * @param  {Object} compareObject  Object to compare (which data will show up in diff result)
 * @return {Object}                Return a new object who represent the diff
 */
export function difference<T1 = any, T2 = any>(
  originalObject: T1 & IDictionary<any>,
  compareObject: T2 & IDictionary<any>,
  customComparers = defaultDiffComparers
): Partial<T2> {
  return transform(
    compareObject as any,
    (result: Partial<IDictionary<any>>, compareValue: any, key: any) => {
      const originalValue = originalObject?.[key];

      if (!_isEqual(compareValue, originalValue)) {
        const hasCustomComparer = _hasCustomComparer(originalValue, compareValue);
        const compareDeep = isObject(compareValue) && isObject(originalValue) && !hasCustomComparer;
        result[key] = compareDeep ? difference(originalValue, compareValue) : compareValue;
      }
    }
  ) as Partial<T2>;

  function _isEqual(a: any, b: any) {
    if (isEqual(a, b)) {
      return true;
    }
    if (isObject(a) && isObject(b)) {
      // check custom comparers
      return (customComparers || []).some((dc) => {
        if (a instanceof dc.constructor || b instanceof dc.constructor) {
          return dc.isEqual(a, b);
        }
        return false;
      });
    }
    return false;
  }
  function _hasCustomComparer(a: any, b: any) {
    return (customComparers || []).some(
      (dc) => a instanceof dc.constructor || b instanceof dc.constructor
    );
  }
}

/**
 * Deep merge properties that differs by difference()
 */
export function deepMergeDifference<T1 = any, T2 = any>(
  a: T1 & object,
  b: T2 & object,
  differenceFn = difference
): T1 {
  if (!isObject(a) || !isObject(b)) {
    return a;
  }
  const diffs = differenceFn(a, b);
  for (const prop in diffs) {
    if (isObject((a as any)[prop]) && isObject(diffs[prop])) {
      (a as any)[prop] = deepMergeDifference((a as any)[prop], (b as any)[prop]);
    } else {
      (a as any)[prop] = (b as any)[prop];
    }
  }
  return a;
}

/**
 * Detect if two object are equal by using difference()
 * @param a
 * @param b
 */
export const isEqualObj = <T = unknown>(a: T, b: T) => {
  const diffA = difference(a, b);
  const diffB = difference(b, a);
  const hasDiffA = Object.keys(diffA).length > 0;
  const hasDiffB = Object.keys(diffB).length > 0;
  return !hasDiffA && !hasDiffB;
};

/**
 * Detect if two values are equal by comparing JSON.stringify values
 * @param a
 * @param b
 */
export const isEqualJSON = <T = unknown>(a: T, b: T) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * Check if userId is in admin list
 * @param userId
 */
export const isUserAdmin = (userId?: string) => GLOBAL_SETTINGS.adminUserIds.includes(userId || '');

/**
 * Check if userId is anonymous
 * Due to Firebase's isAnonymous prop break when logging in
 * by custom token, we're doing our own checks on email.
 * @param u - Firebase user object-ish
 */
export const isUserAnonymous = (u?: { uid: string; email?: string; phoneNumber?: string }) =>
  !!u?.uid && !u.email && !u.phoneNumber;

/**
 * Try simplify/strip auth object by using its toJSON() method, when available
 */
export const getSanitizedAuth = <T>(auth?: T): T => {
  // @ts-ignore: auth is kind of unknown
  return auth?.toJSON ? auth.toJSON() : auth;
};

/**
 * Try to get token by auth object
 */
export const getTokenByAuth = async (auth?: any) => {
  return auth?.getIdToken?.() ?? auth?.stsTokenManager?.accessToken;
};

/**
 * Get address parts from simple address (oneliner)
 * @param simpleAddress
 */
export const addressBySimpleAddress = (simpleAddress: string): IAddress => {
  const commaPos = simpleAddress.lastIndexOf(',');
  const streetLine1 = commaPos >= 0 ? simpleAddress.substring(0, commaPos) : simpleAddress;
  const postalInfo = commaPos >= 0 ? simpleAddress.substring(commaPos + 1).trim() : '';
  const postalCode = (postalInfo.match(/^[\d\s]+/)?.[0] ?? '').trim();
  const postalCity = postalInfo.replace(postalCode, '').trim();
  return { streetLine1, postalCode, postalCity };
};

export const cleanTelephone = (tel?: string, options = { countryCode: '46' }) => {
  let r = (tel || '').toString().trim();
  r = r.replace(/[^\d\+]/gi, '');
  r = r.replace(/^0+/gi, '');
  if (
    r.substring(0, 1) !== '+' &&
    r.substring(0, options.countryCode.length) !== options.countryCode
  ) {
    r = options.countryCode + r;
  }
  r = '+' + r.replace(/\+/gi, '');
  return r;
};

/**
 * Clean up URLs
 */
export const cleanUrl = (urlRaw?: string) => {
  if (!urlRaw) {
    return urlRaw;
  }
  let url = (urlRaw ?? '').toLowerCase().trim();
  if (url.indexOf('://') < 0) {
    url = 'https://' + url;
  }
  return url;
};

/**
 * Clean up Facebook URLS
 */
export const cleanFacebookUrl = (urlRaw?: string) => {
  let url = cleanUrl(urlRaw);
  if (url && url.indexOf('facebook.com/') < 0) {
    url = url.replace('://', '://www.facebook.com/').replace('.com//', '/');
  }
  return url;
};

/**
 * Clean up Instagram usernames
 */
export const cleanInstagramUser = (userRaw?: string) => {
  if (!userRaw) {
    return userRaw;
  }
  let user = userRaw.toLowerCase().trim();
  if (user[0] === '@') {
    user = user.substring(1);
  }
  return user;
};

/**
 * Remove properties by values
 */
export const filterObjectByValues = <T>(objRaw: T, removeValues: any[]) =>
  Object.keys(objRaw).reduce((obj, key) => {
    if (!removeValues.includes((objRaw as any)[key])) {
      (obj as any)[key] = (objRaw as any)[key];
    }
    return obj;
  }, {} as Partial<T>);

/**
 * Check if user/auth requires email verification and returns status info
 * @param auth firebase.User object
 */
export const getRequireEmailVerification = (auth: any) => {
  const providers = auth?.providerData;
  const isEmailLogin = providers?.length === 1 && providers[0].providerId === 'password';
  if (isEmailLogin && !auth.emailVerified) {
    const email = auth.email;
    const createdAtStamp = +auth['createdAt'];
    const createdAt = dayjs(createdAtStamp).toDate();
    const locksAt = dayjs(createdAt)
      .add(GLOBAL_SETTINGS.requireEmailVerificationInDays, 'day')
      .toDate();
    const daysUntilLock = dayjs.tz(locksAt).startOf('day').diff(new Date(), 'day');
    return { email, createdAt, locksAt, daysUntilLock };
  }
  return null;
};

export const randomNumberByLength = (length: number) => {
  const from = Math.pow(10, length - 1);
  const to = Math.pow(10, length) - 1;
  return randomNumber(from, to);
};
export const randomNumber = (from: number, to: number) => {
  return Math.floor(Math.random() * (to - from + 1)) + from;
};

/**
 * Convert date to number (.getTime()) and removes x digits at end (default = 1000 = removes milliseconds)
 * @param d
 * @param compressNum
 */
export const compressDate = (d?: number | string | Date, compressNum = 1000) => {
  if (d) {
    return Math.floor(new Date(d || new Date()).getTime() / (compressNum || 1));
  }
  return;
};
/**
 * Re-convert compressed date to its original form
 * @param d
 * @param compressNum
 */
export const decompressDate = (d?: number | string, compressNum = 1000) => {
  if (d && !isNaN(+d)) {
    return new Date(+d * (compressNum || 1)).getTime();
  }
  return;
};

/**
 *
 */
export const getBaseUrl = (url: string) => {
  const [protocol, rest] = url.split('://');
  const [host] = rest.split('/');
  return `${protocol}://${host}`.toLowerCase();
};

/**
 * Track/Log elapsed time between events
 */
export const logTime = (
  sStart: string,
  options?: { disabled?: boolean; logFn?: (...args: unknown[]) => unknown }
) => {
  const startAt = Date.now();
  let lastAt = Date.now();
  const logNext = (...args: any) => {
    if (options?.disabled) return;
    const now = Date.now();
    const elapsedSinceStart = (now - startAt) / 1000;
    const elapsedSinceLast = (now - lastAt) / 1000;
    lastAt = Date.now();
    const pad = (n: number) => {
      const nn = (n + '.').split('.');
      const n0 = (nn[0] || '0').padStart(3, ' ');
      const n1 = (nn[1] || '').padEnd(3, '0');
      return n0 + '.' + n1;
    };
    const log = options?.logFn || console.log;
    log(sStart, pad(elapsedSinceLast), pad(elapsedSinceStart), ...args);
  };
  logNext.when = async <T>(promise: Promise<T>, ...args: any): Promise<T> => {
    const r = await promise;
    logNext(...args);
    return r;
  };
  logNext();
  return logNext;
};

/**
 * Checks if date1 is old comparing to date2
 */
export const isOld = (
  date1: dayjs.ConfigType,
  maxAgeInSec = 30 * 60,
  date2: dayjs.ConfigType = new Date()
) => {
  if (!date1 || dayjs(date1).isValid() || !date2 || !dayjs(date2).isValid()) {
    return true; // invalid dates should flag as old
  }
  const diffInSec = dayjs(date1).diff(date2, 'second');
  const r = !isNaN(diffInSec) && diffInSec > maxAgeInSec;
  return r;
};

export const countArrays = (objWithArraysToCount: { [arrayName: string]: unknown[] }) =>
  mapValues(objWithArraysToCount, (arr) => arr.length);

export const propsIsEqual = <T = unknown>(s1: T, s2: Partial<T>, props: Array<keyof T>) =>
  !props.some((p) => s1?.[p] !== s2?.[p]);

export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] =>
  Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];

export const enumValues = <O extends object>(obj: O): O[keyof O][] =>
  enumKeys(obj).map((k) => obj[k]);
