import * as StoreActions from './store.actions';
import { StoreSelectors } from './store.selectors';
import { StoreApiService } from './store.service';
import { StoreState } from './store.state';

export const Store = {
  state: StoreState,
  actions: StoreActions,
  select: StoreSelectors,
  api: StoreApiService,
};
