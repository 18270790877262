import * as PlaceActions from './place.actions';
import { PlaceSelectors } from './place.selectors';
import { PlaceApiService } from './place.service';
import { PlaceState } from './place.state';

export const Place = {
  state: PlaceState,
  actions: PlaceActions,
  select: PlaceSelectors,
  api: PlaceApiService,
};
