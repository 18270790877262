import { Selector } from '@ngxs/store';
import { IUserFilter } from '~_shared/models';
import { MeSelectors } from '../me/me.selectors';
import { IUserFilterState, UserFilterState } from './user-filter.state';

export class UserFilterSelectors {
  @Selector([UserFilterState])
  static state(s: IUserFilterState) {
    return s;
  }

  @Selector([UserFilterState])
  static status(s: IUserFilterState) {
    return s.status;
  }
  @Selector([UserFilterState])
  static hasLoaded(s: IUserFilterState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([UserFilterState])
  static entities(s: IUserFilterState) {
    return s.entities;
  }

  @Selector([UserFilterState])
  static ids(s: IUserFilterState) {
    return s.ids;
  }

  @Selector([UserFilterState])
  static all(s: IUserFilterState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([UserFilterSelectors.all, MeSelectors.userId])
  static allMine(all: IUserFilter[], userId: string) {
    return all.filter((uf) => uf.userId === userId);
  }

  // alias, really
  @Selector([MeSelectors.selectedUserFilterId])
  static selectedId(selectedUserFilterId: string) {
    return selectedUserFilterId;
  }
}
