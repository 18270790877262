import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxResizeObserverModule } from 'ngx-resize-observer';
import { MaterialModule } from '../material';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalComponent } from './modal.component';

@NgModule({
  imports: [CommonModule, MaterialModule, NgxResizeObserverModule],
  declarations: [ModalComponent, ModalHeaderComponent],
  exports: [ModalComponent, ModalHeaderComponent],
})
export class ModalModule {}
