import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IPushMessage, IUserUpdate } from '~_shared/models';
import { getSanitizedAuth, getTokenByAuth, isUserAnonymous } from '~_shared/utils/misc.utils';
import * as BulkActions from '../bulk/bulk.actions';
import * as UserActions from '../user/user.actions';
import { IStatusState, StatusUtils } from '../_utils';
import * as MeActions from './me.actions';
import { MeApiService } from './me.service';

export interface IMeState extends IStatusState {
  auth: firebase.User;
  token: string;
  pushToken: string;
  pushMessages: IPushMessage[];
  updates: IUserUpdate;
  selected: MeActions.IMeSelected;
}

@State<IMeState>({
  name: 'me',
  defaults: {
    ...StatusUtils.defaultState(),
    auth: null,
    token: null,
    pushToken: null,
    pushMessages: [],
    updates: {},
    selected: {},
  },
})
@Injectable()
export class MeState {
  constructor(private api: MeApiService) {}

  @Action(MeActions.SetToken)
  async setToken(ctx: StateContext<IMeState>, { token }: MeActions.SetToken) {
    const status = new StatusUtils(ctx, MeActions.SetToken);
    ctx.patchState({ token });
    status.setLoading(false);
  }

  @Action(MeActions.SetAuth)
  async setAuth(ctx: StateContext<IMeState>, { authObj }: MeActions.SetAuth) {
    const status = new StatusUtils(ctx, MeActions.SetAuth);

    const newAuth = getSanitizedAuth(authObj);
    const oldAuth = ctx.getState().auth;

    const newToken = await getTokenByAuth(authObj);
    const oldToken = await getTokenByAuth(oldAuth);

    if (newToken !== oldToken && isUserAnonymous(oldAuth) && !isUserAnonymous(newAuth)) {
      await ctx
        .dispatch(new MeActions.MigrateAnonymous(oldToken, newToken))
        .toPromise()
        .catch(() => void 0);
    }

    // update token first
    await ctx.dispatch(new MeActions.SetToken(newToken)).toPromise();
    // set new auth
    ctx.patchState({ auth: newAuth });
    status.setLoading(false);

    if (newToken != oldToken) {
      if (!isUserAnonymous(newAuth)) {
        const meUser = await this.api.ensureUser();
        ctx.dispatch(new UserActions.UpsertMany([meUser]));
      }

      ctx.dispatch(new BulkActions.GetUser(newToken)).toPromise();
    }
  }

  @Action(MeActions.LoginByEmail)
  async loginByEmail(ctx: StateContext<IMeState>, { login }: MeActions.LoginByEmail) {
    const status = new StatusUtils(ctx, MeActions.LoginByEmail);
    let error: Error;
    try {
      await this.api.loginPassword(login);
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(MeActions.LoginByFacebook)
  async loginByFacebook(ctx: StateContext<IMeState>, {}: MeActions.LoginByFacebook) {
    const status = new StatusUtils(ctx, MeActions.LoginByFacebook);
    let error: Error;
    try {
      await this.api.loginFacebook();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }
  @Action(MeActions.LoginByGoogle)
  async loginByGoogle(ctx: StateContext<IMeState>, {}: MeActions.LoginByGoogle) {
    const status = new StatusUtils(ctx, MeActions.LoginByGoogle);
    let error: Error;
    try {
      await this.api.loginGoogle();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }
  @Action(MeActions.LoginByApple)
  async loginByApple(ctx: StateContext<IMeState>, {}: MeActions.LoginByApple) {
    const status = new StatusUtils(ctx, MeActions.LoginByApple);
    let error: Error;
    try {
      await this.api.loginApple();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(MeActions.CreateAnonymous)
  async createAnonymous(ctx: StateContext<IMeState>, {}: MeActions.CreateAnonymous) {
    const status = new StatusUtils(ctx, MeActions.CreateAnonymous);
    let error: Error;
    try {
      await this.api.createAnonymous();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(MeActions.CreateUser)
  async createUser(ctx: StateContext<IMeState>, { newUser }: MeActions.CreateUser) {
    const status = new StatusUtils(ctx, MeActions.CreateUser);
    let error: Error;
    try {
      await this.api.createUser(newUser);
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(MeActions.MigrateAnonymous)
  async migrateAnonymous(
    ctx: StateContext<IMeState>,
    { oldToken, newToken }: MeActions.MigrateAnonymous
  ) {
    const status = new StatusUtils(ctx, MeActions.MigrateAnonymous);
    await this.api.migrateAnonymous(oldToken, newToken).catch(void 0);
    status.setLoading(false);
  }

  @Action(MeActions.PushTokenUpdate)
  async pushTokenUpdate(ctx: StateContext<IMeState>, { newToken }: MeActions.PushTokenUpdate) {
    const status = new StatusUtils(ctx, MeActions.PushTokenUpdate);
    const s = ctx.getState();
    let error: Error;
    try {
      const oldToken = newToken !== s.pushToken ? s.pushToken : undefined;
      const pushToken = await this.api.pushTokenUpdate(newToken, oldToken);
      ctx.patchState({ pushToken });
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(MeActions.SetUserUpdates)
  async setUserUpdates(ctx: StateContext<IMeState>, { updates }: MeActions.SetUserUpdates) {
    ctx.patchState({ updates });

    // FYI: actions based on updates are made in MyUserService.trackUpdates()
    // or by utilizing MyUserService.getUpdates$() wherever needed.
  }

  @Action(MeActions.SetSelected)
  async setSelected(ctx: StateContext<IMeState>, { selected, clear }: MeActions.SetSelected) {
    const s = ctx.getState();
    ctx.patchState({
      selected: clear ? selected : Object.assign({}, s.selected, selected),
    });
  }
  @Action(MeActions.DeselectLocation)
  async deselectLocation(ctx: StateContext<IMeState>, { locationId }: MeActions.DeselectLocation) {
    const s = ctx.getState();
    if (!locationId || locationId === s.selected.locationId) {
      ctx.dispatch(new MeActions.SetSelected({ locationId: null }));
    }
  }
}
