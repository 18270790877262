export interface IDictionary<T = any> {
  [id: string]: T;
  [id: number]: T;
}

export interface IEntity {
  id?: number | string;
}

export interface IEntityState<T, U = string | number> {
  entities: IDictionary<T>;
  ids: U[];
}

export class EntityUtils {
  static defaultEntityState<T>() {
    return { entities: {}, ids: [] };
  }

  static cloneState<T>(s: IEntityState<T>) {
    return {
      ...s,
      entities: { ...s.entities },
      ids: [...s.ids],
    } as IEntityState<T>;
  }

  static addOne<T>(s: IEntityState<T>, entity: T & IEntity, cloneState = true) {
    if (!entity?.id) {
      return s;
    }
    const newState = cloneState ? this.cloneState(s) : s;
    newState.entities[entity.id] = entity;
    if (!newState.ids.includes(entity.id)) {
      newState.ids.push(entity.id);
    }
    return newState;
  }
  static updateOne = EntityUtils.addOne; // alias
  static upsertOne = EntityUtils.addOne; // alias

  static addMany<T>(s: IEntityState<T>, entities: (T & IEntity)[]) {
    const newState = this.cloneState(s);
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      if (!entity?.id) {
        console.log('addMany: id missing', entity, s);
        continue;
      }
      newState.entities[entity.id] = entity;
    }
    newState.ids = Object.keys(newState.entities);
    return newState;
  }
  static updateMany = EntityUtils.addMany; // alias
  static upsertMany = EntityUtils.addMany; // alias

  static removeOne<T>(s: IEntityState<T>, entity: T & IEntity, cloneState = true) {
    const newState = cloneState ? this.cloneState(s) : s;
    const idsIndex = newState.ids.indexOf(entity.id);
    if (idsIndex >= 0) {
      // fastest way to delete from array = swap delete
      newState.ids[idsIndex] = newState.ids[newState.ids.length - 1];
      newState.ids.pop();
    }
    delete newState.entities[entity.id];
    return newState;
  }

  static removeMany<T>(s: IEntityState<T>, entities: (T & IEntity)[]) {
    const newState = this.cloneState(s);
    for (let i = 0; i < entities.length; i++) {
      this.removeOne(newState, entities[i], false);
    }
    return newState;
  }
}
