import { IItem, IItemEdit } from '~_shared/models';
import { IDictionary } from '../_utils';

export class UpsertMany {
  static readonly type = '[Item] UpsertMany';
  constructor(public items: IItem[], public loadRelated?: boolean) {}
}

export class SetIdsByStoresHash {
  static readonly type = '[Item] SetIdsByStoresHash';
  constructor(public idsByStoresHash: IDictionary<IDictionary<string[]>>, public clear?: boolean) {}
}
export class GetById {
  static readonly type = '[Item] GetById';
  constructor(public id: string) {}
}

export class GetByIds {
  static readonly type = '[Item] GetByIds';
  constructor(public ids: string[]) {}
}

export class GetPublicsByStores {
  static readonly type = '[Item] GetPublicsByStores';
  constructor(public storeIds: string[]) {}
}

export class GetByStoreAdmin {
  static readonly type = '[Item] GetByStoreAdmin';
  constructor(public storeId: string) {}
}

export class Save {
  static readonly type = '[Item] Save';
  constructor(public item: IItemEdit) {}
}

export class LoadRelated {
  static readonly type = '[Item] LoadRelated';
  constructor(public items: IItem[]) {}
}
