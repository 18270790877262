import * as ShoppingListItemActions from './shopping-list-item.actions';
import { ShoppingListItemSelectors } from './shopping-list-item.selectors';
import { ShoppingListItemApiService } from './shopping-list-item.service';
import { ShoppingListItemState } from './shopping-list-item.state';

export const ShoppingListItem = {
  state: ShoppingListItemState,
  actions: ShoppingListItemActions,
  select: ShoppingListItemSelectors,
  api: ShoppingListItemApiService,
};
