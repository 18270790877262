import { createSelector, Selector } from '@ngxs/store';
import mapValues from 'lodash/mapValues';
import { IItem, IShoppingListItem, IStore } from '~_shared/models';
import { ItemSelectors } from '../item/item.selectors';
import { StoreSelectors } from '../store/store.selectors';
import { IDictionary } from '../_utils';
import { IShoppingListItemState, ShoppingListItemState } from './shopping-list-item.state';

export class ShoppingListItemSelectors {
  @Selector([ShoppingListItemState])
  static state(s: IShoppingListItemState) {
    return s;
  }

  @Selector([ShoppingListItemState])
  static status(s: IShoppingListItemState) {
    return s.status;
  }
  @Selector([ShoppingListItemState])
  static hasLoaded(s: IShoppingListItemState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([ShoppingListItemState, ItemSelectors.entityByIdFn, StoreSelectors.entities])
  static entities(
    s: IShoppingListItemState,
    itemByIdFn: (id: string) => IItem,
    storeByIds: IDictionary<IStore>
  ): IDictionary<IShoppingListItemInfo> {
    return mapValues(s.entities, (it) => {
      const item = itemByIdFn(it.itemId);
      const store = storeByIds[item?.storeId];
      const lit: IShoppingListItemInfo = { ...it, item, store };
      return lit;
    });
  }

  @Selector([ShoppingListItemState])
  static ids(s: IShoppingListItemState) {
    return s.ids;
  }

  @Selector([ShoppingListItemSelectors.ids, ShoppingListItemSelectors.entities])
  static all(ids: string[], entities: IDictionary<IShoppingListItemInfo>) {
    return ids.map((id) => entities[id]);
  }

  @Selector([ShoppingListItemSelectors.all])
  static entitiesByListIds(all: IShoppingListItem[]) {
    return all.reduce((byListIds, lit) => {
      byListIds[lit.shoppingListId] = byListIds[lit.shoppingListId] || [];
      byListIds[lit.shoppingListId].push(lit);
      return byListIds;
    }, {} as IDictionary<IShoppingListItemInfo[]>);
  }

  static entityByIdFn(id: string) {
    return createSelector([this.entities], (entities: IDictionary<IShoppingListItemInfo>) => {
      return entities[id];
    });
  }

  static entitiesByListIdFn(listId: string) {
    return createSelector(
      [this.entitiesByListIds],
      (entitiesByListIds: IDictionary<IShoppingListItemInfo[]>) => {
        return entitiesByListIds[listId] || [];
      }
    );
  }
}

export interface IShoppingListItemInfo extends IShoppingListItem {
  item?: IItem;
  store?: IStore;
}
