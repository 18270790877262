import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import dayjs from '~_shared/libs/dayjs';
import { IItem, ItemHelper, ItemStatus } from '~_shared/models';
import { ImageSizeType } from '~_shared/models/image.model';
import { NGXS } from '../ngxs';
import { ImageService } from './image.service';

export const ItemQuantityLevels = ['-', '1-3', '4-9', '9+'];

@Injectable()
export class ItemService {
  readonly ItemHelper = ItemHelper;

  isLoading$ = this.ngxs.select(NGXS.Item.select.hasLoaded).pipe(map((x) => !x));

  constructor(private ngxs: Store, public imageSvc: ImageService) {}

  image(item: IItem, sizeName: ImageSizeType = 'small') {
    return this.imageSvc.imageUrl(item.images?.[0], sizeName);
  }

  images(item: IItem, sizeName: ImageSizeType = 'small') {
    return (item.images || []).map((img) => this.imageSvc.imageUrl(img, sizeName));
  }

  friendlyStatusText(item: IItem) {
    switch ((ItemHelper.status(item) || '').toUpperCase()) {
      case ItemStatus.Published:
        return 'Publicerad';
      case ItemStatus.Expired:
        return 'Utgått';
      default:
        return 'Ej publicerad';
    }
  }

  expireInDays(item: IItem) {
    return ItemHelper.expireInDays(item);
  }

  lastUpdatedInDays(item: IItem) {
    return ItemHelper.lastUpdatedInDays(item);
  }

  friendlyExpirePreText(item: IItem) {
    // if (Item_.expireInDays(item) < 1)
    //   return Math.abs(Item_.expireInDays(item)) + ' dagar sen';
    const expireInDays = this.expireInDays(item);
    if (expireInDays === -1) {
      return 'Igår';
    }
    if (expireInDays === 0) {
      return 'Idag';
    }
    if (expireInDays === 1) {
      return 'Imorgon';
    }
    if (expireInDays >= 2 && expireInDays <= 7) {
      return expireInDays + ' dagar';
    }
    return '';
  }

  friendlyLastUpdated(item: IItem) {
    const updatedAt = item?.updatedAt || item?.createdAt;
    if (!updatedAt) {
      return;
    }
    const h = dayjs(updatedAt).format('H');
    const days = this.lastUpdatedInDays(item);
    switch (days) {
      case 0:
        return `Kl ${h}-tiden idag, `;
      case 1:
        return `Kl ${h}-tiden igår`;
      default:
        return `${days} dagar sedan`;
    }
  }

  get minQuantityLevel() {
    return 0;
  }
  get maxQuantityLevel() {
    return ItemQuantityLevels.length - 1;
  }

  friendlyQuantityText(quantityLevel: number) {
    return ItemQuantityLevels[quantityLevel];
  }

  // tslint:disable: curly
  quantityStatus(quantityLevel: number) {
    switch (quantityLevel) {
      case 0:
      case 1:
        return 'danger';
      case 2:
        return 'warning';
      case 3:
        return 'success';
    }
  }

  shortInfoByVariants(item?: IItem) {
    return ItemHelper.itemShortInfoByVariants(item?.variants);
  }
}
