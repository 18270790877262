import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transform word into owner state
 * E.g: Fred => Freds, Dennis => Dennis
 */
@Pipe({
  name: 's',
})
export class StSPipe implements PipeTransform {
  transform(v: string) {
    let s = v?.trim();
    if (s?.substring(s.length - 1).toLowerCase() !== 's') {
      s += 's';
    }
    return s;
  }
}
