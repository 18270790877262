import * as ItemBodyActions from './item-body.actions';
import { ItemBodySelectors } from './item-body.selectors';
import { ItemBodyApiService } from './item-body.service';
import { ItemBodyState } from './item-body.state';

export const ItemBody = {
  state: ItemBodyState,
  actions: ItemBodyActions,
  select: ItemBodySelectors,
  api: ItemBodyApiService,
};
