import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, first, map, takeUntil } from 'rxjs/operators';
import { NGXS } from '~/core/ngxs';
import { DestroyWatcher } from '~/shared/helpers';
import dayjs from '~_shared/libs/dayjs';
import { UserEventAllType, UserEventHelper, UserEventType } from '~_shared/models';

@Injectable({ providedIn: 'root' })
export class UserEventService extends DestroyWatcher {
  constructor(private ngxs: Store) {
    super();
    this.sendToQueue$Dispatcher();
    this.sendToSave$Dispatcher();
  }

  readonly Type = UserEventAllType;

  /**
   * Stores timers for single event to debounce it being tracked
   */
  readonly timerQueue: { [userEventId: string]: NodeJS.Timer } = {};

  /**
   * Stores multiple events that is ok to send to NGXS
   * (We use this with debounce to avoid flooding NGXS with `[UserEvent] Track` actions)
   */
  readonly sendToQueue$ = new BehaviorSubject<{
    [userEventId: string]: { type: UserEventType; entityId?: string };
  }>({});

  /**
   * Adds an event to sendToQueue$ (after debounceTimeInMs milliseconds) - if not cancelled by aborter$
   * @param type
   * @param entityId
   * @param aborter$
   * @param debounceTimeInMs
   */
  track(
    type: UserEventType,
    entityId?: string,
    aborter$?: Observable<unknown>,
    debounceTimeInMs = 2000
  ) {
    const id = UserEventHelper.id({ type, entityId });

    this.clearTrack(type, entityId);

    this.timerQueue[id] = setTimeout(() => {
      this.sendToQueue$.next({ ...this.sendToQueue$.value, [id]: { type, entityId } });
      this.clearTrack(type, entityId);
    }, debounceTimeInMs);

    if (aborter$) {
      aborter$.pipe(first()).subscribe(() => this.clearTrack(type, entityId));
    }
  }

  /**
   * Cancel/Clean-up an event track
   * @param type
   * @param entityId
   */
  clearTrack(type: UserEventType, entityId?: string) {
    const id = UserEventHelper.id({ type, entityId });
    clearTimeout(this.timerQueue[id]);
    delete this.timerQueue[id];
  }

  /**
   * Listens for changes to sendToQueue$ with debounceTime to send events in bulk to NGXS.
   * (To avoid flooding Redux log with Track actions)
   */
  sendToQueue$Dispatcher() {
    this.sendToQueue$
      .pipe(
        takeUntil(this.ngDestroyed$),
        filter((q) => Object.keys(q).length > 0),
        debounceTime(1000)
      )
      .subscribe((sendToQueue) => {
        const trackedQueue = Object.values(sendToQueue);
        this.ngxs.dispatch(new NGXS.UserEvent.actions.Track(trackedQueue));
        this.sendToQueue$.next({});
      });
  }

  /**
   * Listens for changes to queue in NGXS with debounceTime to send data to backend to save.
   */
  sendToSave$Dispatcher() {
    const saveDebounceTimeInSec = 60;
    const queue$ = this.ngxs.select(NGXS.UserEvent.select.queue);
    const lastSavedAt$ = this.ngxs.select(NGXS.UserEvent.select.lastSavedAt);
    const hasUser$ = this.ngxs.select(NGXS.Me.select.userId).pipe(filter((x) => !!x));

    combineLatest([queue$, lastSavedAt$, hasUser$])
      .pipe(
        takeUntil(this.ngDestroyed$),
        debounceTime(2000),
        filter(([queue, lastSavedAt]) => {
          const queueOK = Object.keys(queue).length > 0;
          const timeOK =
            !lastSavedAt || Math.abs(dayjs().diff(lastSavedAt, 'second')) >= saveDebounceTimeInSec;
          return queueOK && timeOK;
        })
      )
      .subscribe(([queue]) => {
        this.ngxs.dispatch(new NGXS.UserEvent.actions.Save(queue));
      });
  }

  isTracked$(type: UserEventType, entityId?: string, timestampAfter?: number) {
    return this.ngxs
      .select(
        NGXS.UserEvent.select.filterAll({
          types: [type],
          entityIds: [entityId],
          timestampAfter,
        })
      )
      .pipe(map((userEvents) => !!userEvents.length));
  }
}
