import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dtRemoveYear',
})
export class DtRemoveYearPipe implements PipeTransform {
  transform(v: string) {
    const year = new Date().getFullYear();
    const re = new RegExp(`[\-\.,]?${year}[\-\.,]?`);
    return v.replace(re, '').trim();
  }
}
