import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { environment } from 'environments/environment';
import kebabCase from 'lodash/kebabCase';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { IDictionary, IImage, ImageHelper, ImageSizeType } from '~_shared/models';
import { GLOBAL_SETTINGS } from '~_shared/utils/global-settings';
import { MyUserService } from './my-user.service';

@Injectable()
export class ImageService {
  private uploadQueue$ = new BehaviorSubject<IDictionary<IImageUpload>>({});

  constructor(
    @Inject(DOCUMENT) private document,
    private fireStorage: AngularFireStorage,
    private myUser: MyUserService
  ) {}

  /* Get image url by path or IImage */
  imageUrl(imageOrPath: IImage | string, sizeName: ImageSizeType = 'small') {
    return ImageHelper.imageUrl(imageOrPath, sizeName, this.transformPathToUrl);
  }

  /* Instructions how to transform (e.g storage) path to absolute url */
  transformPathToUrl = (path: string) => {
    const parts = path.split('/').filter((x) => !!x);
    const filename = parts.pop();
    if (parts[0] === 'images') {
      parts[0] = 'i'; // images/myfile.jpg -> i/myfile.jpg
    }
    const filepath = parts.map(kebabCase).join('/');
    return `${environment.fileBaseUrl}/${filepath}/${filename}`;
  };

  /* Instructions how to transform absolute path to storage path */
  transformUrlToPath = (url: string, stripSizeName = true) => {
    url = url.replace(environment.fileBaseUrl, '');
    const parts = url.split('/').filter((x) => !!x);
    let filename = parts.pop();
    if (stripSizeName) {
      filename = filename.replace(/@[\w-]+/, ''); // myfile@s-small.png -> myfile.png
    }
    if (parts[0] === 'i') {
      parts[0] = 'images'; // i/myfile.jpg -> images/myfile.jpg
    }
    const path = parts.map(kebabCase).join('/');
    return `${path}/${filename}`;
  };

  /* Add file from input[type=file] to upload queue */
  addUpload(path: string, file: File, startUpload = true): IImageUpload {
    const uploadQueue = this.uploadQueue$.value;
    uploadQueue[path] = { path, file, resize: this.resizeUpload(file) };
    const u = uploadQueue[path];

    // when resize done -> start uploading resized image
    u.resize.promise.then(() => {
      if (!startUpload) return;
      u.upload = this.startUpload(path, u.resize.imgDataResized);
      u.upload.then((s) => {
        if (s.state === 'success') {
          // upload done = remove from queue (delay to let BE breathe and find new image)
          setTimeout(() => this.removeUpload(path), 1000);
        }
      });
    });

    this.uploadQueue$.next(uploadQueue);
    return u;
  }

  /* Remove from upload queue */
  removeUpload(path: string) {
    const uploadQueue = this.uploadQueue$.value;
    const u = uploadQueue[path];
    u?.upload?.cancel();
    delete uploadQueue[path];
    this.uploadQueue$.next(uploadQueue);
  }

  /* Get queue item, if any */
  getUpload(imageOrPath: IImage | string) {
    const path = typeof imageOrPath === 'object' ? imageOrPath?.path : imageOrPath;
    return this.uploadQueue$.value[path];
  }

  /* Get queue item, if any, as observable */
  getUpload$(imageOrPath: IImage | string) {
    const path = typeof imageOrPath === 'object' ? imageOrPath?.path : imageOrPath;
    return this.uploadQueue$.pipe(
      map((uploadQueue) => uploadQueue[path]),
      distinctUntilChanged()
    );
  }

  /* Resize image from input[type=file] */
  resizeUpload(file: File, maxWidth = GLOBAL_SETTINGS.imageSizes.large || 2000): IImageResize {
    if (!file) {
      throw new Error('Invalid file');
    }
    const document = this.document;
    const resize: IImageResize = {
      file,
      maxWidth,
      promise: Promise.resolve({} as IImageResize), // dummy
    };
    resize.promise = new Promise(async (resolve) => {
      try {
        const dataOriginal = await getImgDataOriginal();
        const dataResized = await getImgDataResized(dataOriginal);
        resize.imgDataOriginal = dataOriginal;
        resize.imgDataResized = dataResized;
      } catch (error) {
        resize.error = error;
      } finally {
        resize.loaded = true;
        resolve(resize);
      }
    });
    return resize;

    function getImgDataOriginal() {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', (e) => resolve(e?.target?.result?.toString()));
        reader.addEventListener('error', (e) =>
          reject(e?.target?.error || new Error('Unknown reader error'))
        );
        reader.readAsDataURL(file);
      });
    }
    function getImgDataResized(data: string) {
      return new Promise<string>((resolve, reject) => {
        try {
          const img = new Image();
          img.addEventListener('load', () => {
            const originalWidth = img?.width ?? maxWidth;
            if (originalWidth <= maxWidth) {
              resolve(data);
              return;
            }
            const scale = maxWidth / originalWidth;
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const resizedData = ctx.canvas.toDataURL('image/jpeg', 80);
            resolve(resizedData);
          });
          img.addEventListener('error', (e) => reject(e || new Error('Unknown resize error')));
          img.src = data;
        } catch (err) {
          reject(err);
        }
      });
    }
  }

  /* Trigger upload to BE */
  startUpload(
    path: string,
    fileOrData: File | ArrayBuffer | string,
    stringFormat = 'data_url' // https://firebase.google.com/docs/reference/js/firebase.storage#stringformat
  ) {
    const fileRef = this.fireStorage.ref(path);
    const meta: firebase.storage.UploadMetadata = {
      customMetadata: { sizeName: 'original', userId: this.myUser.userId },
    };
    return typeof fileOrData === 'string'
      ? fileRef.putString(fileOrData.toString(), stringFormat, meta)
      : fileRef.put(fileOrData, meta);
  }
}

export interface IImageUpload {
  path: string; // id
  file: File;
  resize: IImageResize;
  upload?: AngularFireUploadTask;
}

export interface IImageResize {
  file: File;
  maxWidth: number;
  error?: Error;
  imgDataOriginal?: string;
  imgDataResized?: string;
  promise: Promise<IImageResize>;
  loaded?: boolean;
}
