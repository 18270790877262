import { Injectable } from '@angular/core';

@Injectable()
export class PlatformService {

  constructor() { }

  isWeb() {
    return true;
  }

  isApp() {
    return !this.isWeb;
  }
}
