import { Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { WINDOW } from './window.service';

/**
 * Utilize window.nsWebViewBridge from
 * https://www.npmjs.com/package/@nota/nativescript-webview-ext
 */
@Injectable()
export class NativeTalkerService {
  private listenQueue: Array<{ eventName: string; fn: (data: unknown) => void }> = [];
  private talkQueue: Array<{ eventName: string; data: unknown }> = [];

  private _listen$ = new Subject<ListenEvent>();
  readonly listen$ = this._listen$.asObservable();

  private _talker$ = new BehaviorSubject<NSWebViewBridge>(null);
  readonly hasTalker$ = this._talker$.pipe(map((x) => !!x));
  get hasTalker() {
    return !!this._talker$.value;
  }
  private get talker() {
    return this._talker$.value;
  }

  constructor(
    private ngZone: NgZone,
    @Inject(WINDOW) private window: Window & { nsWebViewBridge: NSWebViewBridge }
  ) {}

  init() {
    this.setTalker();

    if (!this._talker$.value) {
      this.window.addEventListener('ns-bridge-ready', () => this.setTalker());
    }
  }

  private setTalker() {
    this._talker$.next(this.window.nsWebViewBridge);

    // Overrride nsWebViewBridge.onNativeEvent to pass all events to this.listen$
    if (this.talker?.onNativeEvent && !this.talker?.onNativeEventOriginal) {
      this.talker.onNativeEventOriginal = this.talker.onNativeEvent;
      this.talker.onNativeEvent = (eventName: string, data: unknown) => {
        this.ngZone.run(() => {
          this.talker.onNativeEventOriginal(eventName, data);
          this._listen$.next({ eventName, data });
        });
      };
    }

    this.runListenQueue();
    this.runTalkQueue();
  }

  listen<T>(eventName: string, fn: (data: T) => void) {
    this.listenQueue.push({ eventName, fn });
    this.runListenQueue();
  }
  private runListenQueue() {
    if (!this.talker) {
      return;
    }
    for (const l of this.listenQueue) {
      this.talker.on(l.eventName, (data) => l.fn(data));
    }
    this.listenQueue.length = 0;
  }

  talk<T>(eventName: string, data?: T) {
    this.talkQueue.push({ eventName, data });
    this.runTalkQueue();
  }
  private runTalkQueue() {
    if (!this.talker) {
      return;
    }
    for (const t of this.talkQueue) {
      this.talker.emit(t.eventName, t.data);
    }
    this.talkQueue.length = 0;
  }

  listenTo$<T = any>(eventName: string) {
    return this.listen$.pipe(
      filter((t) => t.eventName === eventName),
      map((t) => t.data as T)
    );
  }

  listenOnce$<T = any>(eventName: string) {
    return this.listenTo$<T>(eventName).pipe(first());
  }
}

interface NSWebViewBridge {
  on: <T>(eventName: string, cb: (data: T) => void) => void;
  off: <T>(eventName: string, cb: (data: T) => void) => void;
  emit: <T>(eventName: string, data: T) => void;
  onNativeEvent: <T>(eventName: string, data: T) => void;
  onNativeEventOriginal?: <T>(eventName: string, data: T) => void;
}

interface ListenEvent<T = unknown> {
  eventName: string;
  data: T;
}
