import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import { IPollAnswer, IPollQuestion } from '~_shared/models';
import { EntityUtils, IDictionary, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as PollActions from './poll.actions';
import { PollApiService } from './poll.service';

export interface IPollState extends IEntityState<IPollQuestion>, IStatusState {
  answerByQuestionIds: IDictionary<IPollAnswer>;
}

@State<IPollState>({
  name: 'poll',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
    answerByQuestionIds: {},
  },
})
@Injectable()
export class PollState {
  constructor(private api: PollApiService) {}

  @Action(PollActions.UpsertMany)
  async upsertMany(ctx: StateContext<IPollState>, { polls }: PollActions.UpsertMany) {
    const status = new StatusUtils(ctx, PollActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), polls));
    status.setLoading(false);
  }

  @Action(PollActions.GetByIds)
  async getByIds(ctx: StateContext<IPollState>, { ids }: PollActions.GetByIds) {
    const s = () => ctx.getState();
    const nonExistingIds = uniq(ids).filter((id) => !s().entities[id]);
    if (nonExistingIds.length === 0) {
      return;
    }
    const status = new StatusUtils(ctx, PollActions.GetByIds);
    const polls = await this.api.getByIds$(nonExistingIds).toPromise();
    await ctx.dispatch(new PollActions.UpsertMany(polls)).toPromise();
    status.setLoadedIds(nonExistingIds).setLoading(false);
  }

  @Action(PollActions.UpsertAnswers)
  async upsertAnswers(ctx: StateContext<IPollState>, { answers }: PollActions.UpsertAnswers) {
    const status = new StatusUtils(ctx, PollActions.UpsertAnswers);
    const answerByQuestionIds = Object.assign(
      {},
      ctx.getState().answerByQuestionIds,
      keyBy(answers, 'questionId')
    );
    ctx.patchState({ answerByQuestionIds });
    status.setLoading(false);
  }

  @Action(PollActions.SaveAnswers)
  async saveAnswers(ctx: StateContext<IPollState>, { answers }: PollActions.SaveAnswers) {
    const status = new StatusUtils(ctx, PollActions.SaveAnswers);
    let error: Error;
    try {
      const savedAnswers = await this.api.saveAnswers$(answers).toPromise();
      await ctx.dispatch(new PollActions.UpsertAnswers(savedAnswers)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(PollActions.ClearAnswers)
  async clearAnswers(ctx: StateContext<IPollState>, { questionIds }: PollActions.ClearAnswers) {
    const status = new StatusUtils(ctx, PollActions.ClearAnswers);
    let error: Error;
    try {
      const clearedAnswers = await this.api.clearAnswers$(questionIds).toPromise();
      const answerByQuestionIds = { ...(ctx.getState().answerByQuestionIds || {}) };
      for (const a of clearedAnswers || []) {
        delete answerByQuestionIds[a.questionId];
      }
      ctx.patchState({ answerByQuestionIds });
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }
}
