import { createSelector, Selector } from '@ngxs/store';
import { IShoppingListUser } from '~_shared/models';
import { IDictionary } from '../_utils';
import { IShoppingListUserState, ShoppingListUserState } from './shopping-list-user.state';

export class ShoppingListUserSelectors {
  @Selector([ShoppingListUserState])
  static state(s: IShoppingListUserState) {
    return s;
  }

  @Selector([ShoppingListUserState])
  static status(s: IShoppingListUserState) {
    return s.status;
  }
  @Selector([ShoppingListUserState])
  static hasLoaded(s: IShoppingListUserState) {
    return !s.status?.loading && !!s.status?.lastLoaded;
  }

  @Selector([ShoppingListUserState])
  static entities(s: IShoppingListUserState) {
    return s.entities;
  }

  @Selector([ShoppingListUserState])
  static ids(s: IShoppingListUserState) {
    return s.ids;
  }

  @Selector([ShoppingListUserState])
  static all(s: IShoppingListUserState) {
    return s.ids.map((id) => s.entities[id]);
  }

  @Selector([ShoppingListUserSelectors.all])
  static entitiesByListIds(all: IShoppingListUser[]) {
    return all.reduce((byListIds, lit) => {
      byListIds[lit.shoppingListId] = byListIds[lit.shoppingListId] || [];
      byListIds[lit.shoppingListId].push(lit);
      return byListIds;
    }, {} as IDictionary<IShoppingListUser[]>);
  }

  static entityByIdFn(id: string) {
    return createSelector([this.state], (s: IShoppingListUserState) => {
      return s.entities[id];
    });
  }

  static entitiesByListIdFn(listId: string) {
    return createSelector(
      [this.entitiesByListIds],
      (entitiesByListIds: IDictionary<IShoppingListUser[]>) => {
        return entitiesByListIds[listId] || [];
      }
    );
  }
}
