import { hash } from '../../utils/misc.utils';

export interface ICartItem {
  id: string;
  storeId: string;
  itemId: string;
  userId: string;
  quantity: number;
  message?: string;
}

export class CartItemHelper {
  static id(userId: string, itemId: string) {
    return hash(userId + itemId);
  }
}
