import { Injectable } from '@angular/core';
import { BackendService } from '~/core/services/backend.service';
import { IPushMessage } from '~_shared/models';

@Injectable()
export class PushMessageApiService {
  constructor(private backend: BackendService) {}

  async getMine() {
    return this.backend.get<IPushMessage[]>(`push/mine`).toPromise();
  }
}
