import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import flatten from 'lodash/flatten';
import { IOrder } from '~_shared/models';
import * as ItemActions from '../item/item.actions';
import * as StoreActions from '../store/store.actions';
import * as UserActions from '../user/user.actions';
import { EntityUtils, IEntityState, IStatusState, StatusUtils } from '../_utils';
import * as OrderActions from './order.actions';
import { OrderApiService } from './order.service';

export interface IOrderState extends IEntityState<IOrder>, IStatusState {}

@State<IOrderState>({
  name: 'order',
  defaults: {
    ...StatusUtils.defaultState(),
    ...EntityUtils.defaultEntityState(),
  },
})
@Injectable()
export class OrderState {
  constructor(private api: OrderApiService) {}

  @Action(OrderActions.UpsertMany)
  async upsertMany(
    ctx: StateContext<IOrderState>,
    { orders, loadRelated }: OrderActions.UpsertMany
  ) {
    const status = new StatusUtils(ctx, OrderActions.UpsertMany);
    ctx.patchState(EntityUtils.upsertMany(ctx.getState(), orders));
    if (loadRelated) {
      await ctx.dispatch(new OrderActions.LoadRelated(orders)).toPromise();
    }
    status.setLoading(false);
  }

  @Action(OrderActions.GetMine)
  async getMine(ctx: StateContext<IOrderState>, {}: OrderActions.GetMine) {
    const status = new StatusUtils(ctx, OrderActions.GetMine);
    let error: Error;
    try {
      const orders = await this.api.getMine$().toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany(orders)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.GetByStores)
  async getByStores(ctx: StateContext<IOrderState>, { storeIds }: OrderActions.GetByStores) {
    const status = new StatusUtils(ctx, OrderActions.GetByStores);
    let error: Error;
    try {
      const orders = flatten(
        await Promise.all(storeIds.map((sid) => this.api.getByStore$(sid).toPromise()))
      );
      await ctx.dispatch(new OrderActions.UpsertMany(orders, true)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.Create)
  async create(ctx: StateContext<IOrderState>, { order }: OrderActions.Create) {
    const status = new StatusUtils(ctx, OrderActions.Create);
    let error: Error;
    try {
      const sentOrder = await this.api.create$(order).toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany([sentOrder])).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.Accept)
  async accept(ctx: StateContext<IOrderState>, { data }: OrderActions.Accept) {
    const status = new StatusUtils(ctx, OrderActions.Accept);
    let error: Error;
    try {
      const orders = await this.api.accept$(data).toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany(orders)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.VerifyCode, { cancelUncompleted: true })
  async verifyCode(ctx: StateContext<IOrderState>, { storeId, code }: OrderActions.VerifyCode) {
    const status = new StatusUtils(ctx, OrderActions.VerifyCode);
    let error: Error;
    try {
      const order = await this.api.verifyCode$(storeId, code).toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany([order])).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.Collect)
  async collect(ctx: StateContext<IOrderState>, { data, collectAsUser }: OrderActions.Collect) {
    const status = new StatusUtils(ctx, OrderActions.Collect);
    let error: Error;
    try {
      const orders = await this.api.collect$(data, collectAsUser).toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany(orders)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.Decline)
  async decline(ctx: StateContext<IOrderState>, { data }: OrderActions.Decline) {
    const status = new StatusUtils(ctx, OrderActions.Decline);
    let error: Error;
    try {
      const orders = await this.api.decline$(data).toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany(orders)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.CancelByStore)
  async cancelByStore(ctx: StateContext<IOrderState>, { data }: OrderActions.CancelByStore) {
    const status = new StatusUtils(ctx, OrderActions.CancelByStore);
    let error: Error;
    try {
      const orders = await this.api.cancelByStore$(data).toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany(orders)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.CancelByUser)
  async cancelByUser(ctx: StateContext<IOrderState>, { data }: OrderActions.CancelByUser) {
    const status = new StatusUtils(ctx, OrderActions.CancelByUser);
    let error: Error;
    try {
      const orders = await this.api.cancelByUser$(data).toPromise();
      await ctx.dispatch(new OrderActions.UpsertMany(orders)).toPromise();
    } catch (err) {
      error = err;
    }
    status.setLoading(false).setError(error);
  }

  @Action(OrderActions.LoadRelated)
  async loadRelated(ctx: StateContext<IOrderState>, { orders }: OrderActions.LoadRelated) {
    const storeIds: string[] = [];
    const itemIds: string[] = [];
    const userIds: string[] = [];
    for (const o of orders || []) {
      storeIds.push(o.storeId);
      userIds.push(o.userId);
      o.orderItems.forEach((it) => itemIds.push(it.itemId));
    }
    if (storeIds.length) {
      ctx.dispatch(new StoreActions.GetByIds(storeIds));
    }
    if (itemIds.length) {
      ctx.dispatch(new ItemActions.GetByIds(itemIds));
    }
    if (userIds.length) {
      ctx.dispatch(new UserActions.GetByIds(userIds));
    }
  }
}
