import { IBulkItemsQuery } from '~_shared/models';

export class GetLocation {
  static readonly type = '[Bulk] Location';
  constructor(public locationId: string, public forceLoad?: boolean) {}
}

export class GetItemsByQuery {
  static readonly type = '[Bulk] GetItemsByQuery';
  constructor(public query: IBulkItemsQuery, public forceLoad?: boolean) {}
}

export class GetItemsByIds {
  static readonly type = '[Bulk] GetItemsByIds';
  constructor(
    public itemIds: string[],
    public affectedStoreIds: string[],
    public forceLoad?: boolean
  ) {}
}

export class GetItemsByStoreAdmin {
  static readonly type = '[Bulk] GetItemsByStoreAdmin';
  constructor(public storeId: string, public forceLoad?: boolean) {}
}

export class GetSystem {
  static readonly type = '[Bulk] System';
  constructor() {}
}

export class GetUser {
  static readonly type = '[Bulk] User';
  constructor(public token: string, public forceLoad?: boolean) {}
}

export class ClearStatus {
  static readonly type = '[Bulk] ClearStatus';
  constructor(public actionIdStartsWith: string | string[]) {}
}
