export interface IDictionary<T> {
  [key: string]: T;
}

export type SubmitState = 'idle' | 'submitting' | 'submitted' | 'error';

export interface ILinkAccountsByCredentialInput {
  credential?: {
    accessToken?: string;
    oauthAccessToken?: string;
    providerId?: string;
    signInMethod?: string;
  };
  email?: string;
}

export interface IRequireEmailVerification {
  email: string;
  createdAt: Date;
  locksAt: Date;
  daysUntilLock: number;
}

export enum PhoneNumberStatus {
  Verified = 'verified',
  NotVerified = 'not_verified',
}
export interface IPhoneNumberStatus {
  status: PhoneNumberStatus;
}

export interface IMinMax {
  min?: number;
  max?: number;
}
