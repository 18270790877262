import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, of, timer } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ITalkEventLoginResult, NativeToWebEvent, WebToNativeEvent } from '~_shared/models';
import { NGXS } from '../ngxs';
import { MeApiService } from '../ngxs/me/me.service';
import { NativeTalkerService } from '../services';

/**
 * Responsible for check and trigger initial data.
 * Rules:
 * 1) Always load system bulk (filters/cats/etc)
 * 2) Always demand a user - if none found, create anonymous.
 *    - This is detected by getting user bulk.
 *    - Wait to see if there's a native wrapper with info (native creates anonymous if needed).
 *    - If no native wrapper available, check Firebase user by web.
 *    - If no Firebase web user available, create anonymous
 */
@Injectable()
export class InitialLoadedGuard implements CanActivate {
  constructor(
    private ngxs: Store,
    private nativeTalker: NativeTalkerService,
    private fireAuth: AngularFireAuth,
    private meApi: MeApiService
  ) {}

  readonly hasSystemLoaded$ = this.ngxs.select(NGXS.Bulk.select.hasSystemLoaded);
  readonly shouldSystemLoad$ = this.ngxs.select(NGXS.Bulk.select.shouldSystemLoad);

  readonly hasUserLoaded$ = this.ngxs.select(NGXS.Bulk.select.hasUserLoaded);
  readonly shouldUserLoad$ = this.ngxs.select(NGXS.Bulk.select.shouldUserLoad);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.initLoadSystem();
    this.initLoadUser();

    return combineLatest([this.hasSystemLoaded$, this.hasUserLoaded$]).pipe(
      map(([hasSystemLoaded, hasUserLoaded]) => hasSystemLoaded && hasUserLoaded),
      filter((allLoaded) => allLoaded)
    );
  }

  /**
   * Load system, if not already loaded
   */
  private initLoadSystem() {
    this.shouldSystemLoad$.pipe(first()).subscribe((shouldLoad) => {
      if (shouldLoad) {
        this.ngxs.dispatch(NGXS.Bulk.actions.GetSystem);
      }
    });
  }
  /**
   * Load user, if not already loaded.
   * 1) Check if native has
   *
   */
  private initLoadUser() {
    // wait at least 1 sec before accepting isNative$=false
    const isNative$ = this.nativeTalker.hasTalker$;
    const isNativeDelayed$ = isNative$.pipe(
      switchMap((x) => (x ? of(x) : timer(1000).pipe(switchMap(() => isNative$))))
    );
    // if native, ask it to send initial auth (either user or created anonymoys)
    const userByNative$ = of(null).pipe(
      tap(() => this.nativeTalker.talk(WebToNativeEvent.GET_INITIAL_USER)),
      switchMap(() =>
        this.nativeTalker.listenTo$<ITalkEventLoginResult>(NativeToWebEvent.LOGGED_IN)
      )
    );
    // if non-native, try get current Firebase user - if none: create anonymous
    // (wait at least 1 sec before accepting fireUser$=null)
    const userByWeb$ = this.fireAuth.user.pipe(
      switchMap((fireUser) => {
        if (fireUser) {
          return of(fireUser);
        }
        return timer(1000).pipe(
          tap(() => this.meApi.createAnonymous()),
          switchMap(() => this.fireAuth.user)
        );
      })
    );

    /**
     * Final flow:
     * 1. Is native?
     * - yes = wait for initial login info
     * - no = get current Firebase user or create anonymous
     * 2. Wait for user arrive to NGXS state
     * 3. Ensure that user bulk not loading/loaded
     * 4. Load bulk
     */
    isNativeDelayed$
      .pipe(
        switchMap((isNative) => (isNative ? userByNative$ : userByWeb$)),
        switchMap(() => this.ngxs.select(NGXS.Me.select.token)),
        filter((token) => !!token),
        debounceTime(1), // to make sure token has been set as auth bearer
        withLatestFrom(this.shouldUserLoad$),
        first()
      )
      .subscribe(([token, shouldLoad]) => {
        if (shouldLoad) {
          // MyUserService.trackFirebase() also listens for user changes and dispatch user bulk.
          // Due to debounceTime(1), this should never actually occur, but leaving it just in case.
          this.ngxs.dispatch(new NGXS.Bulk.actions.GetUser(token));
        }
      });
  }
}
